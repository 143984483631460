const settings = {

  WebSite: 'https://app.boardx.com.cn/', //website domain
  ImageApi: 'https://pixabay.com/api/',
  IconApi: 'https://api.iconfinder.com/v4/icons/search',
  JoinUrl: 'https://app.boardx.com.cn',
  
};

export default settings;
