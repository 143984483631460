//** Import react
import React, { useState } from 'react';

import { styled } from '@mui/material/styles';

//** Import Redux kit
import { useAdminAddAiModelTrainedMutation } from '../../../redux/AiAssistApiSlice';

import Button from '@mui/joy/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Textarea } from '@mui/joy';

//** Import i18n
import { useTranslation } from 'react-i18next';

//** Import services
import { UtilityService } from '../../../services';

import store from '../../../store';

export default function AIModelAddTrainedData({
  openAddNewRowDialog,
  handleCloseAddNewRowDialog,
  currentModelData
}) {

  const { t } = useTranslation();
  const [propmpt, setPropmpt] = useState('');
  const [completion, setCompletion] = useState('');

  const [adminAddAiModelTrained] = useAdminAddAiModelTrainedMutation();

  const handleAddCommand = async () => {
    if (!currentModelData) {
      Boardx.Util.Msg.info(t('adminPage.pleaseSelectAModel'));
      return;
    }

    if (propmpt === '') {
      Boardx.Util.Msg.info(t('adminPage.pleaseFillAllFields'));
      return;
    }

    const data = {
      _id: UtilityService.getInstance().generateWidgetID(),
      modelId: currentModelData._id,
      prompt: propmpt,
      completion: completion,
      createdAt: new Date(),
      createUser: store.getState().user.userInfo,
      createUserId: store.getState().user.userInfo.userId,
      lastUpdateUser: store.getState().user.userInfo.userName,
      lastUpdateUserId: store.getState().user.userInfo.userId,
      lastUpdateAt: new Date()
    };
    await adminAddAiModelTrained({ modelTrainedData: data });
    Boardx.Util.Msg.info(t('adminPage.addSuccessfully'));
    handleCloseAddNewRowDialog();
    // Boardx.Util.Msg.info(err.reason);
  };

  return (
    <Dialog open={openAddNewRowDialog}
      onClose={handleCloseAddNewRowDialog}
      sx={{
        '.inputBox': { marginTop: '12px' },
        '.inputTitle': {
          fontWeight: 600,
          fontSize: '12px',
          lineHeight: '15px',
          marginBottom: '6px'
        },
        '.textAreaStyle': {
          width: '500px',
          resize: 'none',
          border: '1px solid rgba(0, 0, 0, 0.23)',
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '24px',
          letterSpacing: '0.15px',
          padding: '5px'
        }
      }}
    >
      <DialogContent>
        {/* Prompt */}
        <Box className={'inputBox'}>
          <Typography className={'inputTitle'} variant="plain">
            PROMPT
          </Typography>
          <Textarea
            className={'textAreaStyle'}
            autoFocus
            id="prompt"
            placeholder="prompt"
            onChange={e => setPropmpt(e.target.value)}
          />
        </Box>

        {/* Completion */}
        <Box className={'inputBox'}>
          <Typography className={'inputTitle'} variant="plain">
            COMPLETION
          </Typography>
          <Textarea
            id="completion"
            className={'textAreaStyle'}
            placeholder="completion"
            onChange={e => setCompletion(e.target.value)}
          />
        </Box>
      </DialogContent>

      {/* save button */}
      <DialogActions
        className={'inputBox'}
        sx={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <Button
          sx={{
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '24px',
            letterSpacing: '0.4px',
            textTransform: 'uppercase',
            boxShadow: 'var(--joy-shadow-md)',
            borderRadius: '5px',
            padding: '7px 22px !important',
            background: '#F21D6B !important'
          }}
          variant="solid"
          onClick={handleAddCommand}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
