//** Import react
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { useState, useRef } from 'react';

//** Import i18n
import { useTranslation, } from 'react-i18next';

//** Import Redux kit
import store, { RootState } from '../../store';
import { useSelector } from 'react-redux';

//**Import Mui
import Box from '@mui/joy/Box';
import Modal from '@mui/joy/Modal';
import DialogContent from '@mui/joy/DialogContent';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


import Typography from '@mui/joy/Typography';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';

import ClipboardService from '../../services/ClipboardService';
import Button from '@mui/joy/Button';
import Input from '@mui/joy/Input';
import { ModalClose } from '@mui/joy';
export default function InviteFriend() {
  //use now
  const theme = useTheme();
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const orgInfo = useSelector(state => state.org.orgInfo);
  const [openDialog, setOpenDialog] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const language = localStorage.getItem('boardx-lang-pref');
  const rsBtn = useRef(null);
  const userInfo = useSelector(state => state.user.userInfo);
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const stopTabPropagation = event => {
    if (event.key === 'Tab') {
      event.stopPropagation();
    }
  };
  const inviteLink =
    'http://' +
    location.host +
    '/join?invite=' +
    store.getState().user.userInfo.userId;
  const getInviteLink = () => {
    return `http://${location.host}/join?invite=${store.getState().user.userInfo.userId
      }`;
  };

  const handleCopy = () => {
    ClipboardService.getInstance().clipboardCopy(getInviteLink());
    Boardx.Util.Msg.info(
      t('components.referFriend.copyLinkSuccess')
    );
  };

  const handleCopyInvitation = () => {
    const inviteLink = `http://${location.host}/join?invite=${store.getState().user.userInfo.userId}`;


    const inviteMessageChinese = `
    释放BoardX AI助手Ava的强大力量！

      ✅ 为什么选择BoardX AI 助手Ava
      - 人工通用智能（AGI）正在改变我们学习和工作的方式。为了保持领先，您需要直接从源头学习AI。
      - 与最好的AI模型对话：ChatGPT 4、Gemini和Claude 3。您AI互动的质量塑造了您的理解力。不要满足于基础模型；免费模型可能导致误解和AI技能的停滞不前。使用BoardX Ava，您将与卓越对齐，确保您为AI驱动的世界培养正确的心态和能力。
      - 在一个地方获得顶尖AI模型的精华，用于文本、音频和图像互动。与BoardX Agent和BoardX Board协同工作，促进您的团队与AI技术之间的协同效应。

      ✅ 谁将从中受益？
      - 学习者：从学生到终身学习者，适应以AI为中心的教育环境。
      - 专业人士：以AGI作为您的个人助理，提高生产力和解决问题的能力。
      - 创新者：通过与尖端AI技术合作，探索新的视野。

      ✅ 如何申请？
      - 注册帐户（仅限邀请）：
      如果您还没有注册账户，请使用这个独家邀请链接：${inviteLink}
      - 升级账户：
      注册后，导航到屏幕右上角找到“升级”菜单。使用促销代码PILOT升级您的账户。

      ✅ 限时促销
      - 同时访问全球最好的三个模型(GPT4, Gemini, Claude)：价值720美元/年。
      - 第一年获得整个套件，只需67.2美元。`;

    const inviteMessage = `
    Unleash the Power of Advanced AI with BoardX !

        ✅ Why Choose BoardX AI Assistant - Ava?
        - Artificial General Intelligence (AGI) is transforming how we learn and work. To stay ahead, immerse yourself by learning AI directly from the source
        - Chat with the best AI models: ChatGPT 4, Gemini,  and Claude 3. The quality of your AI interactions shapes your understanding. Don't settle for basic; free models can lead to misconceptions and stunted AI skills. With BoardX Ava, you align with excellence, ensuring you develop the right mindset and competencies for an AI-driven world.
        - Get the best of top AI models in one place for text, audio, and image interactions. Works with BoardX Agent and BoardX Room, fostering unparalleled synergy between your team and AI technology.
        
        ✅ Who Will Benefit?
        - Learners: From students to lifelong learners, adapt to an AI-centric educational landscape.
        - Professionals: Enhance productivity and problem-solving with AGI as your personal assistant.
        - Innovators: Explore new horizons by collaborating with leading-edge AI technology.
        
        ✅ How to Apply?
        - Register an Account(invite only):
        If you have not registered an account yet, please use this exclusive invitation link: ${inviteLink}
        - Upgrade Account:
        After registration, navigate to the upper-right corner of the screen to find the 'Upgrade' menu. Use the code PILOT to upgrade your account.
        
        ✅ Promotion in Limited Time
        - Special Offer: Triple Power, Fraction of the Price!
        - Elite AI Trio: Access our three best models, normally valued at $720/year.
        - Unbelievable Savings: Get the entire suite for only $67.2 for the first year.`;

    ClipboardService.getInstance().clipboardCopy(i18n.language === 'zh-CN' ? inviteMessageChinese : inviteMessage);
    Boardx.Util.Msg.info(
      t('components.referFriend.copyInvitationSuccess')
    );
  };
  const userProfileBtnCOM = (
    <Box id="rsButton" ref={rsBtn}>
      <Box
        id="id_roomsetting"
        onClick={handleClickOpen}
      >
        <Typography level="title-md">
          {t('components.userMenu.ReferAFriend')}
        </Typography>

      </Box>
    </Box>
  );

  const title = t('components.referFriend.title').replace('&amp;', '&');

  return (

    <Box    >
      <Box sx={{ width: '100%' }}>
        {userProfileBtnCOM}
        <Modal open={openDialog} onClose={handleClose}>

          <ModalDialog
            aria-labelledby="responsive-dialog-title"
            onKeyDown={stopTabPropagation}
          >
            <ModalClose onClick={handleClose} />
            <DialogTitle id="responsive-dialog-title" >
            </DialogTitle>

            <DialogContent
              sx={{
                textAlign: 'center'
              }}
            >
              <Typography level='h3'  > {title}</Typography>
              <Box > You have invited: {userInfo.referalUsers} users </Box>


              {/* <Divider  /> */}
              <Box sx={{
                display: 'flex',
                flexDirection: 'column', justifyContent: 'space-around', alignItems: 'flex-start', gap: 2,
                marginBottom: '20px'
              }}>
                <Typography >
                  {t('components.referFriend.shareLink')}
                </Typography>


                <Input
                  name="share"
                  sx={{ width: '480px', maxWidth: '90%', height: '30px' }}

                  value={inviteLink}
                  readOnly
                />

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                  <Button onClick={handleCopy} variant='solid'>
                    {t('components.referFriend.copy')}
                  </Button>
                  <Button onClick={handleCopyInvitation} variant='solid'>
                    {t('components.referFriend.copyInvite')}
                  </Button>
                </Box>
              </Box>
              {/* <Box style={{ textAlign: 'left', marginLeft: '30px' }}>
                <Typography className={classes.shareLinkNote}>{t('components.referFriend.maxUsers')} </Typography>
                <Box style={{ position: 'relative', marginBottom: '50px' }}>
                  <TextField
                    name="share"
                    sx={{ width: '540px', height: '40px', }}
                    inputProps={{ style: { borderRadius: '8px', color: '#ccc', fontSize: '14px' } }}
                    value="10"
                    readOnly
                  />
                </Box>
              </Box> */}

            </DialogContent>

          </ModalDialog>
        </Modal>
      </Box>
    </Box>

  );
}
