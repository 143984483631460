//** Import react
import * as React from 'react';
import { styled } from '@mui/material/styles';

//** Import Redux kit
import { useGetAiAllCustomStyleCommandQuery } from '../../../redux/AiAssistApiSlice';
import { useSelector, useDispatch } from 'react-redux';
import { handleSetCurrentAdminAiImagePromptData } from '../../../store/AIAssist';

import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import TextField from '@mui/material/TextField';

import AIAssistantImagesAddNewPrompt from './AIAssistantImagesAddNewPrompt';
import AIImagePrompt from './AIImagePrompt';


export default function AIAssistantImagesAdminPage() {
  const dispatch = useDispatch();
  const commandNameRef: any = React.useRef('');
  const [searchKeyWord, setSearchKeyWord] = React.useState('');
  const [openAddNewPromptDialog, setOpenAddNewPromptDialog] =
    React.useState(false);

  const [openAiImagePromptDialog, setOpenAiImagePromptDialog] =
    React.useState(false);

  let { data: customStylecommandData = [] } =
    useGetAiAllCustomStyleCommandQuery(undefined);

  const IconCellDOM = params => {
    return (
      <img
        style={{ width: '24px', height: '24px' }}
        src={
          Boolean(params.row.icon)
            ? params.row.icon
            : '/images/android-icon-36x36.png'
        }
        alt={params.row.name}
      />
    );
  };

  const BackgroundImgCellDOM = params => {
    return (
      <img
        style={{ width: '52px', height: '39px' }}
        src={
          Boolean(params.row.backgroundUrl)
            ? params.row.backgroundUrl
            : '/images/ImageCommandBackgroundImg.png'
        }
        alt={params.row.name}
      />
    );
  };

  const columns = [
    { field: 'category', headerName: 'Category', width: 100 },
    { field: 'name', headerName: 'Name', width: 100 },
    {
      field: 'type',
      headerName: 'Type',
      width: 100,
      type: 'string'
    },
    {
      field: 'weight',
      headerName: 'Weight',
      width: 100,
      type: 'number'
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 100
    },
    { field: 'command', headerName: 'Command', width: 100 },
    {
      field: 'usedTimes',
      headerName: 'Used Times',
      type: 'number',
      editable: false
    },
    {
      field: 'isFeatured',
      headerName: 'Featured',
      type: 'boolean'
    },
    { field: 'width', headerName: 'Width', type: 'number' },
    { field: 'height', headerName: 'Height', type: 'number' },
    {
      field: 'icon',
      headerName: 'Icon',
      type: 'string',
      renderCell: params => IconCellDOM(params)
    },
    {
      field: 'backgroundImage',
      headerName: 'Background Image',
      type: 'string',
      width: 200,
      renderCell: params => BackgroundImgCellDOM(params)
    }
  ];

  const handleCloseAddNewPromptDialog = () => {
    setOpenAddNewPromptDialog(false);
  };

  const handleSearchCommand = e => {
    const keyword = commandNameRef.current.value;
    setSearchKeyWord(keyword);
  };

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'flex-start',
      height: 'calc(100vh - 60px)',
      marginTop: '30px',
    }}>
      <Box sx={{
        width:  'calc(100vw - 350px)',
        padding: '5px',
        borderRadius: '6px 0px 0px 6px',
        background: 'var(--joy-palette-background-surface)',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <TextField
          fullWidth
          id="fullname"
          inputProps={{
            style: {
              height: '18px',
              width: '95%',
              color: 'rgba(0, 0, 0, 0.87)'
            }
          }}
      
          inputRef={commandNameRef}
          label="Filter"
          variant="outlined"
          onKeyDown={handleSearchCommand}
        />
        <DataGrid
          style={{ flex: 1 }}
          columns={columns}
          disableColumnMenu
          rows={customStylecommandData.filter(item =>
            item.name.toLowerCase().includes(searchKeyWord.toLowerCase())
          )}
          sortModel={[{ field: 'category', sort: 'desc' }]}
          onRowClick={(params, event) => {
            dispatch(handleSetCurrentAdminAiImagePromptData(params.row));
            setOpenAiImagePromptDialog(true);
          }}
        />
        <Box sx={{
          margin: '30px 0px 24px',
          display: 'flex',
          justifyContent: 'flex-end'
        }}>
          <Button

     
            variant="solid"
            onClick={() => setOpenAddNewPromptDialog(true)}
          >
            ADD NEW PROMPT
          </Button>
        </Box>
        <AIAssistantImagesAddNewPrompt
          openAddNewPromptDialog={openAddNewPromptDialog}
          handleCloseAddNewPromptDialog={handleCloseAddNewPromptDialog}
          setOpenAiImagePromptDialog={setOpenAiImagePromptDialog}
        />
      </Box>

      <AIImagePrompt
        openAiImagePromptDialog={openAiImagePromptDialog}
        setOpenAiImagePromptDialog={setOpenAiImagePromptDialog}
      />
    </Box>
  );
}
