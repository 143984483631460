//** Import react
import React, { useRef, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';

//** Import i18n
import { useTranslation } from 'react-i18next';

//** Import Mui

import TextField from '@mui/material/TextField';
import { InputAdornment } from '@mui/material';
import { Box } from '@mui/joy';

//** Import Redux kit
import store, { RootState } from '../../store';
import { useSelector, useDispatch } from 'react-redux';
import {
  handleSetKeyword,
  handleSetInFavoriteBoardPage,
  handleSetFavoriteBoardList
} from '../../store/boardList';
import { handleSetCurrentRoomList, handleSetInRoom } from '../../store/room';
import { useGetRoomListByOrgIdQuery } from '../../redux/OrgAPISlice';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
const Search = styled('div')((theme) => ({
  position: 'relative',
  //borderRadius: theme.shape.borderRadius,
  flexGrow: 1,
  marginRight: 2,
  marginLeft: 0,
  // [theme.breakpoints.down('xl')]: {
  //   marginLeft: 0,
  //   width: 178,
  //   float: 'left',
  // },
}));

export default function AutoSearchBoard() {
  //use
  const dispatch = useDispatch();

  const location = useLocation();
  const { t } = useTranslation();
  const [isSearchContent, setIsSearchContent] = useState(false);

  const [keywordValue, setKeywordValue] = useState('');
  //org
  const orgId = useSelector((state) => state.org.orgInfo.orgId);
  const orgInfo = useSelector((state) => state.org.orgInfo);

  const keyword = useSelector((state) => state.boardList.keyword);

  //search
  const keyWordRef = useRef();

  //room
  const { data: originRoomList = [] } = useGetRoomListByOrgIdQuery(orgId);

  //board
  const originBoardList = useSelector((state) => state.boardList.boardList);
  const originFavoriteBoardList = useSelector(
    (state) => state.boardList.favoriteBoardList
  );

  useEffect(() => {
    setKeywordValue(keyword ? keyword : '');
  }, [location]);

  const searchByKeyword = async (e) => {
    if (e.keyCode === 13) {
      const search = keyWordRef.current.value;
      if (search.length < 1 || !search) {
        delKeywords();
        return;
      }
      if (search !== '') {
        dispatch(handleSetKeyword(search));
        setIsSearchContent(true);

        //deal favorite board search
        if (originFavoriteBoardList.length > 0) {
          const newFavoriteBoardList = originFavoriteBoardList.filter(
            t => t.name.toLowerCase().indexOf(search) > -1
          );
          return dispatch(handleSetFavoriteBoardList(newFavoriteBoardList));
        }
      }
    }
  };

  const delKeywords = () => {
    dispatch(handleSetCurrentRoomList(originRoomList));
    dispatch(handleSetKeyword(''));
    dispatch(handleSetInRoom(true));
    dispatch(handleSetInFavoriteBoardPage(true));
    document.getElementById('searchField').value = '';
    setIsSearchContent(false);
  };

  return (
    <Box>
      <Search
        id="searchBar"
        sx={{
          display: 'flex',

          height: '40px',
          width: '30%',
          minWidth: '240px',
          maxWidth: '640px',
          position: 'relative',
          '.noBorder': { border: 'none' }
        }}
      >
        <TextField
          InputProps={{
            onKeyUp: searchByKeyword,
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon fontSize="small" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                onClick={delKeywords}
                sx={{
                  width: '15px',
                  height: '15px',
                  '&:hover': {
                    fontWeight: 'bold !important',
                    fill: '#444467'
                  }
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="delsvg"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  width={15}
                  height={15}
                  style={{
                    display: isSearchContent ? 'block' : 'none',
                    cursor: 'default'
                  }}
                >
                  <g transform="matrix(1,0,0,1,0,0)">
                    <path
                      d="M0.75 23.249L23.25 0.749"
                      fill="none"
                      stroke="var(--joy-palette-text-icon)"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    ></path>
                    <path
                      d="M23.25 23.249L0.75 0.749"
                      fill="none"
                      stroke="var(--joy-palette-text-icon)"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    ></path>
                  </g>
                </svg>
              </InputAdornment>
            ),
            classes: { notchedOutline: 'noBorder' }
          }}
          id="searchField"
          placeholder={t('pages.listPage.searchBoardOrRoom')}
          style={{
            marginTop: '-8px',
            marginLeft: '4px',
            //fontSize: '12px',
            width: '100%'
          }}
          type="text"
          onChange={() => setKeywordValue(keyWordRef.current.value)}
          inputRef={keyWordRef}
          value={keywordValue}
        />
      </Search>
    </Box>
  );
}
