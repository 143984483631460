//** Import react
import React from 'react';

//** Import i18n
import { useTranslation } from 'react-i18next';

//** Import Redux kit
import store, { RootState } from '../../../store';
import { useSelector, useDispatch } from 'react-redux';
import { useUpdateAIChatSessionMutation } from '../../../redux/AiAssistApiSlice';
import { handleSetAiToolBar } from '../../../store/domArea';
import {
  handleSetCurrentChatAiPersonaData,
  handleSetCurrentChatSession,
  handleSetChatSessionList
} from '../../../store/AIAssist';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/joy/Typography';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

const ChatAgentItem = props => {
    const { data, dataIndex, handleClose } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const currentChatSession: any = useSelector(
      (state: RootState) => state.AIAssist.currentChatSession
    );
  
    const chatSessionList = useSelector(
      (state: RootState) => state.AIAssist.chatSessionList
    );
  
    const [updateAIChatSession] = useUpdateAIChatSessionMutation();
  
    const handleClickSelectPromptPersona = async data => {
      dispatch(handleSetCurrentChatAiPersonaData(data));
  
      const result = await updateAIChatSession({
        aiChatSessionId: currentChatSession._id,
        updateData: {
          promptPersonaData: data
        }
      })
  
      //update current chat session
      dispatch(
        handleSetCurrentChatSession({
          ...currentChatSession,
          promptPersonaData: result
        })
      );
  
      //update chat session list
      const newChatSessionList = chatSessionList.map(item => {
        if (item._id === currentChatSession._id) {
          return {
            ...item,
            promptPersonaData: result
          };
        }
        return item;
      });
      dispatch(handleSetChatSessionList(newChatSessionList));
  
  
      handleClose();
    };
  
    return (
      <ListItemButton
        key={dataIndex}
        onClick={() => handleClickSelectPromptPersona(data)}
        sx={{
          m: '3px 10px 3px 0px',
          alignItems: 'flex-start',
          padding: '8px',
          borderRadius: '4px',
          transition: 'none',
          minWidth: '300px',
       
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
          },
          maxHeight: '90px',
         
        }}
  
      >
        <img
          style={{ width: '50px', height: '50px', alignSelf: 'flex-start', 
          borderRadius:'50%',   backgroundColor: 'white',
          marginLeft: '20px', marginRight: '20px', marginTop: '10px',
          boxShadow: 'var(--joy-shadow-md)',}}
          src={data.icon ? data.icon : '/images/android-icon-36x36.png'}
          alt="command icon"
        />
        <ListItemText
          primary={<Typography level="title-sm" sx={{ fontWeight: 'bold' }}>{data.name}</Typography>}
          secondary={data.description}
          sx={{
            // whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            /* letter-spacing: 0.15px; */
            overflow: 'hidden',
            height: '65px',
          }}
        />
      </ListItemButton>
    );
  };

  export default ChatAgentItem;