import React from 'react';

import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { useState } from 'react';

//** Import i18n
import { useTranslation } from 'react-i18next';
import ChatAgentList from './ChatAgentList';

function ChatAgentComponent() {

    const { t } = useTranslation();

    const currentChatAiPersonaData = useSelector((state: RootState) => state.AIAssist.currentChatAiPersonaData);
    const [openPromptPersonaSelectDialog, setOpenPromptPersonaSelectDialog] =
        useState(false);

    const handleOpenPromptPersonaSelectDialog = () => {
        setOpenPromptPersonaSelectDialog(true);
    };


    return (
        <Box>
            <Box
                sx={{
                    zIndex: 100,
                    width: '100%',
                    justifySelf: 'center',
                }}
            >
                <Button
                    endDecorator={<KeyboardArrowDownOutlinedIcon />}
                    variant="plain"
                    onClick={handleOpenPromptPersonaSelectDialog}
                >{currentChatAiPersonaData?currentChatAiPersonaData.name: t('chatAi.selectPersona')}</Button>
            </Box>

            <ChatAgentList
                openPromptPersonaSelectDialog={openPromptPersonaSelectDialog}
                setOpenPromptPersonaSelectDialog={setOpenPromptPersonaSelectDialog}
            />


        </Box>

    );
}

export default ChatAgentComponent;