//** Import react
import React, { useEffect, useState } from 'react';


//** Import Redux toolkit
import store, { RootState } from '../../store';
import { useSelector, useDispatch } from 'react-redux';
import { handleSetOrgMemberList } from '../../store/org';
import {
  useAddOneUserToOrgMutation,
  useSendOrgInvitationRegisterEmailToNonExistingUserMutation,
  useFindOrCreateInviteQuery
} from '../../redux/OrgAPISlice';
import { useCheckActionPermissionOfTeamMutation } from '../../redux/PermissionApiSlice';
//** Import i18n
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

//** Import Mui
import Box from '@mui/joy/Box';
import Chip from '@mui/joy/Chip';
import TextField from '@mui/material/TextField';
import Button from '@mui/joy/Button';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/joy/Typography';

//** Import Service
import { ClipboardService, OrgService, UserService } from '../../services';

//** Import components
import { isEmail } from '../../util/isEmail';
import { useCheckIfUserExistsByEmailMutation } from '../../redux/UserAPISlice'

import _ from 'lodash';
import { Divider } from '@mui/material';

export default function OrganizationSettingsInviteUsers() {
  //use
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  //org
  const orgInfo = useSelector((state) => state.org.orgInfo);

  //hooks
  const [inviteList, setInviteList] = useState([]);
  const [checkIfUserExistsByEmail] = useCheckIfUserExistsByEmailMutation();
  const handleCheckIfUserExistsByEmail = async (email) => {
    return await checkIfUserExistsByEmail(email);
  };
  const [handleCheckActionPermissionOfTeam] = useCheckActionPermissionOfTeamMutation();
  const [addOneUserToOrg] = useAddOneUserToOrgMutation();
  const [sendOrgInvitationRegisterEmailToNonExistingUser] =
    useSendOrgInvitationRegisterEmailToNonExistingUserMutation();

  const { data: inviteInfo } = useFindOrCreateInviteQuery({
    userId: store.getState().user.userInfo.userId,
    invite: {
      rid: '',
      orgId: orgInfo.orgId,
      days: 0,
      maxUses: 0,
      inviteType: 'org'
    }
  });
  // if (error) {
  //   Boardx.Util.Msg.warning(error.message);
  //   return;
  // }
  const checkActionPermission = async (permissionName) => {
    let data = {
      permissionName: permissionName,
      role: orgInfo.role
    }
    return await handleCheckActionPermissionOfTeam(data);
  }

  const handleDelete = (user) => {
    setInviteList(_.without(inviteList, user));
  };

  const onInvitationClick = async () => {
    const copyText = `${inviteInfo.invitationLink}`;
    ClipboardService.getInstance().clipboardCopy(copyText);
    Boardx.Util.Msg.info(
      t('components.connectionNotification.youHaveCopiedInvitationLink'),
    );
  };

  const handleClickInvite = async () => {
    let checkRoleOfManageTeam = await checkActionPermission('Add Member To Team');
    if (checkRoleOfManageTeam && !checkRoleOfManageTeam.data) {
      Boardx.Util.Msg.info(
        t('pages.listPage.roomSettings.addMemberToTeam')
      );
      return;
    }
    const orgId = orgInfo.orgId;
    const orgName = orgInfo.name;

    inviteList.forEach(async (invite) => {
      if (invite.isNew) {
        const { data: result } = await handleCheckIfUserExistsByEmail(invite.name);
        if (result && result._id) {

          addOneUserToOrg({
            orgId: orgId,
            orgName: orgName,
            user: result,
            link: inviteInfo.invitationLink,
            language: i18n.language.slice(0, 2),
            orgInfo: orgInfo
          });
          // Boardx.Util.Msg.info(
          //   t('components.connectionNotification.userAlreadyInOrg')
          // );
        } else {
          sendOrgInvitationRegisterEmailToNonExistingUser({
            orgName: orgInfo.name,
            username: store.getState().user.userInfo.userName,
            inviteUsername: invite.username,
            invitationLink: inviteInfo.invitationLink,
            language: i18n.language.slice(0, 2)
          });
          // Boardx.Util.Msg.info(
          //   `${t('pages.invitationSent')} ${inviteUsername}`
          // );
        }
      }
    });

    if (inviteList.length <= 1) {
      Boardx.Util.Msg.info(
        `${inviteList[0].username} ${t('pages.memberHasBeenAdded')}`,
      );
    } else {
      Boardx.Util.Msg.info(
        `${inviteList.length} ${t('pages.users')} 
        ${t('pages.memberHasBeenAdded')}`,
      );
    }
    setInviteList([]);
  };

  const keyPress = (e) => {
    if (e.keyCode === 188 || e.keyCode === 13) {
      e.preventDefault();
      const text = e.target.value;

      if (text === '') {
        Boardx.Util.Msg.warning(t('pages.pleaseEnterEmailAddress'));
        return;
      } else if (!isEmail(text)) {
        Boardx.Util.Msg.warning(t('pages.invalidEmail'));
        return;
      }

      const newList = _.clone(inviteList);
      if (!newList.includes(text)) {
        newList.push({
          _id: text,
          username: text,
          name: text,
          isNew: true,
        });
        setInviteList(newList);
        e.target.value = '';
      }
    } else if (e.keyCode === 8 && e.target.value === '') {
      setInviteList(_.initial(inviteList));
    }
  };

  const inviteListChipDOM = () =>
    inviteList.map((user) => (
      <Chip
        sx={{
          '.sizeMedium': {
            margin: '2px 0px 0px 6px',
 
            cursor: 'pointer',
    
          }
        }}
        classes={{ sizeMedium: 'sizeMedium' }}
        key={user._id}
        
        onDelete={handleDelete.bind(this, user)}>{user.username}</Chip>

    ));



  return (
    <Box  theme={theme}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          flexGrow: 1,
          mb: '8px'
        }}
      >
          {//<Typography>Press Enter after Each Contact to Enable to Activate the Invite Button</Typography>
}
        <Box sx={{
          [theme.breakpoints.up('sm')]: {
            width: '837px',
          },
          height: 'auto',
          border: '1px solid rgba(0, 0, 0, 0.16)',
          borderRadius: '2px',
          boxSizing: 'border-box',
          display: 'flex',
        }}>

        
          {inviteListChipDOM()}
          <TextField
            sx={{  // width: 'auto',
              flex: 1,
              height: '38px',
              minWidth: '230px',
              margin: 0,
              '& .MuiInputBase-input': {
                width: '100%',
                height: '38px',
                padding: '3px 3px 3px 12px',
                boxSizing: 'border-box',
                focused: {borderColor:'yellow'}
              },
              '& .Mui-focused .MuiOutlinedInput-notchedOutline':{ 
             borderColor:'grey.500',

              }
              
         
            }}
            id="userName2"
            margin="dense"
            onKeyDown={keyPress}
            placeholder={t('pages.listPage.roomSettings.addOrgMembers')}
            type="text"
          />
        </Box>
        <Button
          sx={{
            width: '66px',
            height: '40px',
            left: '16px',

          }}
          onClick={handleClickInvite}
          size="md"
          disabled={inviteList.length > 0 ? false : true}
          variant="solid"
        >
          {t('pages.listPage.roomSettings.invite')}
        </Button>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Button
          color="primary"
          onClick={() => {
            onInvitationClick();
          }}
          variant="outlined"
        >
          {t('pages.listPage.inviteCopyInvitationLink')}
        </Button>
      </Box>
    </Box>
  );
}
