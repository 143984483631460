
import React,{useState} from 'react';

import ChatShare from './ChatShare';
import { Box, IconButton } from '@mui/joy';
import { IconArrowsMaximize, IconX, IconSettings , IconPrinter} from '@tabler/icons-react';
import ChatAISettings from './ChatAISettings';
import { useSelector, useDispatch } from 'react-redux';
import {
    handleSetCurrentChatSession,
    handleSetChatSessionList,
    handleSetCurrentChatAiPersonaData,
    handleSetShowChatAiMaskingLayer
  } from '../../../store/AIAssist';


export function ChatHeaderMenu({}) {

    const dispatch = useDispatch();
    const [openChatAISettings, setOpenChatAISettings] = useState(false);
  // useEffect(() => {
  //   AIService.getInstance().loadChatSessionList(chatSessionList, chatType);
  // }, [chatSessionList]);

  const handleClickOpenChatAISettings = () => {
    setOpenChatAISettings(true);
    dispatch(handleSetShowChatAiMaskingLayer(true));
  };

  const removeClassInHTMLString = (htmlString) => {
    // Use DOMParser to parse the HTML string
    var parser = new DOMParser();
    var doc = parser.parseFromString(htmlString, 'text/html');

    // Select all elements with the class 'chatItemFooterIcons' and remove them
    var elements = doc.querySelectorAll('.chatItemFooterIcons');
    elements.forEach(element => element.parentNode.removeChild(element));

    // Add a grey hr line before each element with class 'avatar-img'
    var avatarImages = doc.querySelectorAll('.avatar-img');
    avatarImages.forEach(image => {
        var hr = document.createElement('hr');
        hr.style.border = 'none';
        hr.style.borderTop = '1px solid #ccc';
        image.parentNode.insertBefore(hr, image);
    });

    // Force all images under the div with class 'avatar-img' to have width: 36px, height: 36px, and border-radius: 50%
    var avatarImages = doc.querySelectorAll('.avatar-img img');
    avatarImages.forEach(image => {
        //@ts-ignore
        image.style.width = '24px';
        //@ts-ignore
        image.style.height = '24px';
        //@ts-ignore
        image.style.borderRadius = '50%';
    });

    // Serialize the document back to a string
    var serializer = new XMLSerializer();
    var updatedHtmlString = serializer.serializeToString(doc);
    return updatedHtmlString;
}

const printWholeChat = () => {
  const chatContent = document.getElementById('chatAIUI');
  var htmlContent = chatContent.outerHTML;
  let updatedHtmlContent = removeClassInHTMLString(htmlContent);

  if (!updatedHtmlContent) return;
  const printWindow = document.createElement('iframe');
  printWindow.style.display = 'none';
  document.body.appendChild(printWindow);
  printWindow.contentDocument.write(updatedHtmlContent);
  printWindow.contentWindow.print();
  document.body.removeChild(printWindow);
}

  return (
  
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    <ChatShare />

    <IconButton onClick={printWholeChat}>
  <IconPrinter style={{ strokeWidth:"var(--joy-lineHeight-sm)" }} />
  </IconButton>
    <IconButton onClick={handleClickOpenChatAISettings} style={{ strokeWidth:"var(--joy-lineHeight-sm)" }}>
      <IconSettings style={{ strokeWidth:"var(--joy-lineHeight-sm)" }}/>
    </IconButton>
    <ChatAISettings
          openChatAISettings={openChatAISettings}
          setOpenChatAISettings={setOpenChatAISettings}
        />
  </Box>
  );
}