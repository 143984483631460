import SvgIcon from '@mui/joy/SvgIcon';
import React from 'react';

export default function UserPhoto(props) {
  return (
    <SvgIcon {...props} style={{ width: 18, height: 18 }} viewBox="0 0 18 18">
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 2.25C7.35203 2.25 6 3.60203 6 5.25C6 6.89797 7.35203 8.25 9 8.25C10.648 8.25 12 6.89797 12 5.25C12 3.60203 10.648 2.25 9 2.25ZM9 3.75C9.83731 3.75 10.5 4.41269 10.5 5.25C10.5 6.08731 9.83731 6.75 9 6.75C8.16269 6.75 7.5 6.08731 7.5 5.25C7.5 4.41269 8.16269 3.75 9 3.75ZM9 10.5C7.69129 10.5 6.14037 10.8073 4.83691 11.3218C4.18518 11.579 3.59602 11.8845 3.12451 12.2769C2.653 12.6692 2.25 13.1995 2.25 13.875V15.75H15.75V15V13.875C15.75 13.1995 15.347 12.6692 14.8755 12.2769C14.404 11.8845 13.8148 11.579 13.1631 11.3218C11.8596 10.8073 10.3087 10.5 9 10.5ZM9 12C10.0605 12 11.5095 12.2805 12.6138 12.7163C13.1659 12.9342 13.6327 13.194 13.916 13.4297C14.1993 13.6654 14.25 13.826 14.25 13.875V14.25H3.75V13.875C3.75 13.826 3.80069 13.6654 4.08398 13.4297C4.36728 13.194 4.83412 12.9342 5.38623 12.7163C6.49046 12.2805 7.93946 12 9 12Z"
          fill="#544F5A"
        />
      </svg>
    </SvgIcon>
  );
}
