import { Box } from "@mui/joy";
import { Outlet } from "react-router-dom";
import React, { useEffect } from 'react';
import ChatSessionList from "components/boardChatAI/ChatAI/ChatSessionList";
import { handleSetCurrentApp } from 'store/system/index';
import store from 'store/index';
import { useMediaQuery } from "@mui/material";

export default function AiAssistantLayout() {
    const isMobile = useMediaQuery("(max-width: 600px)");
    useEffect(() => {
        store.dispatch(handleSetCurrentApp('ava'));
    }, []);
    return (
        <Box sx={{ display: 'flex', height: 'calc(100vh-60px)', flexGrow: 1, flexShrink: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
            <Box sx={{ width: isMobile ? '100%' : '240px' }}>
                <ChatSessionList />
            </Box>
            <Box sx={{ width: '100%', display: isMobile ? 'none' : 'flex', flexGrow: 1, flexShrink: 1 }}>
                <Outlet />
            </Box>
        </Box>
    )
}
