import { createSlice } from '@reduxjs/toolkit';
import IModeState, {ModeType} from 'definition/IModeState';
export type {ModeType};

const initialState: IModeState = {
  type: 'default',
  absolutePoint: {x: 0, y: 0}
};

const modeSlice = createSlice({
  name: 'mode',
  initialState,
  reducers: {
    changeMode(state, { payload }: { payload: ModeType }) {
      state.type = payload;
    },
    updateAbsolutePoint(state, { payload }) {
      state.absolutePoint = payload;
    }
  }
});

export default modeSlice.reducer;
export const { changeMode, updateAbsolutePoint } = modeSlice.actions;
