import { Popover } from '@mui/material';
import Tooltip from '@mui/joy/Tooltip';
import Button from '@mui/joy/Button';
import { changeMode } from '../../store/mode';
import React from 'react';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Feedback from './components/feedback';
import { IconButton, Sheet } from '@mui/joy';

import FeedbackIcon from '@mui/icons-material/Feedback';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';

import { IconMessageCircle2 } from '@tabler/icons-react';

export default () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const { t } = useTranslation();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    dispatch(changeMode('feedback'));
  };

  const handleClose = () => {
    setAnchorEl(null);
    dispatch(changeMode('default'));
  };

  const open = Boolean(anchorEl);
  const id = open ? 'feedback-popover' : undefined;

  return (
    <Sheet sx={{
      backgroundColor: 'var(--joy-palette-background-surface)',
      borderRadius: '50%',


    }}>
      <Tooltip arrow placement='bottom' title={t('feedback.title')}>
        <IconButton
          id="feedbackButton"
          aria-describedby={id}
          variant="plain"
          color='neutral'
          onClick={handleClick}
          size='sm'

          sx={{
            flexDirection: "column",
            "--Button-gap": 0,
            display: { xs: "none", sm: "flex" },
            fontSize: '10px',
            borderRadius: '50%',
          }}
        >
          <IconMessageCircle2 style={{ strokeWidth:"var(--joy-lineHeight-sm)" }}/>
        </IconButton>
      </Tooltip>



      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Feedback onCancel={handleClose} />
      </Popover>
    </Sheet>
  );
};
