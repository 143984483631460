//** Import react
import React, { useEffect } from 'react';

import { styled } from '@mui/material/styles';

//** Import Redux kit
import store, { RootState } from '../../../store';
import { useSelector, useDispatch } from 'react-redux';
import { useGetOfficialTemplatesQuery } from '../../../redux/ResourceAPISlice';

//** Import i18n
import { useTranslation } from 'react-i18next';

import Button from '@mui/joy/Button';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/joy/Divider';
import {  InputAdornment, TextField } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import IconButton from '@mui/joy/IconButton';


const AIAssistantCommandBindingTemplates = props => {
  const {
    openBindingTemplatesDialog,
    setOpenBindingTemplatesDialog,
    currentBindingTemplatesData,
    setCurrentBindingTemplatesData
  } = props;
  const { t } = useTranslation();


  const orgId = useSelector((state: RootState) => state.org.orgInfo.orgId);

  const { data: offTemplateListData = [] } = useGetOfficialTemplatesQuery(orgId);

  const offTemplateList: any = offTemplateListData;

  const [currentTemplateData, setCurrentTemplateData] = React.useState(offTemplateListData);

  // useEffect(() => {
  //   if (offTemplateList) {
  //     setCurrentTemplateData(offTemplateList);
  //   }
  // }, [offTemplateList]);

  const handleClosesDialog = () => {
    setOpenBindingTemplatesDialog(false);
  };

  const handleClickSave = () => {
    handleClosesDialog();
  };

  /**
   * Handles the search input when the user presses the Enter key.
   *
   * @param {string} value - The search input value.
   */
  const handleEnterSearchTemplate = value => {
    // Check if the input value is not empty after trimming whitespace.
    if (value.trim() !== '') {
      // Filter the list of templates to find items whose name contains the search value (case-insensitive).
      const result = offTemplateList.filter(item => {
        return item.name.toLowerCase().includes(value.toLowerCase());
      });

      // Set the filtered template data as the current template data.
      setCurrentTemplateData(result);
    }

    // Call the handleChangeSearchValue function to update the search value.
    handleChangeSearchValue(value);
  };

  /**
   * Handles changes to the search input value.
   *
   * @param {string} value - The search input value.
   */
  const handleChangeSearchValue = value => {
    // Check if the trimmed value is empty.
    if (value.trim() === '') {

      // If the search input is empty, reset the current template data to the full list of templates.
      setCurrentTemplateData(offTemplateList);
    }
  };

  return (
    <Dialog
      open={openBindingTemplatesDialog}
      onClose={handleClosesDialog}
      id="bindingTemplatesDialog"
      sx={{ paper: { maxWidth: 'unset', borderRadius: '6px' } }}
    >
      <Box sx={{ width: '800px', maxWidth: '800px', height: 'auto', padding: '0 24px 10px', boxSizing: 'border-box' }}>
        {/* Title */}
        <Box sx={{ position: 'relative', paddingTop: '40px', width: '100%', marginBottom: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <TextField
            onContextMenu={e => {
              e.stopPropagation();
            }}
            onPaste={e => {
              e.stopPropagation();
            }}
            onChange={(event: any) => handleChangeSearchValue(event.target.value)}
            onKeyDown={(event: any) => {
              if (event.key === 'Enter')
                handleEnterSearchTemplate(event.target.value);
            }}
            sx={{ width: '400px', marginBottom: '15px', '& .MuiOutlinedInput-root': { padding: 0, height: '38px' } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="end">
                  <IconButton sx={{ ml: '-10px' }} size="lg">
                    <SearchOutlinedIcon style={{ strokeWidth:"var(--joy-lineHeight-sm)" }}/>
                  </IconButton>
                </InputAdornment>
              )
            }}
            placeholder={t(
              'promptManagement.searchForTemplatesEnterToConfirm'
            )}
            variant="outlined"
          />
          <Typography level="h4" sx={{ lineHeight: '19px', textAlign: 'center', fontWeight: '500', fontSize: '16px', textTransform: 'capitalize' }}>
            {t('promptManagement.pleaseSelectATemplate')}
          </Typography>
          <Typography level="h4" sx={{ textAlign: 'center', fontWeight: '400', fontSize: '12px', color: 'rgba(35, 41, 48, 0.65)', lineHeight: '15px', marginTop: '8px', marginBottom: '16px' }}>
            {t(
              'promptManagement.mouseClickToSelectDoubleClickToDeselect'
            )}
          </Typography>
          <Divider sx={{ width: '100%' }} />
        </Box>

        <DialogContent sx={{ maxHeight: '350px', padding: 0, overflowX: 'hidden', overflowY: 'scroll' }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
            {currentTemplateData &&
              currentTemplateData.map((item, index) => (
                <TemplatesCard
                  key={index}
                  item={item}
                  index={index}
                  currentBindingTemplatesData={currentBindingTemplatesData}
                  setCurrentBindingTemplatesData={
                    setCurrentBindingTemplatesData
                  }

                />
              ))}

            {currentTemplateData &&
              currentTemplateData.length % 4 === 2 &&
              currentTemplateData
                .slice(0, 2)
                .map((item, index) => (
                  <Card
                    sx={{ width: '174px', opacity: 0 }}
                    key={index}
                  ></Card>
                ))}

            {currentTemplateData &&
              currentTemplateData.length % 4 === 3 &&
              currentTemplateData
                .slice(0, 2)
                .map((item, index) => (
                  <Card
                    sx={{ width: '174px', opacity: 0 }}
                    key={index}
                  ></Card>
                ))}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="plain" onClick={handleClosesDialog}>
            {t('promptManagement.cancel')}
          </Button>
          <Button
            disabled={!currentBindingTemplatesData}
            variant="solid"
            onClick={handleClickSave}
          >
            {t('promptManagement.save')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

/**
 * Functional component for rendering a template card.
 *
 * @param {object} props - The component's props.
 * @param {object} item - The template data to display in the card.
 * @param {number} index - The index of the template in the list.
 * @param {object} currentBindingTemplatesData - The currently selected binding template data.
 * @param {function} setCurrentBindingTemplatesData - Function to set the currently selected binding template.
 * @param {object} sx - CSS classes for styling the component.
 */
const TemplatesCard = ({
  item,
  index,
  currentBindingTemplatesData,
  setCurrentBindingTemplatesData,
   
}) => (
  <Card
    sx={{
      '.cardMediaActive': {
        padding: '2px',
        boxSizing: 'border-box',
        border: '2px solid #F21D6B'
      }, width: '174px', cursor: 'pointer', marginBottom: '10px', position: 'relative', borderRadius: '0px', boxShadow: 'none'
    }}
    onClick={() => setCurrentBindingTemplatesData(item)}
    onDoubleClick={() => {
      setCurrentBindingTemplatesData(null);
    }}
    key={index}
  >
    <CardMedia
      sx={{ boxSizing: 'border-box', borderRadius: '2px', border: '2px solid transparent', '&:hover': { boxSizing: 'border-box', border: '2px solid #F21D6B' } }}
      className={
        currentBindingTemplatesData &&
          currentBindingTemplatesData._id === item._id
          ? 'cardMediaActive'
          : ''
      }
      component="img"
      width="174"
      height="108"
      image={
        item.thumbnail2
          ? item.thumbnail2
          : item.thumbnail
            ? item.thumbnail
            : '/images/abbdgor.png'
      }
    />
    <Typography sx={{   width: '100%', display: 'block', overflow: 'hidden', paddingTop: '4px', whiteSpace: 'nowrap', paddingRight: 0, textOverflow: 'ellipsis', fontSize: '14px', fontWeight: 400 }}>{item.name}</Typography>
  </Card>
);

export default AIAssistantCommandBindingTemplates;
