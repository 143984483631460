import simpleDDP from 'simpleddp';
import ws from 'isomorphic-ws';
import { handleSetConnectionStatus } from '../store/system';
import { handleSetSocketConnectStatus } from '../store/user';

const simpleDDPLogin = require('simpleddp-plugin-login').simpleDDPLogin;
import store from '../store';
import { async } from 'rxjs';

setInterval(async () => {
  try {
    await testConnectionWithTimeout();
  } catch (e) {
    console.log(e);
    store.dispatch(handleSetConnectionStatus(false));
  }
}, 5000);

function timeoutError(ms) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error('Timeout error'));
    }, ms);
  });
}

async function testConnection() {
  try {
    await server.call('testConnection');
    store.dispatch(handleSetConnectionStatus(true));
  } catch (e) {
    store.dispatch(handleSetConnectionStatus(false));
  }
}

async function testConnectionWithTimeout() {
  const response = await Promise.race([testConnection(), timeoutError(5000)]);
}
