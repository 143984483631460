import { Box } from "@mui/joy";
import { Outlet } from "react-router-dom";
import React,{useEffect} from 'react';
import ChatSessionList from "components/boardChatAI/ChatAI/ChatSessionList";
import {handleSetCurrentApp} from 'store/system/index';
import store from 'store/index';

export default function AiAssistantLayout() {
    useEffect(() => {
        store.dispatch(handleSetCurrentApp('agent'));
    }, []);
    return (
        <Box sx={{display:'flex',   flexGrow:1, flexShrink:1,flexDirection:'row', justifyContent:'space-between'}}>
 
            <Box sx={{width:'100%',  flexGrow:1, flexShrink:1}}>
                <Outlet />
            </Box>
        </Box>
    )
}