//** Import react
import React, { useEffect, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';

//** Import i18n
import { useTranslation } from 'react-i18next';

//** Import Redux kit
import store, { RootState } from 'store';
import { useSelector, useDispatch } from 'react-redux';

//** Import Material UI
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/joy/IconButton';
import Box from '@mui/joy/Box';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

 
import UpdateAIAgent from './UpdateAIAgent';
import AIAgentChat from './AIAgentChat';


function AIAgent(props) {
  const { openAiAgentDialog, setOpenAiAgentDialog, type } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClickReturn = () => {
    setOpenAiAgentDialog(false);
  };

  const currentAgent = useSelector(
    (state: RootState) => state.AIAssist.currentAgent
  );

  return (
    <Box>
      <Dialog
        id="aiAgentDialog"
        open={openAiAgentDialog}
        BackdropProps={{ invisible: true }}
        disableEnforceFocus={true}
        fullScreen={true}
      >
        
        <Box sx={{ boxShadow: 'var(--joy-shadow-md)' }}>
          <IconButton onClick={handleClickReturn}>
            <KeyboardArrowLeftIcon style={{ strokeWidth:"var(--joy-lineHeight-sm)" }} />
          </IconButton>
        </Box>

        <Box sx={{width:'99vw', display: 'flex', height: 'calc(100Vh  - 45px)' }}>
          <Box
            sx={{
              width: '50%',
              height: '100%',
      
            }}
          >
            { (
              <UpdateAIAgent type={type} isNew={!Boolean(currentAgent)} />
            )  }
          </Box>
          <Box
            sx={{
              width: '50%',
              height: 'calc(100vh - 45px)',
             
            }}
          >
            <AIAgentChat />
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
}

export default AIAgent;
