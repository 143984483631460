//** Import react
import React, { useRef, useEffect } from 'react';

import { styled } from '@mui/material/styles';

//** Import i18n
import { useTranslation } from 'react-i18next';

//** Import Redux kit
import store, { RootState } from 'store';
import { useUpdateAiAgentMutation, useAddAiAgentMutation } from 'redux/AiAssistApiSlice';
import { useSelector, useDispatch } from 'react-redux';
import { handleSetCurrentAgent } from 'store/AIAssist';

import Button from '@mui/joy/Button';
import Input from '@mui/joy/Input';
import Switch from '@mui/joy/Switch';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Slider from '@mui/joy/Slider';
import  Textarea   from '@mui/joy/Textarea';
import Autocomplete from '@mui/joy/Autocomplete';
import IconButton from '@mui/joy/IconButton';
import AIService from 'services/AIService';
//** Import services
import { UtilityService, FileService, BoardService } from 'services';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import { handleSetOrgInfo } from 'store/org';
 

export default function UpdateAIAgent(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isNew, setIsNew] = React.useState(props.isNew);
  const valueRefName: any = useRef(''); //creating a refernce for TextField Component
  const valueRefDescriiption: any = useRef(''); //creating a refernce for TextField Component
  const { type } = props;
  const orgInfo = useSelector((state: RootState) => state.org.orgInfo);
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [commandText, setCommandText] = React.useState('');
  const [switchValue, setSwitchValue] = React.useState(false);
  const [iconSrc, setIconSrc] = React.useState('');
  const [backgroundUrl, setBackgroundUrl] = React.useState('');
  const [temperatureNum, setTemperatureNum] = React.useState(0.7);
  const [maximumLengthNum, setMaximumLengthNum] = React.useState(1500);
  const [topPNum, setTopPNum] = React.useState(1);
  const [frequencyPenaltyNum, setFrequencyPenaltyNum] = React.useState(0);
  const [presencePenaltyNum, setPresencePenaltyNum] = React.useState(0);
  const [bestOfNum, setBestOfNum] = React.useState(1);
  const [sectionSelect, setSectionSelect] = React.useState([]);
  const [AIModelSelect, setAIModelSelect] = React.useState('0');

  const sectionSelectData = [
    'General',
    'Ideate',
    'Extract',
    'Blog',
    'Email',
    'Sales & Marketing',
    'Product',
    'Persona',
    'Storytelling',
    'Social Media',
    'Video',
    'SEO',
    'Translation',
    'Unclassified'
  ];

  const AIModelSelectData = ['gpt-3.5', 'gpt-4'];

  const [updateAiAgent] = useUpdateAiAgentMutation();
  const [addAiAgent] = useAddAiAgentMutation();
  const userInfo = useSelector((state: RootState) => state.user.userInfo);

  const currentAiAgentData = useSelector(
    (state: RootState) => state.AIAssist.currentAgent
  );

  useEffect(() => {
    if (!currentAiAgentData) return;
    setName(currentAiAgentData.name || '');
    setAIModelSelect(
      String(
        AIModelSelectData.indexOf(currentAiAgentData.gptModel) > -1
          ? AIModelSelectData.indexOf(currentAiAgentData.gptModel)
          : 0
      )
    );
    setDescription(currentAiAgentData.description || '');
    setCommandText(currentAiAgentData.command || '');
    setTemperatureNum(currentAiAgentData.temperature || 0.7);
    setMaximumLengthNum(currentAiAgentData.maximumLength || 1500);
    setTopPNum(currentAiAgentData.topP || 1);
    setFrequencyPenaltyNum(currentAiAgentData.frequencyPenalty || 0);
    setPresencePenaltyNum(currentAiAgentData.presencePenalty || 0);
    setBestOfNum(currentAiAgentData.bestOf || 1);
    setIconSrc(currentAiAgentData.icon || '');
    setBackgroundUrl(currentAiAgentData.backgroundUrl || '');
    setSwitchValue(currentAiAgentData.isFeatured || false);
    if (
      currentAiAgentData.section &&
      currentAiAgentData.section !== ''
    ) {
      setSectionSelect(
        typeof currentAiAgentData.section === 'string'
          ? [currentAiAgentData.section]
          : currentAiAgentData.section
      );
    } else {
      setSectionSelect([]);
    }
  }, [currentAiAgentData]);


  const handlePromptRefine = async () => {
    if (commandText === '') {
      Boardx.Util.Msg.info(t('adminPage.pleaseFillAllFields'));
      return;
    }

    Boardx.Util.Msg.info('working on the new prompt');
    const prompt = `
    As a prompt engineer with 20+ years of experience and multiple PhDs, focus on optimizing prompts for LLM performance. Apply these techniques:

**Personas**: Ensures consistent response styles and improves overall performance.
**Multi-shot Prompting**: Use example-based prompts for consistent model responses.
**Positive Guidance**: Encourage desired behavior; avoid 'don'ts'.
**Clear Separation**: Distinguish between instructions and context (e.g., using triple-quotes, line breaks).
**Condensing**: Opt for precise, clear language over vague descriptions.
**Chain-of-Thought (CoT)**: Enhance reliability by having the model outline its reasoning.

Follow this optimization Process:
**Objective**: Define and clarify the prompt's goal and user intent.
**Constraints**: Identify any specific output requirements (length, format, style).
**Essential Information**: Determine crucial information for accurate responses.
**Identify Pitfalls**: Note possible issues with the current prompt.
**Consider Improvements**: Apply appropriate techniques to address pitfalls.
**Craft Improved Prompt**: Revise based on these steps. Enclose the resulting prompt.
**format**: don't show the optimiazatin process or suggestion, must just return the new prompt result.

Use your expertise to think through each step methodically and develop the new prompt.

 this is the prompt or the requirement of the prompt below:
  
  ` + commandText + ' =========== \n Craft Improved Prompt: ';

    const result = await AIService.getInstance().handleRefinePrompt(prompt, 0.5);

    var message = "Are you sure you want to replace prompt with the this new version?" + "\n" + result;
    if (confirm(message)) {
      setCommandText(result);
      Boardx.Util.Msg.info('new prompt has been added');
      //   // Code to execute if the user clicks "OK"
      console.log("Confirmed");
    } else {
      console.log("Cancelled");
    }


  }


  const handleSaveEditAIAgent = async () => {
    let chatSessionId = '';
    if (
      valueRefName.current.value === '' ||
      valueRefDescriiption.current.value === '' ||
      commandText === ''
    ) {
      Boardx.Util.Msg.info(t('adminPage.pleaseFillAllFields'));
      return;
    }
    const agentId=isNew?UtilityService.getInstance().generateWidgetID():currentAiAgentData._id;

    if(isNew){
      const chatSession= await AIService.getInstance().createNewChatSession(userInfo.userId, orgInfo.orgId, '', 'gpt-3.5-turbo');
      chatSessionId=chatSession._id;
    }else{
      chatSessionId=currentAiAgentData.chatSessionId;
    }
 

    const data = {
      _id: agentId,
      chatSessionId: chatSessionId,
      name: name,
      description: description,
      command: commandText,
      section: sectionSelect,
      gptModel: AIModelSelectData[AIModelSelect],
      temperature: temperatureNum,
      maximumLength: maximumLengthNum,
      topP: topPNum,
      frequencyPenalty: frequencyPenaltyNum,
      presencePenalty: presencePenaltyNum,
      bestOf: bestOfNum,
      icon: iconSrc,
      type:type,
      backgroundUrl: backgroundUrl,
      isFeatured: switchValue,
      lastUpdateUser: store.getState().user.userInfo.userName,
      lastUpdateUserId: store.getState().user.userInfo.userId,
      createdBy: store.getState().user.userInfo.userId,
      lastUpdateAt: new Date()
    };

    if(isNew){
      const result = await addAiAgent({ agentData: data });
      setIsNew(false);
    }else{
      const result = await updateAiAgent({ newAgentData: data });
    }
   


    dispatch(handleSetCurrentAgent(data));
    AIService.getInstance().InitializeChatSessionByID(
      data.chatSessionId)



    // Display a success message after successfully updating the command.
    Boardx.Util.Msg.success(t('adminPage.updateSuccessfully'));
  };

  const handleSwitchChange = event => {
    setSwitchValue(!switchValue);
  };

  const handleUploadIconFile = async e => {
    e.preventDefault();

    const files = e.target.files;

    // Check if more than one file is selected.
    if (files.length > 1) {
      // Reset the file input value and display an alert message if multiple files are selected.
      const v = document.getElementById('iconFile') as HTMLInputElement;
      v.value = '';

      alert('Please select a single file to upload!');
      return;
    }

    // Get the upload path for the icon file.
    const r2UploadPath = UtilityService.getInstance().getr2UploadPath(
      store.getState().board.board
    );

    // Upload the selected file to the specified path and get the file key.
    const key: any = await FileService.getInstance().uploadFileToR2Async(
      r2UploadPath,
      files[0],
      {
        progress(ee) { }
      }
    );

    // Set the iconSrc state with the uploaded file key.
    setIconSrc(key);
  };

  const handleUploadBgImageFile = async e => {
    e.preventDefault();

    const files = e.target.files;

    // Check if more than one file is selected.
    if (files.length > 1) {
      // Reset the file input value and display an alert message if multiple files are selected.
      const v = document.getElementById(
        'backgroundImageFile'
      ) as HTMLInputElement;
      v.value = '';
      alert('Please select a single file to upload!');
      return;
    }

    // Get the upload path for the background image file.
    const r2UploadPath = UtilityService.getInstance().getr2UploadPath(
      store.getState().board.board
    );

    // Upload the selected file to the specified path and get the file key.
    const key: any = await FileService.getInstance().uploadFileToR2Async(
      r2UploadPath,
      files[0],
      {
        progress(ee) { }
      }
    );

    // Set the backgroundUrl state with the uploaded file key.
    setBackgroundUrl(key);
  };

  return (
    <Box sx={{
      height: 'calc(100vh - 60px)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width:'50vw'
    }}>
      <Box
        sx={{
          display:'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: 'calc(100vh - 100px)',
          overflowY: 'scroll',
          overflowX: 'hidden',
          padding: '0px 20px',
          gap: 2
        }}
      >
        {/* CATEGORY */}
        <Box sx={{ flexShrink:0, flexGrow:0 }}>
          <Typography variant="plain">
            {t('promptManagement.category')}
          </Typography>
          <Autocomplete
            id="agentSectionSelect"
            multiple
            options={sectionSelectData }
            getOptionLabel={option => option}
             onChange={(event, value) => setSectionSelect(value)}
             placeholder="Category" 
             defaultValue={currentAiAgentData?.section }
             slotProps={{
              listbox: {
                sx: (theme) => ({
                  zIndex: theme.vars.zIndex.modal
                })
              }
            }}
          />
        </Box>

        {/* Name */}
        <Box sx={{ flexShrink:0, flexGrow:0}}>
          <Typography variant="plain">
            {t('promptManagement.name')}
          </Typography>
          <Input
            value={name}
  
            fullWidth
            id="name"
            ref={valueRefName}
            placeholder={t('promptManagement.name')}
            type="text"
            variant="outlined"
            onChange={(e) => {
     
              setName(e.target.value);
            }}
          />
        </Box>

        {/* Desription */}
        <Box sx={{ flexShrink:0, flexGrow:0}}>
          <Typography variant="plain">
            {t('promptManagement.description')}
          </Typography>
          <Input
            id="desription"
            value={description}
            ref={valueRefDescriiption}
            placeholder={t('promptManagement.description')}
            type="text"
            variant="outlined"
            fullWidth
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </Box>

        {/* Command */}
        <Box sx={{ flexShrink:0, flexGrow:1 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
            <Typography variant="plain" sx={{ alignSelf: 'center' }}>
              {t('promptManagement.prompt')}
            </Typography>
            <IconButton aria-label="AI Assistant" onClick={handlePromptRefine}>
              <AutoFixHighOutlinedIcon style={{ strokeWidth:"var(--joy-lineHeight-sm)" }} />
            </IconButton>
          </Box>
          <Textarea
            value={commandText}
            id="command"
            placeholder={t('promptManagement.prompt')}
            variant='outlined'
            style={{
              width: '100%',
              height: '95%',
              resize: 'none',
 
            }}
            onChange={e => setCommandText(e.target.value)}
            minRows={10}
          />
        </Box>



        {/* Temperature  Maximum Length  TopP */}
        <Box
          sx={{ display: 'flex',flexShrink:0, flexGrow:0, justifyContent: 'flex-start', padding: '10px',gap: '20px', marginTop: '12px' }}
        >

          {/* Temperature */}
          <Box sx={{ width: '184px' }}>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
              <Typography variant="plain">
                {t('promptManagement.temperature')}
              </Typography>
              <span style={{
                fontWeight: 400,
                fontSize: '12px',
                letterSpacing: '0.4px',
              }}>
                {temperatureNum}
              </span>
            </Box>

            <Slider
              id="temperatureSlider"
              variant='solid'
              onChange={(event: any, newValue) =>
                setTemperatureNum(event.target.value)
              }
              max={1}
              defaultValue={0.7}
              value={temperatureNum ? temperatureNum : 0}
              min={0}
              aria-label="Default"
              valueLabelDisplay="auto"
              step={0.01}
            />
          </Box>
          {/* Maximum Length */}
          <Box sx={{ width: '184px' }}>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}>
              <Typography variant="plain">
                {t('promptManagement.maximumLength')}
              </Typography>
              <span style={{
                fontWeight: 400,
                fontSize: '12px',
                letterSpacing: '0.4px',
                 
              }}>
                {maximumLengthNum}
              </span>
            </Box>

            <Slider
              id="MaximumLengthSlider"
              variant='solid'
              onChange={(event: any, newValue) =>
                setMaximumLengthNum(event.target.value)
              }
              max={4000}
              defaultValue={256}
              value={maximumLengthNum ? maximumLengthNum : 0}
              min={0}
              aria-label="Default"
              valueLabelDisplay="auto"
              step={1}
            />
          </Box>

        </Box>

   
        {/* Icon */}
        <Box sx={{ flexShrink:0, flexGrow:0 }}>
          <Typography
            style={{ marginBottom: '0px' }}
            variant="plain"
          >
            {t('promptManagement.icon')}
          </Typography>
          <Box sx={{
            display: 'flex',
            height: '40px',
            alignItems: 'flex-end'
          }}>
            <Box sx={{ position: 'relative', overflow: 'hidden' }}>
              <Button
                variant="outlined"
                sx={{marginRight:'10px'}}
                id="iconFileBtn"
              >
                {t('promptManagement.uploadFile')}
              </Button>
              <input
                style={{
                  width: '150px',
                  height: '30px',
                  position: 'absolute',
                  opacity: 0,
                  cursor: 'pointer',
                  left: '0px',
                  lineHeight: 7
                }}
                id="iconFile"
                multiple
                name="files"
                onChange={handleUploadIconFile}
                type="file"
                accept=".png,.jpeg,.jpg,.webp,.gif,.svg"
              />
            </Box>

            <img
              style={{ width: '24px', height: '24px' }}
              src={
                Boolean(iconSrc)
                  ? iconSrc
                  : '/images/ImageCommandBackgroundImg.png'
              }
            />
          </Box>
        </Box>

        {/* Background Image */}
        <Box sx={{ flexShrink:0, flexGrow:0}}>
          <Typography
            style={{ marginBottom: '0px' }}
            variant="plain"
          >
            {t('promptManagement.backgroundImage')}
          </Typography>
          <Box sx={{
            display: 'flex',
            height: '40px',
            alignItems: 'flex-end'
          }}>
            <Box sx={{ position: 'relative', overflow: 'hidden' }}>
              <Button
                variant="outlined"
              sx={{marginRight:'10px'}}
                id="imagesFileBtn"
              >
                {t('promptManagement.uploadFile')}
              </Button>
              <input
                style={{
                  width: '150px',
                  height: '30px',
                  position: 'absolute',
                  opacity: 0,
                  cursor: 'pointer',
                  left: '0px',
                  lineHeight: 7
                }}
                id="backgroundImageFile"
                multiple
                name="files"
                onChange={handleUploadBgImageFile}
                type="file"
                accept=".png,.jpeg,.jpg,.webp,.gif,.svg"
              />
            </Box>

            <img
              style={{ width: '52px', height: '39px' }}
              src={
                Boolean(backgroundUrl)
                  ? backgroundUrl
                  : '/images/ImageCommandBackgroundImg.png'
              }
            />
          </Box>
        </Box>

        {/* Switch */}
        <Box sx={{ flexShrink:0, flexGrow:0 }}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <Typography

              sx={{
                mb: 0, letterSpacing: '0.15px',
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '24px',
   
              }}
              variant="plain"
            >
              {t('promptManagement.isFeatured')}:{' '}
            </Typography>
            <Switch
              onChange={handleSwitchChange}
              checked={switchValue}
       
            />
          </Box>
        </Box>
      </Box>

      <Box sx={{
          display: 'flex',
          justifyContent: 'flex-end',
    
          mr: '20px',
          height: '58px',
          alignItems: 'center',
     
      }}>
        <Button
 
          variant="solid"
          onClick={handleSaveEditAIAgent}
        >
          {/* {t('promptManagement.save')} */}
          Update
        </Button>
      </Box>
    </Box>
  );
}
