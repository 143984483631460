import React, { useState, useEffect } from 'react';
import { Popper, MenuList, Popover } from '@mui/material';
import LazyLoad from 'react-lazyload';
import OrganizationCreateOrgModal from 'components/org/OrganizationCreateOrgModal';
import ArrowRight from '@mui/icons-material/ArrowRight';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/joy/Box';
import store, { RootState } from '../../store';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useGetPendingDeletedBoardQuery } from '../../redux/BoardListAPISlice';
import { useGetOrgListQuery } from '../../redux/OrgAPISlice';
import Option from '@mui/joy/Option';
import { useCheckActionPermissionOfTeamMutation } from '../../redux/PermissionApiSlice';
import Select, { selectClasses } from '@mui/joy/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { BoardService, OrgService } from '../../services';
import Divider from '@mui/joy/Divider';
import { IconUsersGroup } from '@tabler/icons-react';
import {   DialogContent, DialogTitle, Modal, ModalClose, ModalDialog } from '@mui/joy';

export default function TeamManagement() {
    //use
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const { t } = useTranslation();

    //org setting
    const isOrgAdmin = useSelector((state: RootState) => state.org.orgInfo.role) !== 'member' ? true : false;
    const orgInfo = useSelector((state: RootState) => state.org.orgInfo);
    const [handleCheckActionPermissionOfTeam] = useCheckActionPermissionOfTeamMutation();
    //user
    const userInfo = useSelector((state: RootState) => state.user.userInfo);

    //orgList
    const [switchOrgList, setSwitchOrgList] = useState(false);
 
    const { data: orgList = [], isLoading, isError } = useGetOrgListQuery({ userId: userInfo.userId });
    const orgName = useSelector((state: RootState) => state.org.orgInfo.name);

    //react dom
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openCreateOrg, setOpenCreateOrg] = useState(false);
    const [roleForManage, setRoleForManage] = useState(false);
    const [open2, setOpen2] = useState(false);

    const { data: deletedBoardList = [] } = useGetPendingDeletedBoardQuery({
        orgId: orgInfo.orgId
    });

    const checkActionPermission = async (permissionName) => {
        let data = {
            permissionName: permissionName,
            role: orgInfo.role
        }
        return await handleCheckActionPermissionOfTeam(data);
    }

    useEffect(() => {
        const deal = async () => {
            let checkRoleOfManageTeam = await checkActionPermission('Edit Team');
            //@ts-ignore
            setRoleForManage(checkRoleOfManageTeam && checkRoleOfManageTeam.data);
        };
        deal();
    }, [orgInfo]);


    const handleCreateOrgListDOM = () => {
        return orgList.map(obj => (
            <MenuItem
                disableRipple
                id={obj.orgId}
                key={obj.orgId}
                onClick={switchOrg.bind(
                    this,
                    obj.orgId,
                    obj.name,
                    obj.role
                )}
            >
                {obj.name}
            </MenuItem>
        ));
    };

    const handleSwitchOrgMenu = () => {
        setSwitchOrgList(!switchOrgList);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen2(false)
        setSwitchOrgList(false);
    };

    const handleClick = (event) => {
        setAnchorEl(event?.currentTarget);
        setOpen2(true);
        setSwitchOrgList(false);
    };


    const handleCloseCreateOrg = () => {
        setOpenCreateOrg(false);
    };
    //切换org名称
    const switchOrg = (orgId, orgNameSwitch, role) => {
        OrgService.getInstance().loadOrganization(orgId, orgNameSwitch, role)
        navigate('/recent');
        handleClose();
    };
    const handleClickOpenManageOrgMenu = async () => {
        if (!roleForManage) {
            Boardx.Util.Msg.info(
                t(
                    'pages.listPage.roomSettings.youHaveNoRoleToEnterTeamManageDashboard'
                )
            );
            return;
        }
        handleClose();
        navigate('/teamsetting');
    };


    return (<Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',

        minHeight: '60px',
        textOverflow: 'ellipsis',
        gap: '10px',
    }}>


        <Select
            value={orgName}
            onChange={(e) => handleClick(e)}
            variant="plain"
            indicator={<KeyboardArrowDown />}
            startDecorator={<IconUsersGroup fontSize={'20px'} />}
            sx={{

                width: 200,
                [`& .${selectClasses.indicator}`]: {
                    transition: '0.2s',
                    [`&.${selectClasses.expanded}`]: {
                        transform: 'rotate(-180deg)',
                    },
                },
            }}

            slotProps={{

                listbox: {
                    sx: (theme) => ({
                        zIndex: theme.vars.zIndex.modal
                    })
                }
            }}
        >
            <Option value={orgName}>{orgName}</Option>
            {roleForManage && (
                <Option value="manageOrg" onClick={handleClickOpenManageOrgMenu}>{t('pages.manageOrganization')}</Option>
            )}

            {isOrgAdmin && <Divider sx={{ my: 0.5 }} />}
            {orgList.length > 1 && (
                <Option
                    value="switchOrg"

                    onClick={(e) => {
                        e.preventDefault();
                        handleSwitchOrgMenu();
                    }}
                    id="switchOrgMenu"
                >
                    {t('pages.switchOrganization')}
                    <ArrowRight />
                </Option>
            )}
            <Option
                value="createOrg"
                onClick={() => {
                    setOpenCreateOrg(true);
                    handleClose();
                }}
            >
                {t('pages.createOrganization')}
            </Option>
        </Select>

        <LazyLoad once>
            <OrganizationCreateOrgModal
                handleClose={handleCloseCreateOrg}
                openCreateOrg={openCreateOrg}
                setOpenCreateOrg={setOpenCreateOrg}
            />
        </LazyLoad>
        <Modal


            onClose={handleClose}
            open={switchOrgList}
        >
            <ModalDialog title={t('pages.switchOrganization')}
            sx={{
                overflow: 'auto',
            }} >

                <ModalClose onClick={handleClose} />
                <DialogTitle>{t('pages.switchOrganization')}</DialogTitle>
                <DialogContent sx={{overflow:'auto'}}>
                <MenuList>{handleCreateOrgListDOM()}</MenuList>
                </DialogContent>
            </ModalDialog>
        </Modal>

    </Box>
    );
}