//** Import react
import React, { useRef, Component, useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';

//** Import i18n
import { useTranslation } from 'react-i18next';
import store, { RootState } from '../../../store';
import { useSelector, useDispatch } from 'react-redux';
import { handleSetCurrentAdminAiImagePromptData } from '../../../store/AIAssist';

//** Import Redux kit
import {
  useAdminUpdateAiCustomStyleCommandMutation,
  useAdminDeleteAiCustomStyleCommandMutation
} from '../../../redux/AiAssistApiSlice';
import Button from '@mui/joy/Button';
import TextField from '@mui/material/TextField';
import Switch from '@mui/joy/Switch';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/joy/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Textarea } from '@mui/joy';
import Option from '@mui/joy/Option'

import { FileService, UtilityService } from '../../../services';



const inputTitleStyle = {
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '15px',
  marginBottom: '6px'
}


export default function AIAssistantImagesDetails({
  setOpenAiImagePromptDialog
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const valueRefName: any = useRef(''); //creating a refernce for TextField Component
  const valueRefWeight: any = useRef(''); //creating a refernce for TextField Component
  const valueRefDescriiption: any = useRef(''); //creating a refernce for TextField Component
  const valueRefCategory: any = useRef(''); //creating a refernce for TextField Component
  const valueRefWidth: any = useRef(''); //creating a refernce for TextField Component
  const valueRefHeight: any = useRef(''); //creating a refernce for TextField Component

  const [switchValue, setSwitchValue] = React.useState(false);
  const [name, setName] = React.useState('');
  const [weight, setWeight] = React.useState(undefined);
  const [description, setDescription] = React.useState('');
  const [category, setCategory] = React.useState('');
  const [commandText, setCommandText] = React.useState('');
  const [width, setWidth] = React.useState('');
  const [height, setHeight] = React.useState('');
  const [iconSrc, setIconSrc] = React.useState('');
  const [backgroundUrl, setBackgroundUrl] = React.useState('');
  const [typeSelect, setTypeSelect] = React.useState('0');
  const typeSelectData = ['', 'Artists', 'Styles', 'Medium'];

  const currentRowData = useSelector(
    (state: RootState) => state.AIAssist.currentAdminAiImagePromptData
  );

  const [adminUpdateAiCustomStyleCommand, { isError, isSuccess, isLoading }] =
    useAdminUpdateAiCustomStyleCommandMutation();
  const [adminDeleteAiCustomStyleCommand] =
    useAdminDeleteAiCustomStyleCommandMutation();

  useEffect(() => {
    if (!currentRowData) return;
    setName(currentRowData.name || '');
    setTypeSelect(
      String(
        typeSelectData.indexOf(currentRowData.type) > -1
          ? typeSelectData.indexOf(currentRowData.type)
          : 0
      )
    );
    setWeight(currentRowData.weight || '');
    setDescription(currentRowData.description || '');
    setCategory(currentRowData.category || '');
    setCommandText(currentRowData.command || '');
    setSwitchValue(currentRowData.isFeatured || false);
    setWidth(currentRowData.width || '');
    setHeight(currentRowData.height || '');
    setIconSrc(currentRowData.icon || '');
    setBackgroundUrl(currentRowData.backgroundUrl || '');
  }, [currentRowData]);

  const handleSaveEditCommand = async () => {
    if (
      valueRefName.current.value === '' ||
      // valueRefDescriiption.current.value === '' ||
      valueRefCategory.current.value === ''
      // ||
      // commandText === ''
    ) {
      Boardx.Util.Msg.info(t('adminPage.pleaseFillAllFields'));
      return;
    }

    const data = {
      _id: currentRowData._id,
      name: valueRefName.current.value,
      type: typeSelectData[typeSelect],
      weight: valueRefWeight.current.value,
      description: valueRefDescriiption.current.value,
      category: valueRefCategory.current.value,
      command: commandText,
      width: valueRefWidth.current.value,
      height: valueRefHeight.current.value,
      icon: iconSrc,
      backgroundUrl: backgroundUrl,
      isFeatured: switchValue,
      lastUpdateUser: store.getState().user.userInfo.userName,
      lastUpdateUserId: store.getState().user.userInfo.userId,
      lastUpdateAt: new Date()
    };

    await adminUpdateAiCustomStyleCommand({
      updateCommand: data,
      currentSelectCommand: currentRowData
    });
    // Boardx.Util.Msg.info(err.reason);
    Boardx.Util.Msg.info(t('adminPage.updateSuccessfully'));
  };

  const handleDeleteCommand = async () => {
    const row = currentRowData;
    let text = 'confirm to delete the commmand: ' + currentRowData.name + '?';
    if (confirm(text) == false) {
      return;
    }

    if (row) {
      await adminDeleteAiCustomStyleCommand({ commandId: row._id });
      Boardx.Util.Msg.info(t('adminPage.deleteSuccessfully'));
      dispatch(handleSetCurrentAdminAiImagePromptData(null));
      // Boardx.Util.Msg.info(err.reason);
    } else {
      Boardx.Util.Msg.info(t('adminPage.selectTableContent'));
    }
    setOpenAiImagePromptDialog(false);
  };

  const handleSwitchChange = event => {
    setSwitchValue(event.target.checked);
  };

  const handleUploadIconFile = async e => {
    e.preventDefault();
    const input = document.getElementById('iconFile') as HTMLInputElement;
    const files = input.files;
    if (files.length > 1) {
      const v = document.getElementById('iconFile') as HTMLInputElement;
      v.value = '';
      alert('Please select a single file to upload!');
      return;
    }
    const r2UploadPath = UtilityService.getInstance().getr2UploadPath(
      store.getState().board.board
    );
    const key: any = await FileService.getInstance().uploadFileToR2Async(
      r2UploadPath,
      files[0],
      {
        progress(ee) { }
      }
    );
    setIconSrc(key);
  };

  const handleUploadBgImageFile = async e => {
    e.preventDefault();
    const input = document.getElementById(
      'backgroundImageFile'
    ) as HTMLInputElement;
    const files = input.files;

    if (files.length > 1) {
      const v = document.getElementById(
        'backgroundImageFile'
      ) as HTMLInputElement;
      v.value = '';
      alert('Please select a single file to upload!');
      return;
    }
    const r2UploadPath = UtilityService.getInstance().getr2UploadPath(
      store.getState().board.board
    );
    const key: any = await FileService.getInstance().uploadFileToR2Async(
      r2UploadPath,
      files[0],
      {
        progress(ee) { }
      }
    );
    setBackgroundUrl(key);
  };

  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    }}>
      <Box
        sx={{
          flex: 1,
          p: '0 20px 100px',
          overflowY: 'scroll',
          overflowX: 'hidden'
        }}
      >
        {/* CATEGORY */}
        <Box sx={{ marginTop: '12px', '& .MuiOutlinedInput-notchedOutline': { display: 'none' } }}  >
          <Typography sx={{ marginTop: '12px' }} variant="plain">
            CATEGORY
          </Typography>
          <TextField
 
            fullWidth
            inputRef={valueRefCategory}
            id="category"
            placeholder="Content"
            type="text" 
            onChange={() => setCategory(valueRefCategory.current.value)}
            onBlur={() =>
              dispatch(
                handleSetCurrentAdminAiImagePromptData({
                  ...currentRowData,
                  category: valueRefCategory.current.value
                })
              )
            }
            value={category}
          />
        </Box>

        {/* Name */}
        <Box sx={{ marginTop: '12px', '& .MuiOutlinedInput-notchedOutline': { display: 'none' } }}  >
          <Typography sx={inputTitleStyle} variant="plain">
            NAME
          </Typography>
          <TextField
            sx={{ root: { padding: 0, fontWeight: 400, fontSize: '12px', lineHeight: '13px', marginLeft: '10px' } }}
            id="name"
            inputRef={valueRefName}
            placeholder="name"
            type="text"
            variant="outlined"
            value={name}
            fullWidth
            onChange={() => setName(valueRefName.current.value)}
            onBlur={() =>
              dispatch(
                handleSetCurrentAdminAiImagePromptData({
                  ...currentRowData,
                  name: valueRefName.current.value
                })
              )
            }
          />
        </Box>

        {/* Type  section */}
        <Box sx={{ marginTop: '12px', display: 'flex' }}  >
          <Box sx={{ mr: '12px', width: '30%' }}>
            <Typography sx={inputTitleStyle} variant="plain">
              TYPE
            </Typography>
            <Select
              slotProps={{
                listbox: {
                  sx: (theme) => ({
                    zIndex: theme.vars.zIndex.modal
                  })
                }
              }}
              sx={{
                width: '100%',
                '& .MuiSelect-select': {
                  padding: 0,
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '13px',
     
                  marginLeft: '10px',
                },

                '& .MuiMenuItem-root': {
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '13px',
                },
              }}
              id="commandTypeSelect"

              onChange={(event, newValue) => {
                setTypeSelect(newValue);
              }}
              value={typeSelect}

            >
              {typeSelectData && typeSelectData.length > 0
                ? typeSelectData.map((type, index) => {
                  return (
                    <Option
                      key={index}
                      sx={{ fontSize: '14px' }}
                      value={index}
                    >
                      {type}
                    </Option>
                  );
                })
                : null}
            </Select>
          </Box>
        </Box>

        {/* Weight  Width  Height */}
        <Box sx={{ marginTop: '12px', display: 'flex' }}  >
          <Box sx={{ mr: '12px', width: '30%' }}>
            <Typography sx={inputTitleStyle} variant="plain">
              WEIGHT
            </Typography>
            <TextField
              sx={{ root: { padding: 0, fontWeight: 400, fontSize: '12px', lineHeight: '13px',   marginLeft: '10px' } }}
              id="weight"
              inputRef={valueRefWeight}
              placeholder="weight"
              type="text"
              variant="outlined"
              onChange={() => setWeight(valueRefWeight.current.value)}
              onBlur={() =>
                dispatch(
                  handleSetCurrentAdminAiImagePromptData({
                    ...currentRowData,
                    weight: valueRefWeight.current.value
                  })
                )
              }
              value={weight}
            />
          </Box>

          <Box sx={{ mr: '12px', width: '30%' }}>
            <Typography sx={inputTitleStyle} variant="plain">
              WIDTH
            </Typography>
            <TextField
              sx={{ root: { padding: 0, fontWeight: 400, fontSize: '12px', lineHeight: '13px',   marginLeft: '10px' } }}
              id="width"
              inputRef={valueRefWidth}
              placeholder="width"
              maxRows={4}
              type="text"
              variant="outlined"
              onChange={() => setWidth(valueRefWidth.current.value)}
              onBlur={() =>
                dispatch(
                  handleSetCurrentAdminAiImagePromptData({
                    ...currentRowData,
                    width: valueRefWidth.current.value
                  })
                )
              }
              value={width}
            />
          </Box>

          <Box sx={{ width: '30%' }}>
            <Typography sx={inputTitleStyle} variant="plain">
              HEIGHT
            </Typography>
            <TextField
              sx={{ root: { padding: 0, fontWeight: 400, fontSize: '12px', lineHeight: '13px',   marginLeft: '10px' } }}
              id="height"
              placeholder="height"
              inputRef={valueRefHeight}
              maxRows={4}
              type="text"
              variant="outlined"
              onChange={() => setHeight(valueRefHeight.current.value)}
              onBlur={() =>
                dispatch(
                  handleSetCurrentAdminAiImagePromptData({
                    ...currentRowData,
                    height: valueRefHeight.current.value
                  })
                )
              }
              value={height}
            />
          </Box>
        </Box>

        {/* Desription */}
        <Box sx={{ marginTop: '12px', '& .MuiOutlinedInput-notchedOutline': { display: 'none' } }}  >
          <Typography sx={inputTitleStyle} variant="plain">
            DESCRIPTION
          </Typography>
          <TextField
            sx={{ root: { padding: 0, fontWeight: 400, fontSize: '12px', lineHeight: '13px',   marginLeft: '10px' } }}
            id="desription"
            inputRef={valueRefDescriiption}
            placeholder="desription"
            type="text"
            variant="outlined"
            fullWidth
            onChange={() => setDescription(valueRefDescriiption.current.value)}
            onBlur={() =>
              dispatch(
                handleSetCurrentAdminAiImagePromptData({
                  ...currentRowData,
                  description: valueRefDescriiption.current.value
                })
              )
            }
            value={description}
          />
        </Box>

        {/* Command */}
        <Box sx={{ marginTop: '12px', '& .MuiOutlinedInput-notchedOutline': { display: 'none' } }}  >
          <Typography sx={inputTitleStyle} variant="plain">
            PROMPT
          </Typography>
          <Textarea
            id="command"
            placeholder="Prompt"
            style={{
              width: '100%',
              resize: 'none',
           
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '24px',
              letterSpacing: '0.15px',
              padding: '5px',
              overflow: 'unset !important'
            }}
            onChange={e => {
              setCommandText(e.target.value);
              dispatch(
                handleSetCurrentAdminAiImagePromptData({
                  ...currentRowData,
                  command: e.target.value
                })
              );
            }}
            value={commandText}
          />
        </Box>

        {/* Icon */}
        <Box sx={{ marginTop: '12px', '& .MuiOutlinedInput-notchedOutline': { display: 'none' } }}  >
          <Typography sx={inputTitleStyle} variant="plain">
            ICON
          </Typography>
          <Box sx={{
            display: 'flex',
            height: '40px',
            alignItems: 'flex-end'
          }}>
            <input
              data-shape="image"
              id="iconFile"
              multiple
              name="files"
              onChange={handleUploadIconFile}
              type="file"
              accept=".png,.jpeg,.jpg,.webp,.gif,.svg"
            />
            <img
              style={{ width: '24px', height: '24px' }}
              src={
                Boolean(iconSrc)
                  ? iconSrc
                  : '/images/ImageCommandBackgroundImg.png'
              }
            />
          </Box>
        </Box>

        {/* Background Image */}
        <Box sx={{ marginTop: '12px', '& .MuiOutlinedInput-notchedOutline': { display: 'none' } }}  >
          <Typography sx={inputTitleStyle} variant="plain">
            BACKGROUND IMAGE
          </Typography>
          <Box sx={{
            display: 'flex',
            height: '40px',
            alignItems: 'flex-end'
          }}>
            <input
              data-shape="image"
              id="backgroundImageFile"
              multiple
              name="files"
              onChange={handleUploadBgImageFile}
              type="file"
              accept=".png,.jpeg,.jpg,.webp,.gif,.svg"
            />
            <img
              style={{ width: '52px', height: '39px' }}
              src={
                Boolean(backgroundUrl)
                  ? backgroundUrl
                  : '/images/ImageCommandBackgroundImg.png'
              }
            />
          </Box>
        </Box>

        {/* Switch */}
        <Box>
          <Switch onChange={handleSwitchChange} checked={switchValue} />
        </Box>

        {/* create User info */}
        <Box sx={{ marginTop: '12px', '& .MuiOutlinedInput-notchedOutline': { display: 'none' } }}  >
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{
              letterSpacing: '0.15px',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '24px',

            }} variant="plain">
              Created by:{' '}
              {currentRowData && currentRowData.createUser
                ? currentRowData.createUser
                : 'admin'}
            </Typography>
            <Typography sx={{
              marginLeft: '22px',
              letterSpacing: '0.15px',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '24px',

            }} variant="plain">
              Created Time:{' '}
              {currentRowData && currentRowData.createdAt
                ? currentRowData.createdAt.toLocaleString()
                : '2021/08/01 00:00:00'}
            </Typography>
          </Box>
        </Box>

        {/* Last Update User info */}
        <Box sx={{ marginTop: '12px', '& .MuiOutlinedInput-notchedOutline': { display: 'none' } }}  >
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{
              letterSpacing: '0.15px',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '24px',

            }} variant="plain">
              Last Updated by:{' '}
              {currentRowData && currentRowData.lastUpdateUser
                ? currentRowData.createUser
                : 'admin'}
            </Typography>
            <Typography sx={{
              marginLeft: '22px',
              letterSpacing: '0.15px',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '24px',

            }} variant="plain">
              Last Updated Time:{' '}
              {currentRowData && currentRowData.lastUpdateAt
                ? currentRowData.lastUpdateAt.toLocaleString()
                : '2021/08/01 00:00:00'}
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* save button */}
      <Box sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'absolute',
        bottom: '0px',
        right: '12px',
        height: '88px',
        alignItems: 'center',
        width: '100%',
        gap:'12px'
      }}>
        <Button
          onClick={handleDeleteCommand}
      
          variant="outlined"
        >
          Delete
        </Button>
        <Button
   
          variant="solid"
          onClick={handleSaveEditCommand}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
}
