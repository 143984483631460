//** Import React
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//** Import Redux
import { useSelector } from 'react-redux';
import store from '../store';
import Box from '@mui/joy/Box';

//** Import Collections
import { handleSetInitDone, handleSetSettings } from '../store/system';
import { UserService, OrgService, SystemService } from '../services';
import server from './serverConnect';
import { useTranslation } from 'react-i18next';

import AIService from '../services/AIService';
import ClipboardService from '../services/ClipboardService';
import { orgApi } from 'redux/OrgAPISlice';



export default function SystemInit({ children }) {
    // const navigate = useNavigate();
    const user = useSelector(state => state.user.userInfo);
    const storageToken = localStorage.getItem('token');
    const settings = useSelector(state => state.system.settings);
    const initDone = useSelector(state => state.system.initDone);

    const { t } = useTranslation();
    Boardx.Util.t = t;
    AIService.getInstance();
    ClipboardService.getInstance();

    const isLoginOrJoin = window.location.pathname.indexOf('/signin') > -1
        || window.location.pathname.indexOf('/join') > -1 || window.location.pathname.indexOf('/reset-password') > -1;

    if (!storageToken && !isRestrictedPath()) {
        redirectToSignIn();
    }

    function isRestrictedPath() {
        const restrictedPaths = ['/signin', '/join', '/reset-password', '/invite', '/chatShare'];
        return restrictedPaths.some(path => window.location.pathname.indexOf(path) !== -1);
    }

    function redirectToSignIn(useCallback = true) {
        if (useCallback) {
            const callbackParam = window.location.pathname.slice(1);
            const signInUrl = '/signin?callback=' + callbackParam;
            location.href = signInUrl;
        } else {
            location.href = '/signin';
        }
      
    }

    //load Settings from backend
    useEffect(() => {
        async function initializeUserProfile() {
            let url = '';
            await SystemService.getInstance().loadSettings();
            if ( initDone) return;
            store.dispatch(handleSetInitDone(true));
            if (storageToken && !store.getState().user.userId) {

                signinUser();

                async function signinUser() {
                    try {
                        let user = await server.login({ resume: storageToken });
              
                        if (user && user.id) {

                            store.dispatch(handleSetInitDone(true));
                            let user = await server.call('getUserInfo');
                
                            const userId = user._id;
                            const token = storageToken;
                            await server.call('saveLoginToken', userId, token);
                            UserService.getInstance().saveStore(user);
                        }
                    } catch (err) {
                        console.log('err---------', err)
                     
                    }

                }


            }
        }
        initializeUserProfile();

    }, []);


    // useEffect(() => {
    //     console.log('#user', user)
    //     if (!user.userId) return;

    //     // (async () => {
    //     //     const { data: orgList } = await store.dispatch(orgApi.endpoints.getOrgList.initiate({ userId: user.userId }));
    //     //     let orgId = localStorage.getItem('orgId');
    //     //     if (!orgId) orgId = orgList[0]?.orgId;
    //     //     if (orgList && orgList.length > 0 && user.userId && orgId) {
    //     //         OrgService.getInstance().loadOrganization(orgId);
    //     //     }
    //     // })();

    // }, [user]);

    return ( (initDone || user.userId) && <Box>{children}</Box>);
}
