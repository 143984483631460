//** Import react
import React, { useRef, useState, useEffect } from 'react';

import { styled } from '@mui/material/styles';

//** Import i18n
import { useTranslation } from 'react-i18next';

//** Import Redux kit
import { RootState } from '../../store';
import { handleInitRoom } from '../../store/room';
import { useSelector, useDispatch } from 'react-redux';

//**Import Mui
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, IconButton, Sheet } from '@mui/joy';
import { Menu, MenuItem } from '@mui/material'

//**Import Services
import { UserService } from '../../services';
import ModeToggle from './ModeToggle';

import UserMenu from '../../components/user/UserMenu';
import OrganizationInviteMembersModal from '../../components/org/OrganizationInviteMembersModal';
import AutoSearchBoard from '../../components/search/AutoSearchBoard';

import TeamManagement from './TeamManagement';

const drawerWidth = 240;
const winHeight = window.innerHeight;



function DesktopHeader({ isOrgAdmin, logout }) {

  const currentApp = useSelector((state: RootState) => state.system.currentApp);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100vw',
      maxHeight: '50px',
      justifyContent: 'space-between'
    }}>
      {currentApp!=='console' ? <TeamManagement />:<Box></Box>}



      <Box style={{ display: 'flex', alignItems: 'center' }}>
        {currentApp=='board' && <AutoSearchBoard />}
        {isOrgAdmin && <OrganizationInviteMembersModal />}
        <ModeToggle />
        <UserMenu logout={logout} />
      </Box>
    </Box>
  );
}

function MobileUIHeader({ logout, isOrgAdmin }) {
  const currentApp = useSelector((state: RootState) => state.system.currentApp);
  //dom & control
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
        mt: '10px'
      }}
    >
      {currentApp=='board' && <IconButton
        aria-label="open drawer"
        sx={{ color: '#828282' }}
        onClick={() => (window as any).setMobileOpen(true)}
        size="lg"
      >
        <MenuIcon style={{ strokeWidth:"var(--joy-lineHeight-sm)" }}/>
      </IconButton>}
      {/* { <AutoSearchBoard />} */}
      <TeamManagement />
      <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
      <ModeToggle />
        <UserMenu logout={logout} />
        {/* <IconButton
          aria-label="show 17 new notifications"

          id="moreMenuRecentPageHeader"
          onClick={() => setOpenMenu(true)}
          size="lg"
        >
          {isOrgAdmin && <MoreVertOutlinedIcon
            style={{ color: openMenu ? '#F21d6B' : 'rgba(0,0,0,0.54)' }}
          />}
        </IconButton> */}
        {/* {isOrgAdmin && <Menu
          anchorEl={document.getElementById('moreMenuRecentPageHeader')}
          onClose={() => {
            setOpenMenu(false);
          }}
          open={openMenu}
        >
          <MenuItem>
            <OrganizationInviteMembersModal />
          </MenuItem>
        </Menu>} */}
      </Box>
    </Box>
  )
}

export default function MainHeader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  //org
  const isOrgAdmin =
    useSelector((state: RootState) => state.org.orgInfo.role) !== 'member'
      ? true
      : false;
  //mobile
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const logout = () => {
    UserService.getInstance().logout();
    // navigate('/signin');
    window.location.href = '/signin';
  };


  return (

    <Box
      sx={{
        display: 'flex',
        flexGrow: 0,
        flexShrink:0,
        flexDirection: 'row',
        alignItems: 'center',
        zIndex: 1002,
        paddingTop: 0,
        height: '60px',
        width: '100%',
        boxShadow: 'var(--joy-shadow-sm)',
        mt:isMobile?'5px':'0px',


      }}


    >
      <Sheet
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          height: '100%',
          opacity:0.8
        }}
      >
        {smallScreen && <MobileUIHeader isOrgAdmin={isOrgAdmin} logout={logout}></MobileUIHeader>}
        {!smallScreen && <DesktopHeader isOrgAdmin={isOrgAdmin} logout={logout} />}

      </Sheet>
    </Box>

  );
}

