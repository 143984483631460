//** Import react
import React, { useEffect, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';

//** Import Material UI
import Box from '@mui/joy/Box';

// import AIAgentChatContent from './AIAgentChatContent';
import ChatDialogContent from 'components/boardChatAI/ChatAI/ChatDialogContent';
import SendMsgForm from 'components/boardChatAI/ChatAI/SendMsgForm';
function AIAgentChat(props) {
  return (
    <Box sx={{
  
      maxHeight: 'calc(100Vh  - 60px)',
      display: 'flex',
      flexDirection: 'column',
    }}>
     
        <ChatDialogContent dialogFullScreen={true}
          chatType={'agent'} />
 
      <Box sx={{
        flex: '0 1 auto'
      }}>
        <SendMsgForm dialogFullScreen={true} chatType={'agent'} />

      </Box>


    </Box>
  );
}

export default AIAgentChat;
