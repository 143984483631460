// ** React Imports
import React, { useEffect, useRef, useState } from 'react';
import RecordRTC from 'recordrtc';

//** Import i18n
import { useTranslation } from 'react-i18next';

// ** Import Redux
import { useDispatch, useSelector } from 'react-redux';
import store from '../../../store';
import {
  handleSetIsReading,
  handleSetShowAiChatLoading,
  handleSetShowRecordButton,
  handleSetShowRecordingImg
} from '../../../store/board';

import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import { styled } from '@mui/material/styles';
//** Import MUI Icons
import LoadingIcon from '../../../mui/icons/LoadingIcon';
// ** Import Services
import {
  AIService,
  BoardService
} from '../../../services';

import { useMediaQuery } from '@mui/material';
// ** Icons Imports


import AIModelSelectComponent from '../AIModelSelectComponent';

import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { Textarea } from '@mui/joy';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { IconCamera, IconMicrophone, IconPhotoPlus, IconPlayerStop } from '@tabler/icons-react';

import AudioVisualizer from './AudioVisualizer';

const Form = styled('form')(({ theme }) => ({
  padding: theme.spacing(1, 1, 1)
}));



// ** Styled Components
const ChatFormWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  // boxShadow: theme.shadows[1],
  justifyContent: 'space-between',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper
}));

/***
 * @description: Send the message to the AI assistant
 * 
 */
const SendMsgForm = props => {
  const { dialogFullScreen, chatType } = props;
  const isReading = useSelector((state) => state.board.isReading);
  const userInfo = useSelector((state) => state.user.userInfo);
  const currentChatSession = useSelector(
    state => state.AIAssist.currentChatSession
  );
  const showAiChatLoading = useSelector((state) => state.board.showAiChatLoading);
  const [currentImage, setCurrentImage] = useState(null);

  const { t } = useTranslation();
  const [stream, setStream] = useState(null);
  const dispatch = useDispatch();

  // ** State
  const [msg, setMsg] = useState('');
  const textAreaRef = useRef(null);
  const showRecordButton = useSelector(
    (state) => state.board.showRecordButton
  );
  const showRecordingImg = useSelector(
    (state) => state.board.showRecordingImg
  );

  const aiChatMessages = useSelector((state) => state.AIAssist.aiChatMessages);
  const aiChatRows = useSelector((state) => state.AIAssist.aiChatRows);
  const messages = [...aiChatMessages];
  // voice
  const [recording, setRecording] = useState(false);
  const [audioRecorder, setAudioRecorder] = useState(null);
  const [recordAudioFile, setRecordAudioFile] = useState(null);
  const [accessRecord, setAccessRecord] = useState(false);
  const [isComposing, setIsComposing] = useState(false);
  const [voiceTime, setVoiceTime] = useState(0);
  const [isTouchDev, setIsTouchDev] = useState(false);
  const [isShowCancelTheAvaRequestBtn, setIsShowCancelTheAvaRequestBtn] = useState(false);
  // const [abortController, setAbortController] = useState(null);
  let abortController = null;
  const isMobile = useMediaQuery('(max-width:600px)');


  useEffect(() => {
    return () => {
      abortController?.abort();
    };
  }, []);

  const handleCompositionStart = () => {
    setIsComposing(true);
  };

  const handleCompositionEnd = () => {
    setIsComposing(false);
  };

  /*
    const getAvaGpt4LastMessageTime = async () => {
      const newAiChatMessages = aiChatRows.filter(item => item.type === 'AI');
  
      if (newAiChatMessages.length > 0) {
        const lastMessage = newAiChatMessages[newAiChatMessages.length - (LIMIT-1)];
        const createdAt = lastMessage.createdAt;
        const timestamp = Date.parse(createdAt);
        return timestamp;
      } else {
        const currentTime = new Date().getTime();
        return currentTime;
      }
    };
  */



  const handleSendMsg = async (e) => {
    e.preventDefault();

    if (msg?.trim().length == 0) return;

    const userMessage = msg.trim();
    let contentOnTheBoard = await getContentOnTheBoard();

    dispatch(handleSetIsReading(true));
    dispatch(handleSetShowAiChatLoading(true));
    setIsShowCancelTheAvaRequestBtn(true);
    setCurrentImage(null);
    setMsg('');
    abortController = null;
    abortController = new AbortController();

    if (store.getState().user.userInfo.status !== 'pro') {
      const hasQuota = AIService.getInstance().checkUsageQuota();

      if (hasQuota) {
        await AIService.getInstance().handleRequestAIChat(userMessage, currentImage, contentOnTheBoard, chatType);
      } else {
        dispatch(handleSetShowAiChatLoading(false));
        dispatch(handleSetIsReading(false));
        Boardx.Util.Msg.warning(t('chatAi.freeTierLimit'));
      }
      return;
    }

    await AIService.getInstance().handleRequestAIChat(userMessage, currentImage, contentOnTheBoard, chatType);
  };

  /***
   * @description: Get the content on the board
   */
  const getContentOnTheBoard = async () => {
    if (!location.pathname.includes('/board/')) return "";

    if (currentChatSession.isIncludeBoardContent) {
      const currentViewportTextArr =
        await BoardService.getInstance().getCurrentOptionTextArr();

      if (currentViewportTextArr.length === 0) return "";

      let textArray = [
        `${t('chatAi.contentOnTheBoardTitle')}`,
        ...currentViewportTextArr
      ];

      return textArray.join('\n').trim();
    }

    return "";
  };



  function CurrentUploadedImage({ currentImage, setCurrentImage }) {


    const handleDeleteImage = () => {
      setCurrentImage(null);

    };

    return (
      currentImage && (
        <Box
          sx={{
            width: '100px',
            height: '100px',
            position: 'relative',
            backgroundImage: `url(${currentImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <Button
            variant="solid"
            size="small"
            sx={{
              position: 'absolute',
              top: '0px',
              right: '0px',

            }}
            onClick={handleDeleteImage}
          >
            <CloseOutlinedIcon fontSize='small' />
          </Button>
        </Box>
      )
    );
  }

  const handleShowRecordBtn = () => {
    let showBtn = store.getState().board.showRecordButton;
    if (!accessRecord) {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then(stream => {
          // 用户已授权录音
          setAccessRecord(true);
        })
        .catch(error => {
          // 用户未授权录音
          setAccessRecord(false);
        });
    } else {
      if (showBtn) {
        store.dispatch(handleSetShowRecordButton(false));
        handleStopRecord();

      } else {
        store.dispatch(handleSetShowRecordButton(true));
        handleStartRecord();
      }
    }
  };


  // const startShowWave = (stream) => {
  //   return;
  //   let cn;
  //   console.log('startShowWave');
  //   let c;
  //   cn = document.getElementById("audioCanvas");
  //   c = cn.getContext("2d");

  //   let audioContext = new (window.AudioContext || window.webkitAudioContext)();
  //   let analyser = audioContext.createAnalyser();

  //   let source = audioContext.createMediaStreamSource(stream);
  //   source.connect(analyser);
  //   analyser.fftSize = 128;

  //   let bufferLength = analyser.frequencyBinCount;
  //   let dataArray = new Uint8Array(bufferLength);
  //   function draw() {

  //     requestAnimationFrame(draw);

  //     analyser.getByteFrequencyData(dataArray);

  //     c.fillStyle = "rgba(0, 0, 0, 0.05)";
  //     c.fillRect(0, 0, cn.width, cn.height);

  //     let centerX = cn.width / 2;
  //     let centerY = cn.height / 2;
  //     let threshold = 50; // Minimum value to draw

  //     for (let i = 0; i < bufferLength; i++) {
  //       if (dataArray[i] < threshold) continue; // Skip low values

  //       let adjustedValue = Math.max(0, dataArray[i] - threshold);
  //       let radius = (adjustedValue / 2) + (cn.height / 200); // Reduced sensitivity
  //       let opacity = adjustedValue / 205; // Adjusted for threshold and max opacity

  //       let offset = i * 1;

  //       c.beginPath();
  //       c.arc(centerX + offset - bufferLength / 2, centerY, radius, 0, 2 * Math.PI, false);
  //       c.fillStyle = `rgba(255, 255, 255, ${opacity})`;
  //       c.fill();

  //       // Optional: Add a stroke for additional artistic effect
  //       c.lineWidth = 1;
  //       c.strokeStyle = `rgba(255, 255, 255, ${opacity / 2})`;
  //       c.stroke();
  //     }
  //   }

  //   draw();


  // }
  /***
   * @description: Start recording
   */
  const handleStartRecord = async () => {



    const startRecordTime = new Date().getTime();
    setVoiceTime(startRecordTime);
    //show gif
    store.dispatch(handleSetShowRecordingImg(true));


    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false
    });

    setStream(stream);

    // 使用RecordRTC创建录音机
    const recorder = new RecordRTC(stream, {
      type: 'audio',
      mimeType: 'audio/mp3',
      recorderType: RecordRTC.StereoAudioRecorder,
      numberOfAudioChannels: 1,
      desiredSampRate: 16000,
      disableLogs: true,
      timeSlice: 5000,
      bitsPerSecond: 16
    });
    setAudioRecorder(recorder);
    // startShowWave(stream);

    // 开始录音
    recorder.startRecording();
    setRecording(true);
  };



  /***
   * @description: Stop recording
   */
  const handleStopRecord = () => {

    store.dispatch(handleSetShowRecordingImg(false));


    const endRecordTime = new Date().getTime();

    if (endRecordTime - voiceTime > 1500) {
      if (audioRecorder) {
        audioRecorder.stopRecording(() => {
          const audioBlob = audioRecorder.getBlob();
          // 下载录音文件
          setRecordAudioFile(audioBlob);
          setAudioRecorder(null);
          setStream(null);
        });
      }
      setRecording(false);
      return;
    }

    dispatch(handleSetShowAiChatLoading(false));
    setIsShowCancelTheAvaRequestBtn(false);
    setRecording(false);
    Boardx.Util.Msg.info(t('chatAi.recordingVoiceTips'));
  };




  /***
   * @description: Handle the recording file
   */
  useEffect(() => {
    if (recordAudioFile && recording === false) {
      if (!recordAudioFile.size || recordAudioFile.size === 0) {
        //console.log('recordAudiofilesize---', recordAudioFile.size);
        return;
      }

      (async () => {
        let contentOnTheBoard = await getContentOnTheBoard();
        const text = await AIService.getInstance().handleVoiceChat(recordAudioFile, currentChatSession, contentOnTheBoard, abortController);
        setMsg(msg + ' ' + text);
        setRecordAudioFile(null);
        // setIsShowCancelTheAvaRequestBtn(true);
      })();

    }

  }, [recordAudioFile]);


  useEffect(() => {
    (async () => {
      const permission = await navigator.permissions.query({
        name: 'microphone'
      });
      if (permission.state === 'granted') {
        console.log('Microphone access granted');
        setAccessRecord(true);
      } else {
        console.log('Microphone permission is still pending');
      }
    })();

    if (
      store.getState().system.currentUIType === 'mobile' ||
      window.navigator.userAgent.indexOf('iPad') > 0
    ) {
      setIsTouchDev(true);
    }

    return () => {
      handleStopRecord();
    };
  }, []);


  const handleKeyDownSend = event => {
    if (isComposing) {
      return;
    }

    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMsg(event);
    }
  };

  const handleClickcancelTheAvaRequest = () => {

    // abortController?.abort();
    AIService.getInstance().cancelRequestAIChat();
    dispatch(handleSetShowAiChatLoading(false));
    setIsShowCancelTheAvaRequestBtn(false);
    dispatch(handleSetIsReading(false));

  }

  const getWidth = () => {
    const isMobile = Boardx.Util.isMobile();
    if (isMobile) {
      return 'calc(100vw - 10px)';
    }

    // if (chatType === 'agent') {
    //   return '100%';
    // }


    // if (chatType === 'recentChat') {
    //   if (dialogFullScreen) {
    //     return '95%';
    //   } else {
    //     return '450px'
    //   }
    // }

    return '99%';
    // if (dialogFullScreen) {
    //   if (window.innerWidth > 680)
    //     return 'calc(70vw - 240px)';
    //   else {
    //     return '480px';
    //   }
    // }
  }

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const addPhoto = (isCamera) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.multiple = false;
    if (isCamera) {
      input.capture = 'environment';
    }
    input.onchange = async (e) => {
      const file = e.target.files[0];
      // Define compression options for the image.
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 960,
        useWebWorker: true
      };

      // Compress the image.
      const file2 = await Boardx.Util.compressImage(file, options);

      const base64 = await fileToBase64(file2);
      setCurrentImage(base64);
      // Additional code...
    };
    document.body.appendChild(input); // Append the input to the body
    input.style.display = 'none'; // Hide the input element

    setTimeout(() => { input.click(); }, 0); // Use a timeout to trigger the click event
  };


  const getHeight = () => {
    const isMobile = Boardx.Util.isMobile();
    if (isMobile) {
      return 'calc(100vh - 100px)';
    }
    if (dialogFullScreen) {
      return 'calc(100vh - 100px)';
    }
  }
  const getMinWidth = () => {
    const isMobile = Boardx.Util.isMobile();
    if (chatType == 'agent') {
      return '635px';
    }
    if (isMobile) {
      return '360px';
    }
    if (dialogFullScreen) {

      return '635px';

    }

  }
  return (
    <Box id="abc"
      onDragEnter={(e) => {
        e.preventDefault();
        e.stopPropagation();
        e.currentTarget.classList.add('drag-over');
      }}
      onDragOver={(e) => {
        e.preventDefault();
        e.stopPropagation();
        e.currentTarget.classList.add('drag-over');
      }}
      onDragLeave={(e) => {
        e.preventDefault();
        e.stopPropagation();
        e.currentTarget.classList.remove('drag-over');
      }}
      onDrop={async (e) => {
        e.preventDefault();
        e.currentTarget.classList.remove('drag-over');
        const file = e.dataTransfer.files[0];
        if (file && file.type.startsWith('image/')) {
          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 960,
            useWebWorker: true
          };
          const compressedFile = await Boardx.Util.compressImage(file, options);
          const base64 = await fileToBase64(compressedFile);
          setCurrentImage(base64);
        }
      }}
      sx={{
        display: 'flex', flex: '0 1 auto',
        flexDirection: 'column', alignItems: 'center',
        width: getWidth(), margin: '6px', maxWidth: '1024px', marginBottom: isMobile ? '20px' : '5px',
        '&.drag-over': {
          opacity: 0.5,
        },
      }}>
      <Box sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        padding: '0px',
        maxWidth: '1024px',
        flexDirection: 'row',
        alignItems: 'center',
        height: '30px',
        width: getWidth()

      }}>
        <Box sx={{ width: '200px', height: '30px' }}>
          <AIModelSelectComponent save={true} changeModel={() => { }} />

        </Box>
        <Box sx={{ width: '180px', display: 'flex', justifyContent: 'center' }}>

          <Button
            variant="outlined"
            onClick={handleClickcancelTheAvaRequest}
            startDecorator={<IconPlayerStop />}
            sx={{
              display: isReading & !showAiChatLoading ? 'flex' : 'none',
              border: '0px solid #a3a3a3',

              fontSize: '12px',
              minHeight: 'unset',
              alignSelf: 'center',
              height: 'unset',
              '&:hover': {
                textDecoration: 'none',

              },
              '& .MuiSvgIcon-root': {
                width: '16px',
                height: '16px'
              }
            }}
          >
            {/* {t('chatAi.stopGenerating')} */}
          </Button>
          {showAiChatLoading ? (
            <Box sx={{

              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'center',

            }}>
              <LoadingIcon className="chatAi-loading-icon" />
              {/* <Typography sx={{
                fontWeight: 400,
                fontSize: '12px',
                lineHeight: '15px',

                marginLeft: '12px'
              }}>
                {t('chatAi.generatingTheResult')}
              </Typography> */}
            </Box>
          ) : null}
        </Box>
        <Box sx={{ width: '100px', height: '20px' }}>

        </Box>

      </Box>

      <Box sx={{ width: '100%' }}>
        <Form
          // sx={{
          //   p: 0,
          //   pointerEvents: 'all',
          //   ml: dialogFullScreen ? chatType === 'recentChat' ? 'none' : '300px' : 'none'
          // }}
          sx={{


            // minWidth: getMinWidth(),
            // padding: '10px',

            // maxWidth: '1024px',


          }}
        >
          <Box sx={{}}>



            <ChatFormWrapper

              style={{
                display: 'flex',
                flexDirection: 'column',
                boxSizing: 'border-box',

                height: 'auto',
                padding: '2px',
                position: 'relative'
                // padding: dialogFullScreen
                //   ? '12px calc((100vw - 300px) / 10) 30px'
                //   : '12px'
              }}
            >

              {/* <Popper
                open={showRecordingImg}

                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                sx={{
                  zIndex: 9999,

                  height: '500px',
                  width: '500px',
                }}
              >
                {showRecordingImg && <canvas id='audioCanvas' style={{ width: '100%', height: '100%' }}></canvas>}
              </Popper> */}
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  position: 'relative'
                }}
              >
                {/* {showRecordButton && (

                  <Button
                    id='aiAudioRecordBtn'
                    sx={{
                      width: '100%',
                      height: '32px',
                      marginLeft: '4px',
                      borderRadius: '3px',
                      padding: 0,
                      fontSize: '14px',
                      fontWeight: 400
                    }}
                    onMouseDown={handleStartRecord}
                    onMouseUp={handleStopRecord}
                    onMouseLeave={handleStopRecord}
                    onTouchStart={handleStartRecord}
                    onTouchEnd={handleStopRecord}
                  >
                    {!showRecordingImg
                      ? t('chatAi.mouseClickAndHoldToRecord')
                      : t('chatAi.releaseSendVoice')}
                  </Button>
                )} */}

                {(
                  <Textarea
                    placeholder={t('chatAi.typeYourPromptHere')}
                    onPaste={async (e) => {
                      const file = e.clipboardData.files[0];
                      if (file && file.type.startsWith('image/')) {
                        const options = {
                          maxSizeMB: 1,
                          maxWidthOrHeight: 960,
                          useWebWorker: true
                        };
                        const compressedFile = await Boardx.Util.compressImage(file, options);
                        const base64 = await fileToBase64(compressedFile);
                        setCurrentImage(base64);
                      }
                    }}

                    slotProps={{
                      input: {
                        ref: textAreaRef,
                      },
                    }}
                    value={msg}
                    maxRows={10}
                    minRows={1}
                    onChange={(e) => setMsg(e.target.value)}
                    onKeyDown={handleKeyDownSend}
                    onCompositionStart={handleCompositionStart}
                    onCompositionEnd={handleCompositionEnd}
                    autoFocus
                    size="medium"
                    color="neutral"
                    variant="soft"
                    startDecorator={
                      <Box
                        id="audioRecord"
                        sx={{
                          display: 'flex',
                          width: '100%',
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <IconButton id="aiAudioRecordBtn" variant="plain" onClick={handleShowRecordBtn}>
                          {!showRecordButton ? (
                            <IconMicrophone style={{ width: '20px', height: '20px', opacity: 0.8, strokeWidth: "var(--joy-lineHeight-sm)" }} />
                          ) : (
                            <IconPlayerStop fontSize="small" style={{ strokeWidth: "var(--joy-lineHeight-sm)" }} />
                          )}
                        </IconButton>
                        {isMobile && (
                          <IconButton onClick={() => addPhoto(true)}>
                            <IconCamera style={{ width: '20px', height: '20px', opacity: 0.8, strokeWidth: "var(--joy-lineHeight-sm)" }} />
                          </IconButton>
                        )}
                        <IconButton onClick={() => addPhoto(false)}>
                          <IconPhotoPlus style={{ width: '20px', height: '20px', opacity: 0.8, strokeWidth: "var(--joy-lineHeight-sm)" }} />
                        </IconButton>
                        {showRecordButton && stream && <AudioVisualizer setStop={handleShowRecordBtn} stream={stream} />}
                      </Box>
                    }
                    endDecorator={<CurrentUploadedImage currentImage={currentImage} setCurrentImage={setCurrentImage} />}
                    sx={{
                      width: '100%',
                      paddingLeft: '5px',
                      justifyContent: 'flex-end',
                      borderRadius: '10px',
                      '&.MuiTextarea-root': { '--Textarea-focusedHighlight': 'transparent' },

                    }}
                  />
                )}


                {msg.length > 0 && (
                  <Box sx={{ display: 'flex', alignItems: 'center', }}>
                    <IconButton
                      sx={{
                        width: '32px',
                        height: '32px',
                        cursor: 'pointer',
                        padding: 0,
                        marginLeft: '4px'
                      }}
                      onClick={handleSendMsg}
                    >
                      <SendOutlinedIcon fontSize={'small'} sx={{ strokeWidth: "var(--joy-lineHeight-sm)", color: msg.length > 0 ? '#F21D6B' : 'var(--joy-palette-text-icon' }} />
                    </IconButton>
                  </Box>
                )}

                {/* <ChatUploadFile /> */}
              </Box>
            </ChatFormWrapper>
          </Box>
        </Form>
      </Box>
    </Box>

  );
};

export default SendMsgForm;
