import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ChatDialogContent from 'components/boardChatAI/ChatAI/ChatDialogContent';
import AIService from 'services/AIService';
import Box from '@mui/joy/Box';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';


export default function AIAssistantChatShare() {
  const { sessionid } = useParams();
  const currentChatSession = useSelector((state: RootState) => state.AIAssist.currentChatSession);


  useEffect(() => {
    // Load chat history using chatSessionId
    // Your code to load chat history goes here
    AIService.getInstance().loadChatHistoryBySessionId(
      sessionid
    );
  }, [sessionid]);

  return (<Box sx={{
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }}>
    <Typography variant="h6"
      sx={{
        textAlign: 'center',
        marginTop: '20px'
      }}>

      {
        currentChatSession?.name || 'BoardX Ava Chat Session Share'
      }
    </Typography>
    <ChatDialogContent dialogFullScreen={true} chatType={'chatShare'} />
  </Box>


  );
};
