//** Import react
import React, { useEffect, useState } from 'react';

// import rehypeMathjax from 'rehype-mathjax';
//** Import i18n
import { useTranslation } from 'react-i18next';
import { CodeBlock } from './CodeBlock';
//** Import Redux kit
import store, { RootState } from 'store';
import { useSelector, useDispatch } from 'react-redux';
import { useUpdateAIChatMsgInfoMutation } from 'redux/AiAssistApiSlice';
import { handleSetAiToolBar } from 'store/domArea';

import IconButton from '@mui/joy/IconButton';
import Box from '@mui/joy/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/joy/Typography';
import clsx from 'clsx';

//** Import MUI Icons
import ChatAIAgreeIcon from 'mui/icons/ChatAIAgreeIcon';
import ChatAIDisagreeIcon from 'mui/icons/ChatAIDisagreeIcon';
import ClipboardService from 'services/ClipboardService';
import UtilityService from 'services/UtilityService';

import { IconCopy } from '@tabler/icons-react';
import { IconChalkboard } from '@tabler/icons-react';
import { IconMail } from '@tabler/icons-react';
import { IconPinEnd } from '@tabler/icons-react';
import { IconThumbUp, IconThumbDown } from '@tabler/icons-react';
import { Tooltip } from '@mui/material';
import { IconReload } from '@tabler/icons-react';
import { IconEdit } from '@tabler/icons-react';
import { AIService } from 'services';

import { Button, DialogContent, Modal, ModalClose, ModalDialog, Input, Textarea } from '@mui/joy';

import DialogTitle from '@mui/joy/DialogTitle';

export default function ChatItemFooter({ item, includeFeedback, chatType }) {
    const [updateAIChatMsgInfo] = useUpdateAIChatMsgInfoMutation();
    const { t } = useTranslation();
    const userInfo = useSelector((state: RootState) => state.user.userInfo);
    const [isModalOpen, setIsModalOpen] = useState(false);
 

    const handleAddNoteToBoard = text => {

        const newText = text;
        canvas.createWidgetatCurrentLocationByType('WBText', {
            text: newText,
            useCenterOfScreen: true,
            backgroundColor: '#d3f4f4'
        });
        Boardx.Util.Msg.info(t('chatAi.textHasBeenAddedToTheBoard'));
        setTimeout(() => {
            canvas.getActiveObject().exitEditing();
        }, 100);
    };

    const handleAddStickyNoteToBoard = async text => {


        await canvas.AddMultipleStickyNoteToBoardByText(text);

        Boardx.Util.Msg.info(t('chatAi.textHasBeenAddedToTheBoard'));
    };

    const handelEditChatQuestion = item => {


        setIsModalOpen(true);
    }

    const handleClickCopyText = text => {

        ClipboardService.getInstance().clipboardCopy(text);
        Boardx.Util.Msg.info(t('chatAi.textHasBeenCopied'));
    };

    const handleClickSendEmail = async (text, e) => {

        const elementToSend=e.target.parentNode.parentNode.parentNode.parentNode.parentNode;


        await UtilityService.getInstance().sendEmail(userInfo.email, 'BoardX Chat AI', elementToSend.outerHTML)
        Boardx.Util.Msg.info(t('chatAi.emailHasBeenSent'));

    };

    const handleRegenerateChatAIMsg = async item => {
        AIService.getInstance().handleRequestEditChat(item);
    }



    function ModalEditChat({ item, isModalOpen, setIsModalOpen }) {
        const { t } = useTranslation();

        const [editedMessage, setEditedMessage] = useState(item.message);

        const handleOpenModal = () => {
            setIsModalOpen(true);
        };

        const handleCloseModal = () => {
            setIsModalOpen(false);
        };

        const handleEditAndRegenerateChatAIMsg = async (item) => {
            const currentChatList = store.getState().AIAssist.aiChatRows;
            setIsModalOpen(false);
            //find parentChatID is item._id from the currentChatList
    
            const childChat = currentChatList.find(chat => chat.parentChatId === item._id);
    
            if (childChat) {
                AIService.getInstance().handleRequestEditChat(childChat, editedMessage);
            }
        }


        return (isModalOpen && <Modal open={isModalOpen} onClose={handleCloseModal}>
            <ModalDialog>
                <DialogTitle id="modal-modal-title">Edit the question</DialogTitle>
                <DialogContent>
                    <ModalClose onClick={handleCloseModal} />
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <Textarea
                            id='editedMessage'
                            value={editedMessage}
                            onChange={(e) => setEditedMessage(e.target.value)}
                            maxRows={10}
                            minRows={5}
                            sx={{ mb: '12px', width: '500px', maxWidth: '90%' }}
                        />

                        <Button
                            variant="solid"
                            color='primary'
                            onClick={() => handleEditAndRegenerateChatAIMsg(item)}
                            sx={{ justifyContent: 'space-between' }}
                        > Submit</Button>
                    </Box>
                </DialogContent>
            </ModalDialog>
        </Modal>
        );
    }


    const handleIsAgreeChatAIMsg = async message => {
        let updateInfo = {
            agree: message && message.agree ? false : true,
            disagree: false,
            chatAIMessage: message
        };
        await updateAIChatMsgInfo({ ...updateInfo });
    };

    const handleIsDisagreeChatAIMsg = async message => {
        let updateInfo = {
            agree: false,
            disagree: message && message.disagree ? false : true,
            chatAIMessage: message
        };
        await updateAIChatMsgInfo({ ...updateInfo });
    };



    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        }}>
            <Typography
                level='title-sm'
                sx={{
                    marginLeft: '55px',
                    opacity: 0.54
                }}>
                {item.createdAt
                    ? new Date(item.createdAt).toLocaleString('en-US', {
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: '2-digit',
                        hour12: true
                    })
                    : null}
            </Typography>
            {  chatType !== 'chatShare' && 
                (
                    <Box className='chatItemFooterIcons' sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        alignItems: 'center'
                    }}>
                        {/* <IconButton
                            onClick={() => handleIsAgreeChatAIMsg(item)}
                        >
                                    <IconThumbUp style={{ opacity: 0.54, color:item && item.agree ? 'rgb(235, 0, 20)' : 'var(--joy-palette-text-icon)' }} />
                        
                        </IconButton> */}
                        {includeFeedback && <Tooltip title={"I don't like the result"} arrow>
                            <IconButton
                                onClick={() => handleIsDisagreeChatAIMsg(item)}
                                size='sm'
                                sx={{ fontSize: '6px', opacity: 0.34, color: item && item.disagree ? 'rgb(235, 0, 20)' : 'var(--joy-palette-text-icon)' }}
                            >
                                <IconThumbDown style={{ width: "16px", height: "16px",  strokeWidth:"var(--joy-lineHeight-sm)"  }} />

                            </IconButton>
                        </Tooltip>}

                        {item.type === 'user' && <Tooltip title={"Edit the question"} arrow>
                            <IconButton
                                onClick={() => handelEditChatQuestion(item)}
                                size='sm'
                                sx={{ fontSize: '6px', opacity: 0.34, color: item && item.disagree ? 'rgb(235, 0, 20)' : 'var(--joy-palette-text-icon)' }}
                            >
                                <IconEdit style={{ width: "16px", height: "16px" ,  strokeWidth:"var(--joy-lineHeight-sm)" }} />
                            </IconButton>
                        </Tooltip>}
                        {<ModalEditChat item={item} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />}

                        {item.type !== 'user' && <Tooltip title={"Regenerate the result"} arrow>
                            <IconButton
                                onClick={() => handleRegenerateChatAIMsg(item)}
                                size='sm'
                                sx={{ fontSize: '6px', opacity: 0.34, color: item && item.disagree ? 'rgb(235, 0, 20)' : 'var(--joy-palette-text-icon)' }}
                            >
                                <IconReload style={{ width: "16px", height: "16px",  strokeWidth:"var(--joy-lineHeight-sm)"  }} />
                            </IconButton>
                        </Tooltip>}


                        <Tooltip title={"Copy the text"} arrow>
                            <IconButton
                                size='sm'
                                sx={{ opacity: 0.34 }}
                                onClick={() => handleClickCopyText(item.message)}
                            >
                                <IconCopy style={{ width: "16px", height: "16px",  strokeWidth:"var(--joy-lineHeight-sm)"  }} />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={"Send to my mailbox"} arrow>
                            <IconButton
                                size='sm'
                                sx={{ opacity: 0.34 }}
                                onClick={(e) => handleClickSendEmail(item.message, e)}
                            >
                                <IconMail style={{ width: "16px", height: "16px",  strokeWidth:"var(--joy-lineHeight-sm)"  }} />
                            </IconButton>
                        </Tooltip>


                        {chatType === 'boardChat' && item.type !== 'user' &&
                            <Tooltip title={"Send to current board"} arrow>
                                <IconButton
                                    sx={{ opacity: 0.34 }}
                                    size='sm'
                                    onClick={() => handleAddNoteToBoard(item.message)}
                                >
                                    <IconPinEnd style={{ width: "16px", height: "16px",  strokeWidth:"var(--joy-lineHeight-sm)"  }} />
                                </IconButton>
                            </Tooltip>
                        }
                    </Box>
                )
            }
        </Box>
    );
}



