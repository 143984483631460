//** Import react
import React, { useRef, Component, useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';

//** Import Redux kit
import { useAdminAddAiModelMutation } from '../../../redux/AiAssistApiSlice';

import Button from '@mui/joy/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

//** Import i18n
import { useTranslation } from 'react-i18next';

//** Import services
import { UtilityService } from '../../../services';

import store from '../../../store';

export default function AIModelAddNewModel({
  openAddNewModelDialog,
  handleCloseAddNewModelDialog
}) {

  const { t } = useTranslation();
  const valueRefName: any = useRef(''); //creating a refernce for TextField Component

  const [adminAddAiModel, { isError, isSuccess, isLoading }] =
    useAdminAddAiModelMutation();

  const handleAddCommand = async () => {
    if (
      valueRefName.current.value === ''
    ) {
      Boardx.Util.Msg.info(t('adminPage.pleaseFillAllFields'));
      return;
    }

    const data = {
      _id: UtilityService.getInstance().generateWidgetID(),
      name: valueRefName.current.value,
      status: 'Draft',
      model: 'ada',
      n_epochs: 4,
      batch_size: null,
      learning_rate_multiplier: null,
      prompt_loss_weight: 0.01,
      compute_classification_metrics: false,
      classification_n_classes: null,
      classification_positive_class: null,
      classification_betas: null,
      suffix: null,
      createdAt: new Date(),
      createUser: store.getState().user.userInfo.userName,
      createUserId: store.getState().user.userInfo.userId,
      lastUpdateUser: store.getState().user.userInfo.userName,
      lastUpdateUserId: store.getState().user.userInfo.userId,
      lastUpdateAt: new Date()
    };

    await adminAddAiModel({ modelData: data });
    Boardx.Util.Msg.info(t('adminPage.addSuccessfully'));
    handleCloseAddNewModelDialog();
    // Boardx.Util.Msg.info(err.reason);
  };

  return (
    <Dialog open={openAddNewModelDialog} onClose={handleCloseAddNewModelDialog}>
      <DialogContent>
        {/* Name */}
        <Box sx={{
          marginTop: '12px',
        
        }}>
          <Typography sx={{
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '15px',
            marginBottom: '6px'
          }} variant="plain">
            NAME
          </Typography>
          <TextField
            autoFocus
            id="name"
            inputRef={valueRefName}
            placeholder="name"
            type="text"
            variant="outlined"
            classes={{ root: 'textFieldRoot' }}
          />
        </Box>
      </DialogContent>

      {/* save button */}
      <DialogActions

        sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '12px' }}
      >
        <Button
          sx={{
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '24px',
            letterSpacing: '0.4px',
            textTransform: 'uppercase',
            boxShadow: 'var(--joy-shadow-md)',
            borderRadius: '5px',
            padding: '7px 22px !important',
      
          }}
          variant="solid"
          onClick={handleAddCommand}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
