import React from 'react';
import { Dialog, DialogContent, DialogTitle, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import IconButton from '@mui/joy/IconButton';
import Button from '@mui/joy/Button';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/joy/Typography';
import { useTranslation } from 'react-i18next';

function UpgradePlanDialog({ open, onClose, userInfo }) {
  const { t } = useTranslation();
  const [userLicense, setUserLicense] = React.useState(userInfo.status == 'pro' ? 'pro' : 'free');

  const goPurchase = async () => {
    const paymentLink = await server.call('getPaymentLink');
    window.open(paymentLink + '?prefilled_email=' + userInfo.email + '&client_reference_id=' + userInfo.userId, '_self'); //real link
    // startListenCallBack();
    // setOpenMyPlan(true);
  };

  const manageMySubscription = () => {
    server.call('createStripePortalSession').then((result) => {
      window.open(result, '_self');
    });
  };

  let freeUser= userLicense == 'free';
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle > { freeUser ? t('components.billing.upgrade') : t('components.billing.manage')} </DialogTitle>
      <DialogContent>
        <Typography  level="h3" gutterBottom sx={{ml:'10px'}}>
          {userLicense}
        </Typography>
        {/* //if user is pro then say "Your current plan is Pro, please enjoy the following advanced features" else say "Your current plan is Free, please upgrade to access the following advanced features" */}
        <Typography level="body-sm" gutterBottom style={{ 'margin': '1em' }}>
          {userLicense == 'free' ? 'Your current plan is free, please upgrade to access the following advanced features' : 'Your current plan is pro, please enjoy the following advanced features'}
        </Typography>

        {/* //if userLicense is free, then add a button to upgrade to pro
        //else add a button to downgrade to free */}
        {userLicense == 'free' ? <Button variant="solid" color="primary" fullWidth onClick={goPurchase}  >
          Upgrade to Pro $20USD/Month
        </Button> : null}

        <List>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="Access to GPT-4, the most capable model" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="Browse, create, and use Prompts and Agents" />
          </ListItem>

        </List>
       {userLicense != 'free' && <Button variant="solid" color="primary" fullWidth onClick={manageMySubscription} >
          Manage my subscription
        </Button>} 
        {/* <Button color="primary" fullWidth onClick={onClose}>
          I need help with a billing issue
        </Button>
        <Typography level="body-sm" align="center" color="primary" gutterBottom>
          Need more capabilities? See ChatGPT Enterprise
        </Typography> */}
      </DialogContent>
      <IconButton aria-label="close" onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8,   }}>
        <CloseIcon style={{ strokeWidth:"var(--joy-lineHeight-sm)" }}/>
      </IconButton>

    </Dialog>
  );
}

export default UpgradePlanDialog;
