//** Import react
import React, { useState, useRef } from 'react';

import { styled } from '@mui/material/styles';

//** Import i18n
import { useTranslation } from 'react-i18next';

import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

const ChatItemAudioPlayer = props => {
  const { audioSrc, ChatAIVersions } = props;

  const audioRef: any = useRef(null);
  const { t } = useTranslation();
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [error, setError] = useState(false);

  const handleLoadedMetadata = () => {
    const { duration } = audioRef.current;
    setDuration(duration);
  };

  //   播放语音
  const handlePlay = () => {
    if (error) {
      Boardx.Util.Msg.warning(t('chatAi.voiceFileIsCorrupted'));
      return;
    }
    audioRef.current.play();
    setIsPlaying(true);
  };

  //   暂停语音
  const handlePause = () => {
    audioRef.current.pause();
    setIsPlaying(false);
  };

  //   继续播放语音
  const handleResume = () => {
    audioRef.current.play();
    setIsPlaying(true);
  };

  //   监测语音播放是否出错
  const handleAudioError = () => {
    setError(true);
  };

  //  监测语音播放是否结束
  const handleEnded = () => {
    setIsPlaying(false);
  };

  return (
    <Box
    sx={{'.audioBtn': {
      padding: '0 10px',
 
      boxShadow: 'var(--joy-shadow-md)',
      borderRadius: '0px 4px 4px 4px',
      minWidth: '130px',
      height: '38px',
      display: 'flex',
      justifyContent: 'space-between',
      boxSizing: 'border-box',
     
    },
    '.audioBtn2': {
      padding: '0 10px',
 
      borderRadius: '0px 8px 8px 8px',
      minWidth: '130px',
      height: '38px',
      display: 'flex',
      justifyContent: 'space-between',
      boxSizing: 'border-box',
    
    }
  }}
    >
      <audio
        ref={audioRef}
        src={audioSrc}
        onLoadedMetadata={handleLoadedMetadata}
        onError={handleAudioError}
        onEnded={handleEnded}
      />
      <Button
        startDecorator={!isPlaying ? <PlayArrowIcon /> : <PauseIcon />}
        className={ChatAIVersions === 'desktopChatAI' ? 'audioBtn' : 'audioBtn2'}
        variant="plain"
        onClick={!isPlaying ? handlePlay : handlePause}
      >
        {Math.round(duration)}“
      </Button>
    </Box>
  );
};

export default ChatItemAudioPlayer;
