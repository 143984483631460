import SvgIcon from '@mui/joy/SvgIcon';
import React from 'react';

export default function UserManagementAddTagsIcon(props) {
  return (
    <SvgIcon
      {...props}
      style={{ width: 20, height: 20, marginRight: '10px' }}
      viewBox="0 0 20 20"
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_10820_19557)">
          <path
            d="M10.1431 2.5L2.15641 10.4883C1.84058 10.8024 1.6665 11.2208 1.6665 11.6667C1.6665 12.1125 1.83979 12.5317 2.15479 12.8451L7.15641 17.8451C7.47141 18.1592 7.889 18.3333 8.33317 18.3333C8.779 18.3333 9.19822 18.1601 9.51156 17.8451L17.4998 9.85677V2.5H10.1431ZM10.8332 4.16667H15.8332V9.16667L8.33317 16.6667L3.33317 11.6667L10.8332 4.16667ZM13.3332 5.83333C13.1122 5.83333 12.9002 5.92113 12.7439 6.07741C12.5876 6.23369 12.4998 6.44565 12.4998 6.66667C12.4998 6.88768 12.5876 7.09964 12.7439 7.25592C12.9002 7.4122 13.1122 7.5 13.3332 7.5C13.5542 7.5 13.7661 7.4122 13.9224 7.25592C14.0787 7.09964 14.1665 6.88768 14.1665 6.66667C14.1665 6.44565 14.0787 6.23369 13.9224 6.07741C13.7661 5.92113 13.5542 5.83333 13.3332 5.83333ZM15.8332 13.3333V15.8333H13.3332V17.5H15.8332V20H17.4998V17.5H19.9998V15.8333H17.4998V13.3333H15.8332Z"
            fill="#544F5A"
          />
        </g>
        <defs>
          <clipPath id="clip0_10820_19557">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

