
//**  Import react */
import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
// import Demo from 'mui/elements/Demo';
import { lazy } from 'react';
//**  Import mui */
import Backdrop from '@mui/material/Backdrop';
import AIAgentManagement from 'pages/admin/AIAgent/AIAgentManagement';
import AIAssistantContentAdminPage from 'pages/admin/AIAssistantContentAdmin/AIAssistantContentAdminPage';
import AIAssistantImagesAdminPage from 'pages/admin/AICustomStyleCommand/AIAssistantImagesAdminPage';
import AIModelManagementPage from 'pages/admin/AIModel/AIModelManagementPage';
import UserManagement from 'pages/admin/user/UserManagement';
import {
  Navigate
} from 'react-router-dom';
import SystemInit from '../startup/systemInit';
import AiAssistantLayout from './AIAssistantLayout';
import AISettingLayout from './AISettingLayout';
import AppDesktop from './AppDesktop';
import BoardLayout from './BoardLayout';

import PermissionManagement from 'pages/admin/permission/PermissionManagement';
import AIAssistantChatShare from 'pages/aiAssistantPage/AIAssistantChatShare';
import AdminConsoleLayout from './AdminConsoleLayout';


const lazyWithRetry = (elementImport) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem(
        'page-has-been-force-refreshed'
      ) || 'false'
    );

    try {
      const element = await elementImport();

      window.localStorage.setItem(
        'page-has-been-force-refreshed',
        'false'
      );

      return element;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem(
          'page-has-been-force-refreshed',
          'true'
        );
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });
const AuthPageSignIn = lazyWithRetry(
  () => import('../pages/signin/AuthPageSignIn')
);
const EmailVerified = lazyWithRetry(() => import('../pages/signin/EmailVerified'));
const VerifyEmail = lazyWithRetry(() => import('../pages/signin/verifyEmail'));
const AuthPageSignInWithWechat = lazyWithRetry(
  () => import('../pages/signin/AuthPageSignInWithWechat')
);
const AuthPageSignInWithLinkedin = lazyWithRetry(
  () => import('../pages/signin/AuthPageSignInWithLinkedin')
);
const AuthPageJoin = lazyWithRetry(() => import('../pages/join/AuthPageJoin'));
const InviteJoinPage = lazyWithRetry(() => import('../pages/join/InviteJoin'));
const ResetPassword = lazyWithRetry(
  () => import('../pages/resetPassword/ResetPassword')
);
const RoomHome = lazyWithRetry(() => import('../pages/roomHome/RoomHome'));
const RecentPageContainer = lazyWithRetry(
  () => import('../pages/recentPage/RecentPageContainer')
);
const FavoritesBoardPage = lazyWithRetry(
  () => import('../pages/favoritesBoardPage/FavoritesBoardPage')
);
const DeleteBoardPage = lazyWithRetry(
  () => import('../pages/deleteBoardPage/DeleteBoardPage')
);
const AiAssistantPage = lazyWithRetry(
  () => import('../pages/aiAssistantPage/AiAssistantPage')
);
const AppsStorePage = lazyWithRetry(
  () => import('../pages/appsStore/AppsStorePage')
);
// import OrgSettingPageContainer from '../pages/orgSettingPage/OrgSettingPageContainer';
const OrgSettingPageContainer = lazyWithRetry(
  () => import('../pages/orgSettingPage/OrgSettingPageContainer')
);
const PromptPage = lazyWithRetry(() => import('../pages/promptPage/PromptPage'));
const UserAccount = lazyWithRetry(
  () => import('../pages/userAccount/UserAccount')
);
const UserProfileAndSettingsPage = lazyWithRetry(
  () =>
    import(
      'components/user/userProfileAndSettings/UserProfileAndSettingsPage'
    )
);
// import BoardPage from '../pages/board/BoardPage';
const BoardPage = lazyWithRetry(() => import('../pages/board/BoardPage'));
// const PricingPage = lazyWithRetry(() => import('../pages/.pricing/Pricing'));
// const PurchaseItemPage = lazyWithRetry(
//   () => import('../pages/.pricing/PurchaseItem')
// );
const AdminPage = lazyWithRetry(() => import('../pages/admin/AdminPage'));
const NotFound = lazyWithRetry(() => import('../pages/404/NotFoundPage'));
const LoadingPage = () => {
  return <Backdrop open={true} ></Backdrop>;
};


function RootPage() {
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath === '/') {
      navigate('/recent');
    }
  }, [navigate]);

  return <></>;
}


export default function Router() {
  const [isMobile, setIsMobile] = React.useState(false);

  const [currentApp, setCurrentApp] = React.useState('');


  return (

    <BrowserRouter>

      <Suspense fallback={<LoadingPage />}>
        <SystemInit>
          <Routes>

            <Route path='/' element={<RootPage />} />
            <Route path='/admin' element={<Navigate replace to='/admin/prompt' />} />

            {/*main pages*/}
            <Route element={<AppDesktop />} path="/" >

              {/***********Board layout***************/}
              <Route element={<BoardLayout />} path="/" >
                <Route
                  element={<RecentPageContainer />}
                  path="/recent"
                />
                <Route
                  element={<RoomHome />}
                  path="/room/:id" />
                <Route
                  element={<FavoritesBoardPage />}
                  path="/favorite"
                />
                <Route
                  element={<DeleteBoardPage />}
                  path="/delete"
                />
              </Route>

              {/***Ava layout***/}
              <Route element={<AiAssistantLayout />} path="/" >
                <Route element={<AiAssistantPage />} path="/aiassistant" />
              </Route>

              {/***AI Setting layout***/}
              <Route element={<AISettingLayout />} path="/" >

                <Route
                  element={<AppsStorePage />}
                  path="/appsStore"
                />

              </Route>

              {/***admin backend layout***/}
              <Route element={<AdminConsoleLayout />} path="/" >


                <Route
                  element={<AIAssistantContentAdminPage />}
                  path="/admin/prompt"
                />
                <Route
                  element={<AIAgentManagement />}
                  path="/admin/agent"
                />
                <Route
                  element={<AIAssistantImagesAdminPage />}
                  path="/admin/imageprompt"
                />
                <Route
                  element={<AIModelManagementPage />}
                  path="/admin/model"
                />
                <Route
                  element={<UserManagement />}
                  path="/admin/users"
                />
                <Route
                  element={<PermissionManagement />}
                  path="/admin/permission"
                />


              </Route>



              <Route element={<OrgSettingPageContainer />}
                path="/teamsetting"
              />



              {/* 
              <Route element={<UserAccount />} path="/account" /> */}
              <Route
                element={<UserProfileAndSettingsPage />}
                path="/profile"
              />
            </Route>


            <Route element={<BoardPage />} path="/board/:id" />
            {/* <Route  element={ <Demo />} path="/demo" /> */}
            <Route element={<PromptPage />} path="/prompt" />

            {/*sign in and sign up pages*/}
            <Route element={<AuthPageSignIn />} path="/signin" />
            <Route
              element={<AuthPageSignInWithWechat />}
              path="/wechat"
            />
            <Route
              element={<AuthPageSignInWithLinkedin />}
              path="/singinWithLinkedin"
            />
            <Route element={<AuthPageJoin />} path="/join" />
            <Route
              element={<ResetPassword />}
              path="/reset-password/:token"
            />
            {/*verify pages*/}
            <Route
              element={<EmailVerified />}
              path="/emailVerified"
            />
            <Route
              element={<VerifyEmail />}
              path="/verify-email/:id"
            />

            {/*invite pages*/}
            <Route
              element={<InviteJoinPage />}

              path="/invite/:hash"
            />

            {/*chat session share pages*/}
            <Route
              element={<AIAssistantChatShare />}

              path="/chatshare/:sessionid"
            />


            {/*purchase pages*/}
            {/* <Route element={<PricingPage />} path="/pricing" /> */}
            {/* <Route
              element={<PurchaseItemPage />}
              path="/purchaseItem"
            /> */}
            {/*404 page*/}
            <Route element={<NotFound />} path="*" />
          </Routes>
        </SystemInit>
      </Suspense>
    </BrowserRouter>

  );
}