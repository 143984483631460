//** Import react
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { useState } from 'react';

//** Import Redux kit
import { useGetUserTagsQuery } from '../../../redux/UserAPISlice';

//** Import Mui
import Typography from '@mui/joy/Typography';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Popper from '@mui/material/Popper';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/joy/Divider';
import Chip from '@mui/material/Chip';
import clsx from 'clsx';

function FilterUserAndUserTags(props) {

  const {
    openFilterUserPopper,
    setOpenFilterUserPopper,
    setUserList,
    setData
  } = props;
  const [currentSelectedTags, setCurrentSelectedTags] = useState([]);
  const { data: userTags = [] } = useGetUserTagsQuery(undefined);

  const handleSelectTag = tag => {
    if (currentSelectedTags.some(t => t._id === tag._id)) {
      setCurrentSelectedTags(
        currentSelectedTags.filter(item => item._id !== tag._id)
      );
    } else {
      setCurrentSelectedTags([...currentSelectedTags, tag]);
    }
  };

  const handleClickReset = () => {
    setCurrentSelectedTags([]);
    setOpenFilterUserPopper(false);
    setData({
      filter: null,
      start: 0,
      limit: 3000
    });
  };

  const handleClickSave = () => {
    let newUserList = [];
    currentSelectedTags.map(item => {
      newUserList.push(...item.users);
    });
    newUserList = newUserList.filter(
      (obj, index, self) =>
        index === self.findIndex(t => t.id === obj.id && t.name === obj.name)
    );
    setUserList(newUserList);
    setOpenFilterUserPopper(false);
  };

  return (
    <Popper
      open={openFilterUserPopper}
      anchorEl={document.getElementById('filterUserBtn')}
      placement="bottom-start"
      sx={{ marginTop: '10px !important' }}
    >
      <Box sx={{
        width: '340px',
        height: '634px',
        background: '#F4F5FA',
        border: '1px solid rgba(58, 53, 65, 0.23)',
        boxShadow: 'var(--joy-shadow-md)',
        borderRadius: '6px',
        padding: '16px 0px 4px',
        display: 'flex',
        flexDirection: 'column',
        '.boxStyle': {
          margin: '0px 16px 16px',
          paddingLeft: '8px'
        },

        '.titleText': {
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '24px',
          letterSpacing: '0.15px',

          marginBottom: '6px'
        },
        '.formGroupRoot': {
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingLeft: '2px'
        },

        '.formControllLabelRoot': {
          height: '28px',
          margin: 0,
          flex: '50%'
        },

        '.formControlLabelText': {

          fontSize: '14px',
          lineHeight: '20px',
          letterSpacing: '0.15px',

          whiteSpace: 'nowrap'
        },

        '.checkboxRoot': {
          padding: 0,
          width: '15px',
          height: '15px',
          marginRight: '10px'
        },
        '.checked': { color: 'focusVisible !important' }
      }}>
        <Box className={'boxStyle'}>
          <Typography className={'titleText'}>Basic User Info</Typography>
          <FormGroup classes={{ root: 'formGroupRoot' }}>
            <FormControlLabel
              classes={{
                root: 'formControlLabelRoot',
                label: 'formControlLabelText'
              }}
              control={
                <Checkbox
                  classes={{
                    root: 'checkboxRoot',
                    checked: 'checked'
                  }}
                  defaultChecked
                />
              }
              label="Region"
            />
            <FormControlLabel
              classes={{
                root: 'formControlLabelRoot',
                label: 'formControlLabelText'
              }}
              control={
                <Checkbox
                  classes={{
                    root: 'checkboxRoot',
                    checked: 'checked'
                  }}
                  defaultChecked
                />
              }
              label="Occupation"
            />
            <FormControlLabel
              classes={{
                root: 'formControlLabelRoot',
                label: 'formControlLabelText'
              }}
              control={
                <Checkbox
                  classes={{
                    root: 'checkboxRoot',
                    checked: 'checked'
                  }}
                  defaultChecked
                />
              }
              label="Country"
            />
            <FormControlLabel
              classes={{
                root: 'formControlLabelRoot',
                label: 'formControlLabelText'
              }}
              control={
                <Checkbox
                  classes={{
                    root: 'checkboxRoot',
                    checked: 'checked'
                  }}
                  defaultChecked
                />
              }
              label="Registered"
            />
          </FormGroup>
        </Box>
        <Box className={'boxStyle'}>
          <Typography className={'titleText'}>
            Board Active Time
          </Typography>
          <FormGroup classes={{ root: 'formGroupRoot' }}>
            <FormControlLabel
              classes={{
                root: 'formControlLabelRoot',
                label: 'formControlLabelText'
              }}
              control={
                <Checkbox
                  classes={{
                    root: 'checkboxRoot',
                    checked: 'checked'
                  }}
                  defaultChecked
                />
              }
              label="Daily active"
            />
            <FormControlLabel
              classes={{
                root: 'formControlLabelRoot',
                label: 'formControlLabelText'
              }}
              control={
                <Checkbox
                  classes={{
                    root: 'checkboxRoot',
                    checked: 'checked'
                  }}
                  defaultChecked
                />
              }
              label="Latest login"
            />
            <FormControlLabel
              classes={{
                root: 'formControlLabelRoot',
                label: 'formControlLabelText'
              }}
              control={
                <Checkbox
                  classes={{
                    root: 'checkboxRoot',
                    checked: 'checked'
                  }}
                />
              }
              label="Monthly active"
            />
          </FormGroup>
        </Box>
        <Box className={'boxStyle'}>
          <Typography className={'titleText'}>Board Usage</Typography>
          <FormGroup classes={{ root: 'formGroupRoot' }}>
            <FormControlLabel
              classes={{
                root: 'formControlLabelRoot',
                label: 'formControlLabelText'
              }}
              control={
                <Checkbox
                  classes={{
                    root: 'checkboxRoot',
                    checked: 'checked'
                  }}
                  defaultChecked
                />
              }
              label="AI assistant"
            />
            <FormControlLabel
              classes={{
                root: 'formControlLabelRoot',
                label: 'formControlLabelText'
              }}
              control={
                <Checkbox
                  classes={{
                    root: 'checkboxRoot',
                    checked: 'checked'
                  }}
                  defaultChecked
                />
              }
              label="Basic tool"
            />
            <FormControlLabel
              classes={{
                root: 'formControlLabelRoot',
                label: 'formControlLabelText'
              }}
              control={
                <Checkbox
                  classes={{
                    root: 'checkboxRoot',
                    checked: 'checked'
                  }}
                />
              }
              label="AI widget"
            />
            <FormControlLabel
              classes={{
                root: 'formControlLabelRoot',
                label: 'formControlLabelText'
              }}
              control={
                <Checkbox
                  classes={{
                    root: 'checkboxRoot',
                    checked: 'checked'
                  }}
                  defaultChecked
                />
              }
              label="Templates"
            />
            <FormControlLabel
              classes={{
                root: 'formControlLabelRoot',
                label: 'formControlLabelText'
              }}
              control={
                <Checkbox
                  classes={{
                    root: 'checkboxRoot',
                    checked: 'checked'
                  }}
                />
              }
              label="Resources"
            />
            <FormControlLabel
              classes={{
                root: 'formControlLabelRoot',
                label: 'formControlLabelText'
              }}
              control={
                <Checkbox
                  classes={{
                    root: 'checkboxRoot',
                    checked: 'checked'
                  }}
                />
              }
              label="Help center"
            />
            <FormControlLabel
              classes={{
                root: 'formControlLabelRoot',
                label: 'formControlLabelText'
              }}
              control={
                <Checkbox
                  classes={{
                    root: 'checkboxRoot',
                    checked: 'checked'
                  }}
                />
              }
              label="Feedback"
            />
            <FormControlLabel
              classes={{
                root: 'formControlLabelRoot',
                label: 'formControlLabelText'
              }}
              control={
                <Checkbox
                  classes={{
                    root: 'checkboxRoot',
                    checked: 'checked'
                  }}
                  defaultChecked
                />
              }
              label="Others"
            />
          </FormGroup>
        </Box>
        <Box className={'boxStyle'}>
          <Typography className={'titleText'}>Credit</Typography>
          <FormGroup classes={{ root: 'formGroupRoot' }}>
            <FormControlLabel
              classes={{
                root: 'formControlLabelRoot',
                label: 'formControlLabelText'
              }}
              control={
                <Checkbox
                  classes={{
                    root: 'checkboxRoot',
                    checked: 'checked'
                  }}
                  defaultChecked
                />
              }
              label="Credit balance"
            />
            <FormControlLabel
              classes={{
                root: 'formControlLabelRoot',
                label: 'formControlLabelText'
              }}
              control={
                <Checkbox
                  classes={{
                    root: 'checkboxRoot',
                    checked: 'checked'
                  }}
                  defaultChecked
                />
              }
              label="Credit consumption"
            />
          </FormGroup>
        </Box>
        <Box className={'boxStyle'}>
          <Typography className={'titleText'}>Tags</Typography>
          <Box sx={{
            maxHeight: '60px',
            overflowX: 'hidden',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            
            overflowY: 'scroll'
          }}>
            {userTags?.map((tag, index) => (
              <Chip
                sx={{
                  '.chipRoot': {
                    padding: '3px 10px',
                    background: 'rgba(58, 53, 65, 0.08)',
                    borderRadius: '16px',
                    height: '24px',
                    margin: '0px 10px 8px 0px'
                  },
                  '.chipRootSelected': { background: '#F21D6B' },

                  '.chipLabel': {
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '13px',
                    lineHeight: '18px',
                    letterSpacing: '0.16px',

                    padding: 0,
                    whiteSpace: 'nowrap'
                  },

                  '.chipLabelSelected': {

                  }
                }}
                classes={{
                  root: clsx(
                    'chipRoot',
                    currentSelectedTags.some(t => t._id === tag._id)
                      ? 'chipRootSelected'
                      : ''
                  ),
                  label: clsx(
                    'chipLabel',
                    currentSelectedTags.some(t => t._id === tag._id)
                      ? 'chipLabelSelected'
                      : ''
                  )
                }}
                label={tag.tagName}
                key={tag._id}
                onClick={() => handleSelectTag(tag)}
              />
            ))}
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            m: '4px 16px 0px',
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            '.buttonPublicStyle': {
              maxHeight: '60px',
              overflowX: 'hidden',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'flex-start',
              overflowY: 'scroll'
            }
          }}
        >
          <Button
            className={'buttonPublicStyle'}
            style={{ color: 'neutrual.500' }}
            variant="plain"
            onClick={handleClickReset}
          >
            Reset
          </Button>
          <Button
            onClick={handleClickSave}
            className={'buttonPublicStyle'}
            variant="plain"
          >
            Save
          </Button>
        </Box>
      </Box>
    </Popper>
  );
}

export default FilterUserAndUserTags;
