//** Import react
import React, { useEffect, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';

//** Import i18n
import { useTranslation } from 'react-i18next';

//** Import Redux kit
import store, { RootState } from '../../../store';
import { useSelector, useDispatch } from 'react-redux';

//** Import Material UI
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/joy/IconButton';
import Box from '@mui/joy/Box';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import AIAssistantImagesDetails from './AIAssistantImagesDetails';
import AIAssistantImagesPreview from './AIAssistantImagesPreview';

function AIImagePrompt(props) {
  const { openAiImagePromptDialog, setOpenAiImagePromptDialog } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClickReturn = () => {
    setOpenAiImagePromptDialog(false);
  };

  return (
    <Dialog
      id="aiAgentDialog"
      open={openAiImagePromptDialog}
      BackdropProps={{ invisible: true }}
      disableEnforceFocus={true}
      sx={{ '.dialogPaperFull': {
        overflow: 'hidden',
        transform: 'translate(0px, 0px) !important',
        borderRadius: '0px'}
      }}
      classes={{
        paper: 'dialogPaperFull'
      }}
      fullScreen={true}
    >
      <Box sx={{ boxShadow: 'var(--joy-shadow-md)' }}>
        <IconButton onClick={handleClickReturn}>
          <KeyboardArrowLeftIcon style={{ strokeWidth:"var(--joy-lineHeight-sm)" }}/>
        </IconButton>
      </Box>

      <Box sx={{ display: 'flex', height: 'calc(100%  - 45px)' }}>
        <Box
          sx={{
            width: '50%',
            height: '100%',
            borderRight: '1px solid #d5d5d6'
          }}
        >
          <AIAssistantImagesDetails
            setOpenAiImagePromptDialog={setOpenAiImagePromptDialog}
          />
        </Box>
        <Box
          sx={{
            width: '50%',
            height: '100%',
            borderLeft: '1px solid #d5d5d6'
          }}
        >
          <AIAssistantImagesPreview />
        </Box>
      </Box>
    </Dialog>
  );
}

export default AIImagePrompt;
