//**  Import react */
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import store, { RootState } from '../store';
import { StyledEngineProvider } from '@mui/joy/styles';
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import joyTheme from "../theme/index";
import {
  extendTheme as extendJoyTheme,
  CssVarsProvider as JoyCssVarsProvider,
  useColorScheme as useJoyColorScheme,
} from "@mui/joy/styles";
import Router from './Router';
import materialTheme from 'mui/theme/index';

import GlobalStyles from '@mui/system/GlobalStyles';





//set global scrollbar styles for all pages for scrollbar
const globalScrollbarStyles = {
  ':root': {
    '--scrollbar-track': 'transparent',
    '--scrollbar-thumb': '#c0c0c0',
  },
  'body[data-theme="dark"]': {
    '--scrollbar-track': '#111',
    '--scrollbar-thumb': '#555',
  },
  '::-webkit-scrollbar': {
    width: '6px',
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: 'var(--scrollbar-track)',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: 'var(--scrollbar-thumb)',
  },

};

function App() {
 

  return (
    <Provider store={store}>
      <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
        <StyledEngineProvider >
          <JoyCssVarsProvider theme={joyTheme}>
            <GlobalStyles styles={globalScrollbarStyles} />
            <CssBaseline enableColorScheme />
            <Router />
      
          </JoyCssVarsProvider>
        </StyledEngineProvider>
      </MaterialCssVarsProvider>
    </Provider>
  );
}


export default App;


