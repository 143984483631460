//** Import react
import React, { useEffect } from 'react';

//** Import Redux kit
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

//** Import i18n
import { useTranslation } from 'react-i18next';

import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import { useMediaQuery } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';

import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';


//**Import Services
import { BoardService } from '../../../services';

//**Import Components
import ChatItem from './ChatItem/ChatItem';

let interval = null;

function ChatDialogContent(props) {
  const { dialogFullScreen, chatType } = props;

  const { t } = useTranslation();
  const theme = useTheme();

  const messagesEndRef: any = React.useRef();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const aiChatRows = useSelector((state: RootState) => state.AIAssist.aiChatRows);
  const currentChatSession = useSelector((state: RootState) => state.AIAssist.currentChatSession);

  const isReading = useSelector((state: RootState) => state.board.isReading);

  const uiType = useSelector((state: RootState) => state.system.currentUIType);
  const showAiChatLoading = useSelector(
    (state: RootState) => state.board.showAiChatLoading
  );

  const currentChatAiPersonaData = useSelector(
    (state: RootState) => state.AIAssist.currentChatAiPersonaData
  );

  const avatarImage = currentChatAiPersonaData && currentChatAiPersonaData.icon ? currentChatAiPersonaData.icon : 'https://files.boardx.us/ChatAI.png';



  // Create a new Subject
  const subject = new Subject();

  subject.pipe(
    debounceTime(500)
  ).subscribe(() => {
    if (!messagesEndRef.current) return;
    const div = document.getElementById("chatAIUI");
    div.scrollTop = div.scrollHeight;
  });

  // Call next on the subject to trigger the function
  const scrollToBottom = () => {
    // subject.next(null);
    const div = document.getElementById("chatAIUI");
    div.scrollTop = div.scrollHeight;
  };

  // let myInterval = null;
  // useEffect(() => {
  //   myInterval = setInterval(() => {
  //     const div = document.getElementById("chatAIUI");
  //     const scrollHeight = div.scrollHeight - div.clientHeight - div.scrollTop;
  //     if (!messagesEndRef.current || scrollHeight > 100) return;
  //     scrollToBottom();
  //   }, 1000);

  //   setTimeout(() => {
  //     scrollToBottom();
  //   }, 1000);

  //   return () => clearInterval(myInterval);


  // }, []);

  useEffect(() => {
    const div = document.getElementById("chatAIUI");
    const scrollHeight = div.scrollHeight - div.clientHeight - div.scrollTop;
    console.log('@@@@', scrollHeight, div.scrollHeight, div.clientHeight, div.scrollTop)
    console.log('####', (!messagesEndRef.current || scrollHeight > 10));
    if (!messagesEndRef.current || scrollHeight > 170) return;
    scrollToBottom();
  }, [aiChatRows]);

  useEffect(() => {

    scrollToBottom();




  }, [currentChatSession]);


  useEffect(() => {
    document
      .getElementById('chatAIUI')
      .addEventListener('scroll', scrollChatAIUIHandler);
    return () => {
      document
        .getElementById('chatAIUI')
        ?.removeEventListener('scroll', scrollChatAIUIHandler);
    };
  }, []);

  function scrollChatAIUIHandler(e) {
    if (e.target.scrollTop == 0) {
      const currentValue = BoardService.getInstance().getChatLimt();
      BoardService.getInstance().setChatLimt(currentValue + 5);
    }
  }


  const EmptyChatComponent = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          alignItems: 'flex-start',
          mt: '12px',
          width: '100%'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            paddingLeft: '8px',
            width: '100%'
          }}
        >
          <Avatar
            style={{
              width: '24px',
              height: '24px',
              marginRight: '10px',

              backgroundColor: 'white',
              borderRadius: '20px'
            }}
            alt={'ChatAIAvatarIcon'}
            id="avatar-AI-img"
          >
            {/* <ChatAIIcon24x24v3 /> */}
            <img
              style={{ width: '24px', height: '24px', borderRadius: '50%' }}
              src={
                avatarImage ? avatarImage : 'https://files.boardx.us/ChatAI.png'
              }
            />
          </Avatar>

          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <Typography
              style={{
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '25px',

                padding: '15px',
                // maxWidth: '280px',
                wordBreak: 'normal',
                // whiteSpace: 'pre-wrap',
                height: 'auto',

                boxShadow: 'var(--joy-shadow-md)',
                borderRadius: '4px 0px 4px 4px',
                boxSizing: 'border-box',
                overflow: 'hidden'
              }}
            >
              {t('chatAi.chatAIPresentation')}
            </Typography>
          </Box>
        </Box>

        <Typography
          style={{
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '15px',

            marginTop: '8px',
            marginLeft: '53px',
            textAlign: 'left'
          }}
          level="title-md"
        >
          {new Date(currentChatSession.createdAt).toLocaleString('en-US', {
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: '2-digit',
            hour12: true
          })}
        </Typography>
      </Box>
    );
  }

  const AgentCompontent = () => {
    return (<Box sx={{ width: 'calc(100% - 12px)' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          alignItems: 'flex-start',
          mt: '12px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            paddingLeft: '8px',
            width: '100%',
          }}
        >
          <Avatar
            style={{
              width: '24px',
              height: '24px',
              marginRight: '10px',
              backgroundColor: 'white',
              boxShadow: 'var(--joy-shadow-md)',
              borderRadius: '20px'
            }}
            alt={'ChatAIAvatarIcon'}
            id="avatar-AI-img"
          >
            {/* <ChatAIIcon24x24v3 /> */}
            <img
              style={{ width: '24px', height: '24px', borderRadius: '50%' }}
              src={avatarImage ? avatarImage : "https://files.boardx.us/ChatAI.png"}
            />

          </Avatar>

          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <Typography style={{
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '25px',
              minWidth: '200px',
              padding: '10px',
              wordBreak: 'normal',
              height: 'auto',
              maxWidth: '95vw',
              boxShadow: 'var(--joy-shadow-md)',
              borderRadius: '0px 4px 4px 4px',
              boxSizing: 'border-box',
              overflow: 'hidden'

            }}>
              {currentChatAiPersonaData.description}
            </Typography>
          </Box>
        </Box>

        <Typography
          level='title-sm'
          sx={{
            marginLeft: '55px',
            opacity: 0.34
          }}>
          {new Date().toLocaleString('en-US', {
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: '2-digit',
            hour12: true
          })}
        </Typography>
      </Box>
    </Box>);
  };


  const getWidth = () => {
    const isMobile = Boardx.Util.isMobile();
    if (isMobile) {
      return '100vw';
    }
    return '100%'
    if (chatType === 'agent') {
      return '680px';
    }

    if (dialogFullScreen) {
      if (window.innerWidth > 680)
        return 'calc(100%-240px )';
      else {
        return '100%';
      }
    }
  }

  const getMaxWidth = () => {
    const isMobile = Boardx.Util.isMobile();
    if (isMobile) {
      return '95vw';
    }
    if (chatType == 'agent') {
      return '680px';
    }

    if (chatType == 'boardChat' && !dialogFullScreen) {
      return '500px';
    }



    return '1024px';

  }

  const getMinWidth = () => {
    const isMobile = Boardx.Util.isMobile();
    if (isMobile) {
      return '360px';
    }
    if (chatType == 'agent') {
      return '480px';
    }

    if (dialogFullScreen && chatType == 'dashboard') {

      return 'calc(100vw-240px )';
    }


    if (dialogFullScreen) {

      return '680px';

    }
  }


  const getHeight = () => {
    const isMobile = Boardx.Util.isMobile();
    if (isMobile) {
      return 'calc(100vh - 160px)';
    }
    if (chatType == 'agent') {
      return 'calc(100vh - 150px)';
    }

    if (dialogFullScreen && chatType == 'dashboard') {
      return 'calc(100vh - 150px)';
    }

    if (dialogFullScreen) {
      return 'calc(100vh - 140px)';
    }

    return '420px';

  }

  const getPadding = () => {
    if (chatType == 'agent') {
      return '0px';
    }

    if (chatType == "boardChat" && !dialogFullScreen) {
      return '0px';
    }
    return '0px';

  }
  return (


    <Box
      id="chatAIUI"
      sx={{
        display: 'flex',
        flex: 1,
        overflowY: 'auto',
        overflowX: 'hidden',
        maxWidth: getMaxWidth(),
        flexDirection: 'column',
        pointerEvents: 'all',
        width: getWidth(),
        flexShrink: 1,
        flexGrow: 1,
        // height: getHeight(),
        // minWidth: getMinWidth(),
        // alignSelf: 'center',
        paddingTop: getPadding(),
        // padding: dialogFullScreen ? '25px calc((100vw - 300px) / 10) 0px' : 0,
        boxSizing: 'border-box',
        justifyContent: 'flex-start',
      }}
      ref={messagesEndRef}
    >
      {dialogFullScreen && <Box sx={{ height: 20 }}></Box>}

      {aiChatRows.length === 0 && (
        <EmptyChatComponent />
      )}

      {currentChatAiPersonaData &&
        currentChatAiPersonaData.description &&
        currentChatAiPersonaData.description.length !== 0 && (
          <AgentCompontent />
        )}

      {aiChatRows &&
        aiChatRows.map((item, index) => {
          return (
            <ChatItem
              key={index}
              item={item}
              index={index}
              dialogFullScreen={dialogFullScreen}
              chatType={chatType}
              avatarImage={avatarImage}
            />
          );
        })}
      {dialogFullScreen && <Box sx={{ height: 20 }}></Box>}

    </Box>

  );
}

export default ChatDialogContent;
