
import React from 'react';
import { IconShare } from '@tabler/icons-react';
import { Button, DialogContent, IconButton, Modal, ModalClose, ModalDialog, Tooltip } from '@mui/joy';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Box } from '@mui/joy';
import { DialogTitle } from '@mui/joy';
import { IconMail, IconLink, IconX } from '@tabler/icons-react';
import Util from 'util/utility';
import { useTranslation } from 'react-i18next';
import UtilityService from 'services/UtilityService';
import ClipboardService from 'services/ClipboardService';

function ChatShare() {

    const [openShareChat, setOpenShareChat] = React.useState(false);
    const userInfo = useSelector((state: RootState) => state.user.userInfo);
    const { t } = useTranslation();
    const currentChatSession: any = useSelector(
        (state: RootState) => state.AIAssist.currentChatSession
    );


    const handleChatShare = () => {
        const sessionId = currentChatSession._id;
        const url = `${window.location.origin}/chatShare/${sessionId}`;
        ClipboardService.getInstance().clipboardCopy(url);
        Boardx.Util.Msg.info(
            t('components.connectionNotification.youHaveCopiedShareLink')
        );
    }

    const removeClassInHTMLString = (htmlString) => {
        // Use DOMParser to parse the HTML string
        var parser = new DOMParser();
        var doc = parser.parseFromString(htmlString, 'text/html');

        // Select all elements with the class 'chatItemFooterIcons' and remove them
        var elements = doc.querySelectorAll('.chatItemFooterIcons');
        elements.forEach(element => element.parentNode.removeChild(element));

        // Add a grey hr line before each element with class 'avatar-img'
        var avatarImages = doc.querySelectorAll('.avatar-img');
        avatarImages.forEach(image => {
            var hr = document.createElement('hr');
            hr.style.border = 'none';
            hr.style.borderTop = '1px solid #ccc';
            image.parentNode.insertBefore(hr, image);
        });

        // Force all images under the div with class 'avatar-img' to have width: 36px, height: 36px, and border-radius: 50%
        var avatarImages = doc.querySelectorAll('.avatar-img img');
        avatarImages.forEach(image => {
            //@ts-ignore
            image.style.width = '24px';
            //@ts-ignore
            image.style.height = '24px';
            //@ts-ignore
            image.style.borderRadius = '50%';
        });

        // Serialize the document back to a string
        var serializer = new XMLSerializer();
        var updatedHtmlString = serializer.serializeToString(doc);
        return updatedHtmlString;
    }

    const handleSendEmail = async () => {
        var container = document.getElementById('chatAIUI');
        var htmlContent = container.outerHTML;
        let updatedHtmlContent = removeClassInHTMLString(htmlContent);

        const chatName = currentChatSession.name;
        await UtilityService.getInstance().sendEmail(userInfo.email, 'AI Chat Share: ' + chatName, updatedHtmlContent)
        Boardx.Util.Msg.info(t('chatAi.emailHasBeenSent'));

    }

    const handleDownloadPDF= async () => {
    
    }

    const handleChatShareClose = () => {
        setOpenShareChat(false);
    }
    const handleChatShareOpen = () => {
        setOpenShareChat(true);
    }

    return (<Box>
        <IconButton onClick={handleChatShareOpen}>
            <IconShare style={{ strokeWidth:"var(--joy-lineHeight-sm)" }} />
        </IconButton>
        <Modal
            open={openShareChat}
            onClose={handleChatShareClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <ModalDialog>
                <DialogTitle id="modal-modal-title">Share Chat</DialogTitle>
                <DialogContent>
                    <ModalClose onClick={handleChatShareClose} />
                    {/*share by email*/}
                    <Button
                        variant="outlined"
                        color='neutral'
                        onClick={handleSendEmail}
                        startDecorator={<IconMail />}
                        sx={{ justifyContent: 'flex-start' }}
                    > Send the Chat to my email</Button>
                    {/*share by link*/}

                    <Button
                        variant="outlined"
                        color='neutral'
                        onClick={handleChatShare}
                        startDecorator={<IconLink />}
                        sx={{ justifyContent: 'flex-start' }}
                    > Share the Chat by link</Button>


                </DialogContent>
            </ModalDialog>

        </Modal>
    </Box>


    )
}

export default ChatShare;