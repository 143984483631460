//** Import react
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import LazyLoad from 'react-lazyload';

//** Import i18n
import { useTranslation } from 'react-i18next';

//** Import Redux kit
import { RootState } from '../../store';
import { useSelector, useDispatch } from 'react-redux';
import {
  handleSetBoardList,
  handleSetCurrentBoardList,
  handleSetKeyword
} from '../../store/boardList';
import { handleSetRoomInfo, handleSetRoomId } from '../../store/room';
import {
  useToggleRoomFavoriteMutation,
  useLoadRoomInfoQuery
} from '../../redux/RoomAPISlice';

//** Import Mui
import Typography from '@mui/joy/Typography';
import Divider from '@mui/joy/Divider';
import Box from '@mui/joy/Box';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import ReviewsOutlinedIcon from '@mui/icons-material/ReviewsOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import AutoDeleteOutlinedIcon from '@mui/icons-material/AutoDeleteOutlined';
import FavoriteIcon from '../../mui/icons/FavoriteIcon';
import FavoriteNoIcon from '../../mui/icons/FavoriteNoIcon';
import Sheet from '@mui/joy/Sheet';
//** Import Services
import { useGetRoomListByOrgIdQuery } from '../../redux/OrgAPISlice';

//** Imort components
import CreateRoomModal from '../room/CreateRoomModal';

//** Import others
import clsx from 'clsx';

import List from "@mui/joy/List";
import ListSubheader from "@mui/joy/ListSubheader";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListItemContent from "@mui/joy/ListItemContent";
import IconButton from "@mui/joy/IconButton";
function RoomList({ parentComponent }) {
  //use

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openRoomId, setOpenRoomId] = useState('');
  const [roomList, setRoomList] = useState([]);
  //org
  const orgInfo = useSelector((state: RootState) => state.org.orgInfo);
  const roomInfo = useSelector((state: RootState) => state.room.roomInfo);
  //search
  const keywords = useSelector((state: RootState) => state.boardList.keyword);
  const [currentHoverRoomId, setCurrentHoverRoomId] = useState('');
  //room
  const { data } = useGetRoomListByOrgIdQuery(orgInfo.orgId);
  const { data: loadedRoomInfo } = useLoadRoomInfoQuery(openRoomId);
  const [toggleRoomFavorite] = useToggleRoomFavoriteMutation();

  const currentRoomId = useSelector((state: RootState) => state.room.roomId);

  //board
  const boardList = useSelector(
    (state: RootState) => state.boardList.currentBoardList
  );
  const checkRoomList = (l) => {
    let arr = [];
    var list = l;
    if (list?.length > 0) {
      list.forEach(item => {
        if (item.rid) {
          arr.push(item);
        } else {
          let a = { ...item, rid: item._id };
          arr.push({ ...item, rid: item._id });
        }
      });
    }
    return arr;
  }

  const deletedBoardList = useSelector(
    (state: RootState) => state.boardList.pendingDeleteBoardList
  );

  //functions
  const onHover = (e, favorite) => {
    if (favorite === false && e.target.lastElementChild) {
      e.target.lastElementChild.style.visibility = 'visible';
    }
  };

  const onLeave = (e, favorite) => {
    if (favorite === false && e.target.lastElementChild) {
      e.target.lastElementChild.style.visibility = 'hidden';
    }
  };

  const openRoom = list => {
    console.log('openRoom', list);
    let roomId = list.rid ? list.rid : list._id;
    setOpenRoomId(roomId);
    localStorage.setItem('roomId', roomId);
    navigate(`/room/${roomId}`);

    dispatch(handleSetKeyword(''));
    dispatch(handleSetRoomId(roomId));
    dispatch(handleSetCurrentBoardList([]));
    dispatch(handleSetRoomInfo({ ...roomInfo, roomId: list.rid, name: list.name }));
    (window as any).setMobileOpen(false);
  };
  //undone
  const toggleFavorite = async (e, roomId, value) => {
    if (e.type === 'click') {
      // error
      await toggleRoomFavorite({
        roomId: roomId,
        value: value,
        orgId: orgInfo.orgId
      });
      // Boardx.Util.Msg.warning(error.message);
    }
  };

  const handleClickRecentLink = () => {
    //window.setMobileOpen(false);
    navigate('/recent');
    dispatch(handleSetKeyword(''));
    (window as any).setMobileOpen(false);

  };

  const handleClickFavoritesLink = () => {
    navigate('/favorite');
    dispatch(handleSetKeyword(''));
    (window as any).setMobileOpen(false);

  };

  const handleClickAiAssistantLink = () => {
    navigate('/aiassistant');
    dispatch(handleSetKeyword(''));
  };

  const handleClickAppsStoreLink = () => {
    navigate('/appsStore');
    dispatch(handleSetKeyword(''));
  }

  const handleClickCommunityLink = () => {
    navigate('/community');
    dispatch(handleSetKeyword(''));
  };

  const handleClickPromptLink = () => {
    navigate('/prompt');
    dispatch(handleSetKeyword(''));
  };

  const handleClickDeletedLink = () => {
    navigate('/delete');
    dispatch(handleSetKeyword(''));
    (window as any).setMobileOpen(false);

  };

  const handleDeletedLinkDOM = () => {
    if (deletedBoardList.length !== 0) {
      return (

        <ListItem  >
          <ListItemButton onClick={handleClickDeletedLink} selected={parentComponent === 'deletePage'}>
            <ListItemDecorator>
              <AutoDeleteOutlinedIcon fontSize="small" />
            </ListItemDecorator>
            <ListItemContent>
              {t('pages.listPage.deletedBoards')}
            </ListItemContent>

          </ListItemButton>
        </ListItem>

      );
    }
    return null;
  };

  useEffect(() => {
    if (data) {
      setRoomList(checkRoomList(data));
    }
  }, [data]);

  return (
    <Box sx={{
      overflow: 'hidden',
      height: 'calc(100vh - 80px)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      mt: '10px',

    }}>
      <List size="sm" sx={{
        "--ListItem-radius": "8px", "--List-gap": 4, flexGrow: 0, flexShrink: 0,
        pl: '10px',
        pr: '10px'
      }}>

        <ListItem   >
          <ListItemButton onClick={handleClickRecentLink} selected={parentComponent === 'recentPage'}>
            <ListItemDecorator>
              <DashboardOutlinedIcon fontSize="small" />
            </ListItemDecorator>
            <ListItemContent> {t('pages.listPage.recent')}</ListItemContent>
          </ListItemButton>
        </ListItem>

        <ListItem         >
          <ListItemButton onClick={handleClickFavoritesLink} selected={parentComponent === 'favoritesBoardPage'}>
            <ListItemDecorator>
              <StarOutlineOutlinedIcon fontSize="small" />
            </ListItemDecorator>
            <ListItemContent>
              {t('pages.listPage.favorites')}
            </ListItemContent>

          </ListItemButton>
        </ListItem>

        {/* <ListItem>
          <ListItemButton onClick={handleClickAiAssistantLink} className={'LinkButton'} selected={parentComponent === 'AIAssistantPage'}>
            <ListItemDecorator>
              <ReviewsOutlinedIcon fontSize="small" />
            </ListItemDecorator>
            <ListItemContent>
              {t('pages.listPage.aiAssistant')}
            </ListItemContent>
          </ListItemButton>
        </ListItem>

        <ListItem    >
          <ListItemButton onClick={handleClickAppsStoreLink} selected={parentComponent === 'AppsStorePage'}>
            <ListItemDecorator>
              <EngineeringOutlinedIcon fontSize="small" />
            </ListItemDecorator>
            <ListItemContent>
              {t('pages.listPage.aiAgent')}
            </ListItemContent>
          </ListItemButton>
        </ListItem> */}
        <Divider sx={{ width: '90%', mt: '10px' }} />
        <ListSubheader sx={{ letterSpacing: "2px", fontWeight: "800", flexGrow: 0, flexShrink: 0 }}>
          <CreateRoomModal />
        </ListSubheader>
      </List>

      <List size="sm" sx={{
        "--ListItem-radius": "8px",
        "--List-gap": 4,
        flexGrow: 1,
        flexShrink: 1,
        overflow: 'auto',
        pl: '10px',
        pr: '10px'

      }}>
        {/* <ListItem
          sx={{ mt: '6px' }}
          component={RouterLink}
          className={
            parentComponent === 'communityPage'
              ? clsx('listLink', 'active')
              : 'listLink'
          }
          onClick={handleClickCommunityLink}
          to="#"
        >
          {t('pages.listPage.community')}
        </ListItem> */}

        {/* <ListItem
          sx={{ mt: '6px' }}
          component={RouterLink}
          className={
            parentComponent === 'promptPage'
              ? clsx('listLink', 'active')
              : 'listLink'
          }
          onClick={handleClickPromptLink}
          to="#"
        >
          {t('pages.listPage.prompt')}
        </ListItem> */}




        {roomList.map(list => {
          if (!list.prid) {
            return (
              <ListItem
                key={list.rid}
                title={list.name}
              >
                <ListItemButton
                  onClick={() => openRoom(list)}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                  selected={currentRoomId === list.rid && parentComponent === 'roomPage'}
                  onMouseEnter={() => setCurrentHoverRoomId(list.rid)}
                  onMouseLeave={() => setCurrentHoverRoomId('')}
                >
                  <Typography level='body-xs' sx={{
                    overflow: 'hidden',
                    width: '200px', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                  }}>
                    {list.name}
                  </Typography>

                  <IconButton size='sm' sx={{
                    padding: '0px',
                    margin: '0px',

                    "&:hover": {
                      transform: 'scale(1.2)',
                    }
                  }}>
                    {list.f ? (
                      <FavoriteIcon fontSize="small" style={{ strokeWidth:"var(--joy-lineHeight-sm)" }}
                        onClick={e => toggleFavorite(e, list.rid, false)}
                      />
                    ) : (
                      currentHoverRoomId == list.rid && (
                        <FavoriteNoIcon fontSize="small" style={{ strokeWidth:"var(--joy-lineHeight-sm)" }}
                          onClick={e => toggleFavorite(e, list.rid, true)}
                        />
                      )
                    )}
                  </IconButton>
                </ListItemButton>
              </ListItem>
            );
          }
          return null;
        })}

        {roomList && roomList.length === 0 ? (
          <Box sx={{
            padding: '12px',

            borderRadius: '4px',
            marginLeft: '24px',
            marginRight: '20px',
            boxSizing: 'border-box'
          }}>
            <Typography sx={{
              fontWeight: 500,

              lineHeight: '20px',

              marginBottom: '16px'
            }}>
              {t('components.noCreateRoomContent.noCreateRoomTitle')}
            </Typography>
            <Typography >
              {t('components.noCreateRoomContent.noCreateRoomContentOne')}
              <br />
              <br />
              {t('components.noCreateRoomContent.noCreateRoomContentTwo')}
              <br />
              <br />
              {t('components.noCreateRoomContent.noCreateRoomContentThree')}
            </Typography>
          </Box>
        ) : null}
      </List>

      <List size="sm" sx={{
        "--ListItem-radius": "8px", "--List-gap": 4, flexGrow: 0, flexShrink: 0,
        pl: '10px',
        pr: '10px'
      }}>

        <Divider sx={{
          marginBottom: '10px',
          mt: '10px',
          width: '90%',

          //display: 'flex',
          //flexDirection: 'column',
          //alignItems: 'center',
          //pl: '10px',
          //pr: '10px'
        }} />

        {handleDeletedLinkDOM()}
      </List>


    </Box>
  );
}

export default RoomList;
