//** Import react
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

//** Import i18n

import { useTranslation } from 'react-i18next';

//** Import Redux kit
import store, { RootState } from '../../store';
import { useSelector, useDispatch } from 'react-redux';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/joy/Divider';
import Box from '@mui/joy/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { BootstrapDialogTitle } from '../../mui/components/BootstrapDialog';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import Typography from '@mui/joy/Typography';
import ArrowRight from '@mui/icons-material/ArrowRight';

//**Import Services
import { SysService, UserService } from '../../services';

//**Import others
import PropTypes from 'prop-types';
import InviteFriend from './InviteFriend';
import data, { versionNo } from '../../util/update';
import UserBasicInfo from './UserBasicInfo';


const UserMenu = ({ logout }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuPosition, setMenuPosition] = useState(null);
  const userInfo: any = useSelector((state: RootState) => state.user.userInfo);
  const userAdminRole = userInfo.roles.includes('BoardX Admin');
  const uiType = localStorage.getItem('currentUIType');
  const [open, setOpen] = useState(false);

  const handleClick = event => {
    if (menuPosition) {
      return;
    }
    event.preventDefault();
    setMenuPosition({
      top: event.pageY,
      left: event.pageX
    });
  };

  const handleClose = () => {
    setMenuPosition(null);
  };

  const handleClickGoToProfileLink = () => {
    navigate('/profile');
    handleClose()
  }

  const handleClickLanguageSwitch = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseLanguageSwitch = () => {
    setAnchorEl(null);
  }

  const onChangeLanguage = (event, language) => {
    event.preventDefault();
    if (language) {
      SysService.setLocale(language);
      window.location.reload();
    }
  };

  const renderLoggedIn = () => (

    <Box
      data-cy="user-menu"
      onClick={handleClick}
      sx={{
        cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '10px',  mr:'30px'

      }}
    >
      <Typography level='body-xs' variant='solid' sx={{
        padding: '3px 10px ',
        margin: '2px',
        borderRadius: '4px'
      }}>
        {userInfo.status == 'pro' ? t('components.billing.professional') : t('components.billing.planFree')}
      </Typography>
      <Avatar
        aria-label="user"
        id="avatarMenu"
 
        alt={
          userInfo.avatarType === 'data'
            ? userInfo.nickName
            : userInfo.nickName?.toUpperCase()
        }

        sx={{
          bgcolor: Boardx.Util.getAvatarColor(userInfo.nickName || ''),
          width: '28px',
          height: '28px',
          fontSize: '12px',
          display: 'flex',
          alignItems: 'center'
        }}
        src={userInfo.avatar}
      />

      <Menu

        anchorEl={document.getElementById('avatarMenu')}
        onClose={() => setMenuPosition(null)}
        open={!!menuPosition}
      // open={true}
      >
        <MenuItem

          style={{ backgroundColor: 'transparent' }}
        >
          <UserBasicInfo />
        </MenuItem>
        <Divider  />
        {store.getState().user.userInfo &&
          store.getState().user.userInfo.type &&
          store.getState().user.userInfo.type === 'user' && (
            <MenuItem
              data-cy="account"
            >
              <Typography
                level="title-md"
                sx={{

                }}
                onClick={handleClickGoToProfileLink}
              >
                {t('components.userMenu.accounts')} &{' '}
                {t('components.userMenu.settings')}
              </Typography>
            </MenuItem>
          )}
        <MenuItem data-cy="invite">
           <InviteFriend /> 
        </MenuItem>

        {userAdminRole && (<MenuItem

          onClick={() => navigate('/admin')}

        >
          <Typography
            level="title-md"
          >
            
            {t('components.userMenu.adminConsole')}
          </Typography>

        </MenuItem>)}

        <MenuItem

          onClick={handleClickLanguageSwitch}
        >         
        <Typography
          level="title-md"
        >
            {t('board.header.moreLanguage')}
          </Typography>
          <ArrowRight />
        </MenuItem>

        <Menu
          anchorEl={anchorEl}

          keepMounted
          onClose={handleCloseLanguageSwitch}
          open={Boolean(anchorEl)}
          style={{ boxShadow: '1px 1px 4px 2px #D9A1B18A', marginLeft: '-8px' }}

        >
          <MenuItem onClick={event => onChangeLanguage(event, 'en')}>
            English
          </MenuItem>
          <MenuItem onClick={event => onChangeLanguage(event, 'zh-CN')}>
            中文
          </MenuItem>
        </Menu>

        <MenuItem onClick={logout}>
          <Box sx={{
     
          }}>
            <Typography level='title-md'>
            {t('components.userMenu.logout')}
            </Typography>
          
          </Box>
        </MenuItem>
      </Menu>

      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        fullWidth
        onClose={() => {
          setOpen(false);
          handleClose();
        }}
        open={false}
      >
        <BootstrapDialogTitle
          onClose={() => {
            setOpen(false);
            handleClose();
          }}
        >
          {t('components.userMenu.version')}
        </BootstrapDialogTitle>
        <DialogContent>
          <Timeline position="right">
            {data.map((e, index) => (
              <TimelineItem key={index}>
                <TimelineOppositeContent>{e.time}</TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot variant="outlined" color="primary" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent
                  dangerouslySetInnerHTML={{ __html: e.content }}
                ></TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </DialogContent>
      </Dialog>
    </Box>
  );

  const renderLoggedOut = () => (
    <div className="user-menu">
      <Link className="btn-primary" href="/signin">
        {t('components.userMenu.login')}
      </Link>
      <Link className="btn-primary" href="/join">
        {t('components.userMenu.join')}
      </Link>
    </div>
  );

  return renderLoggedIn();
};

UserMenu.propTypes = {
  logout: PropTypes.func
};

export default UserMenu;
