export enum WidgetType {
  WBActiveSelection = 'activeSelection',
  WBArrow = 'WBArrow',
  WBCircle = 'WBCircle',
  WBCircleNotes = 'WBCircleNotes',
  WBFile = 'WBFile',
  WBGroup = 'WBGroup',
  WBImage = 'WBImage',
  WBIText = 'WBIText',
  WBObject = 'WBObject',
  WBPath = 'WBPath',
  WBPolyline = 'WBPolyline',
  WBLine = 'WBLine',
  WBRdRectPanel = 'WBRdRectPanel',
  WBRect = 'WBRect',
  WBRectNotes = 'WBRectNotes',
  WBRectPanel = 'WBRectPanel',
  WBShape = 'WBShape',
  WBShapeNotes = 'WBShapeNotes',
  WBTriangle = 'WBTriangle',
  WBUrlImage = 'WBUrlImage',
  WBText = 'WBText',
  WBTextbox = 'WBTextbox',
  Common = 'common',
}

export enum NoteType {
  RECT = 'rect',
  SQUARE = 'square',
  CIRCLE = 'circle',
  TEXT = 'text'
}


