import SvgIcon from '@mui/joy/SvgIcon';
import React from 'react';

export default function UserManagementManageTagsIcon(props) {
  return (
    <SvgIcon
      {...props}
      style={{ width: 20, height: 20, marginRight: '10px' }}
      viewBox="0 0 20 20"
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.3195 3.33301L5.51611 10.1364C5.18278 10.4689 5.00016 10.9107 5.00016 11.3815C5.00016 11.8523 5.18361 12.2958 5.51611 12.6283L9.03825 16.1504C9.37075 16.4829 9.81499 16.6663 10.285 16.6663C10.7558 16.6663 11.1984 16.4829 11.5301 16.1504L18.3335 9.347V3.33301H12.3195ZM7.8029 3.57715L1.32178 10.0583C1.00678 10.3733 0.833496 10.7908 0.833496 11.2367C0.833496 11.6825 1.00678 12.1 1.32178 12.415L5.79118 16.8861L6.96956 15.7077L2.50016 11.2367L8.98128 4.75391L7.8029 3.57715ZM13.0096 4.99967H16.6668V8.6569L10.3517 14.972H10.2166L6.6945 11.4499V11.3148L13.0096 4.99967ZM14.1668 6.66634C13.9458 6.66634 13.7339 6.75414 13.5776 6.91042C13.4213 7.0667 13.3335 7.27866 13.3335 7.49967C13.3335 7.72069 13.4213 7.93265 13.5776 8.08893C13.7339 8.24521 13.9458 8.33301 14.1668 8.33301C14.3878 8.33301 14.5998 8.24521 14.7561 8.08893C14.9124 7.93265 15.0002 7.72069 15.0002 7.49967C15.0002 7.27866 14.9124 7.0667 14.7561 6.91042C14.5998 6.75414 14.3878 6.66634 14.1668 6.66634Z"
          fill="#544F5A"
        />
      </svg>
    </SvgIcon>
  );
}

