
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Layout from './Layout';
import Box from '@mui/joy/Box';
import RoomLeftDrawer from 'components/room/RoomLeftDrawer';
import { handleSetCurrentApp } from 'store/system/index';
import store, { RootState } from 'store/index';
import { useSelector, useDispatch } from 'react-redux';

export default function BoardLayout() {

    const parentComponent = useSelector((state: RootState) => state.system.parentComponent);

    const onClose = () => {
        console.log('close')
    }
    useEffect(() => {
        store.dispatch(handleSetCurrentApp('board'));
    }, []);

    return (

        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <RoomLeftDrawer parentComponent={parentComponent} />
            <Box sx={{ width: '100%', flexGrow: 1, flexShrink: 1 }}>
                <Outlet />
            </Box>
        </Box>



    );
}