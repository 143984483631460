import { createSlice } from '@reduxjs/toolkit';
import {IDrawState, BrushWidthRange} from 'definition/widget/IDraw';

const initialState: IDrawState = {
  brushWidth: BrushWidthRange.LEVEL1, 
  brushColor: 'rgba(133,133,133, 1)'
};

const draw = createSlice({
  name: 'defaultDraw',
  initialState,
  reducers: {
    updateDrawColor(state, { payload }) {
      state.brushColor = payload;
    },

    updateDrawWidth(state, { payload }) {
      state.brushWidth = payload;
    }
  }
});

export const { updateDrawColor, updateDrawWidth } = draw.actions;
export default draw.reducer;
