import * as React from 'react';
import type {} from '@mui/material/themeCssVarsAugmentation';
import { Tooltip } from '@mui/joy';
//** Import i18n
import { useTranslation } from 'react-i18next';
import {
  useColorScheme as useMaterialColorScheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  experimental_extendTheme as extendMaterialTheme,
  THEME_ID
} from '@mui/material/styles';

// Material UI components

// Icons
import { IconSun, IconMoon } from '@tabler/icons-react';
import DarkMode from '@mui/icons-material/DarkMode';
import LightMode from '@mui/icons-material/LightMode';

import useMediaQuery from '@mui/material/useMediaQuery';
import store, { RootState } from 'store';
import { handleInitiateThemeMode, handleSetThemeMode } from 'store/system';
import { useSelector } from 'react-redux';
// Joy UI components
import { IconButton } from '@mui/joy';

import {
  CssVarsProvider as JoyCssVarsProvider,
  useColorScheme as useJoyColorScheme
} from '@mui/joy/styles';
import { use } from 'i18next';

type Mode = 'light' | 'dark';

const ModeToggle = () => {
  const { t } = useTranslation();

  const preferredMode = useMediaQuery('(prefers-color-scheme: dark)')
    ? 'dark'
    : 'light';
  const themeMode = useSelector((state: RootState) => state.system.themeMode);
  const { mode, setMode } = useMaterialColorScheme();
  const { setMode: setJoyMode } = useJoyColorScheme();
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    
    if (!localStorage.getItem('theme')) {
      store.dispatch(handleInitiateThemeMode(preferredMode));
    } else {
      store.dispatch(handleInitiateThemeMode(localStorage.getItem('theme')));
    }

    setMounted(true);
  }, []);

  React.useEffect(() => {
    if (themeMode !== '') {
      setMode(themeMode as Mode);
      setJoyMode(themeMode as Mode);
    }
  }, [themeMode]);

  const handleClickSwitchTheme = () => {
    const newMode = themeMode === 'dark' ? 'light' : 'dark';
    store.dispatch(handleSetThemeMode(newMode));

    localStorage.setItem('theme', newMode);
  };

  if (!mounted) {
    return null;
  }
  return (
    <Tooltip arrow placement="bottom" title={t('board.header.changeThemeMode')}>
      <IconButton
        sx={{
          backgroundColor: 'var(--joy-palette-background-surface)',
          borderShadow: 'var(--joy-shadow-md)',
          width: '40px',
          height: '40px',
          p: '8px',
          '&:hover': {
            backgroundColor: 'var(--joy-palette-primary-100)',
            '.tabler-icon': {
              color: 'var(--joy-palette-primary-500)'
            }
          }
        }}
        onClick={handleClickSwitchTheme}
      >
        {mode === 'dark' ? <IconMoon style={{ strokeWidth:"var(--joy-lineHeight-sm)" }} /> : <IconSun style={{ strokeWidth:"var(--joy-lineHeight-sm)" }}/>}
      </IconButton>
    </Tooltip>
  );
};

export default ModeToggle;
