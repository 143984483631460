import axios from 'axios';
import { AiAssistApi } from '../redux/AiAssistApiSlice';
import { CLOUD_FUNCTION_URL } from '../startup/serverConnect';
import { handleSetIsReading, handleSetShowAiChatLoading } from '../store/board';
import FileService from './FileService';
import UtilityService from './UtilityService';

import { handleSetAIChatRows } from '../store/AIAssist';

import {
  handleSetChatSessionList,
  handleSetCurrentChatAiPersonaData,
  handleSetCurrentChatSession
} from '../store/AIAssist';

import LZUTF8 from 'lzutf8';
import { api } from 'redux/api';
import { handleSetAIChatMessages } from '../store/AIAssist';
import {
  handleSetBoardPanelClicked,
  handleSetDrawingEraseMode,
  handleSetIsPanMode
} from '../store/board';
import { handleSetOpenTemplate } from '../store/resource';
import { handleOpenChatUI } from '../store/sideBar';
import BoardService from './BoardService';

export default class AIService {
  public chatSessionId = null;
  public chatLimit = 50;
  public chatSubscription = null;
  private abortChatRequest = false;
  private limitOfQuota = 15;

  static service = null;

  static getInstance(): AIService {
    if (AIService.service == null) {
      AIService.service = new AIService();

      Boardx.Instance.AIService = AIService.service;
    }

    return AIService.service;
  }

  constructor() {}

  async requestGPT4Process(prompt, temperature = 0.6) {
    const response = await this.handleRequestAIWidget(
      'gpt-4',
      prompt,
      '',
      temperature,
      null
    );
    return response['data'];
  }

  async requestGPT3Process(prompt, temperature = 0.6) {
    const response = await this.handleRequestAIWidget(
      'gpt-3',
      prompt,
      '',
      temperature,
      null
    );
    return response['data'];
  }

  async handleRefinePrompt(prompt, temperature) {
    const response = await this.requestGPT4Process(prompt, temperature);
    return response;
  }

  isTimeDifferenceGreaterThan24Hours(timestamp1, timestamp2) {
    const timeDifference = Math.abs(timestamp1 - timestamp2); // 计算时间戳之间的绝对时间差
    const oneDayInMillis = 86400000; // 24小时的毫秒数
    return timeDifference > oneDayInMillis;
  }

  checkUsageQuota() {
    if (!localStorage.getItem('gpt4UsageLog')) {
      let gpt4UsageLog = {
        lastTime: new Date().getTime(),
        numberOfUsesRemaining: this.limitOfQuota
      };
      localStorage.setItem('gpt4UsageLog', JSON.stringify(gpt4UsageLog));
    }

    let gpt4UsageLog = JSON.parse(localStorage.getItem('gpt4UsageLog'));
    const lastTime = gpt4UsageLog.lastTime;
    const currentTime = new Date().getTime();
    const isGreaterThan24Hours = this.isTimeDifferenceGreaterThan24Hours(
      lastTime,
      currentTime
    );

    if (isGreaterThan24Hours) {
      gpt4UsageLog = {
        lastTime: currentTime,
        numberOfUsesRemaining: this.limitOfQuota
      };
      localStorage.setItem('gpt4UsageLog', JSON.stringify(gpt4UsageLog));
    }

    if (gpt4UsageLog.numberOfUsesRemaining > 0) {
      gpt4UsageLog.numberOfUsesRemaining -= 1;
      localStorage.setItem('gpt4UsageLog', JSON.stringify(gpt4UsageLog));
      return true;
    } else {
      return false;
    }
  }

  ///call GPT3 to handel text
  async handleRequestAIWidget(
    gptModel,
    promptTemplate,
    targetData,
    temperature,
    outputFormat
  ) {
    const url = `${CLOUD_FUNCTION_URL}/chat/handleRequestAIWidget`;

    const data = {
      model: gptModel,
      prompt: promptTemplate,
      targetData,
      temperature,
      outputFormat
    };

    //to avoid trigger xss rules of cloudflare and cors error

    const result = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return result;
  }

  ///call GPT3 to handle text
  async handleSaveChatSummary(sessionId, prompt, response) {
    if (!sessionId) return;
    const url = `${CLOUD_FUNCTION_URL}/chat/handleSaveChatSummary`;
    const data = { sessionId, prompt, response };
    const compressedData = LZUTF8.compress(JSON.stringify(data), {
      outputEncoding: 'Base64'
    });

    const result = await axios.post(url, compressedData, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });

    return result;
  }

  ///call GPT3 to handel text
  async handleDocSummarization(text, prompt) {
    const url = `${CLOUD_FUNCTION_URL}/handleDocSummarization`;

    const result = await axios.post(url, { text, prompt });

    return result?.data;
  }

  async deleteChatSessionById(sessionId) {
    const result = server.call('ai.AIChat.deleteSession', sessionId);
    // await api.util.invalidateTags([{ type: 'aiChatSessionList'  }]);
    await store.dispatch(api.util.invalidateTags(['aiChatSessionList']));
    await this.loadChatSessionList('boardChat');
    return result;
  }

  getDefaultAIModel() {
    const userInfo = store.getState().user.userInfo;
    const aiModel = userInfo.status == 'free' ? 'gpt-4' : 'gpt-4';
    return aiModel;
  }

  async loadChatSessionList(chatType) {
    let newChatSessionList = [];
    const roomInfo = store.getState().room.roomInfo;
    const boardId = store.getState().board.board._id;
    const userId = store.getState().user.userInfo.userId;
    const orgId = store.getState().org.orgInfo.orgId;
    const chatSessionList = store.getState().AIAssist.chatSessionList;

    if (chatType === 'boardChat') {
      let boardSessions = [];
      chatSessionList.forEach(chatSession => {
        if (chatSession.entityId === boardId) {
          // newChatSessionList.unshift(chatSession);
          boardSessions.push(chatSession);
          return;
        }
        newChatSessionList.push(chatSession);
      });
      newChatSessionList = [...boardSessions, ...newChatSessionList];

      store.dispatch(handleSetChatSessionList(newChatSessionList));
      if (boardSessions.length === 0) {
        const aiModel = this.getDefaultAIModel();
        const newSession = await this.createNewChatSession(
          userId,
          orgId,
          true,
          aiModel
        );

        store.dispatch(handleSetCurrentChatSession(newSession));
        return;
      } else {
        store.dispatch(handleSetCurrentChatSession(boardSessions[0]));
        this.loadChatSession(boardSessions[0]);
        return;
      }
    }

    if (chatType === 'agent') {
      chatSessionList.forEach(chatSession => {
        if (chatSession.entityId === 'agent') {
          newChatSessionList.unshift(chatSession);
          return;
        }
        newChatSessionList.push(chatSession);
      });
      store.dispatch(handleSetChatSessionList(newChatSessionList));
      return;
    }

    if (chatSessionList.length > 0) {
      store.dispatch(handleSetChatSessionList([...chatSessionList]));
      store.dispatch(handleSetCurrentChatSession(chatSessionList[0]));
      this.loadChatSession(chatSessionList[0]);
      if (chatSessionList[0].promptPersonaData) {
        store.dispatch(
          handleSetCurrentChatAiPersonaData(
            chatSessionList[0].promptPersonaData
              ? chatSessionList[0].promptPersonaData
              : null
          )
        );
      }
    }
  }

  generateNewChatSessionName = entityId => {
    let newChatSessionName = '';
    const orgInfo = store.getState().org.orgInfo;
    const board = store.getState().board.board;
    const roomInfo = store.getState().room.roomInfo;

    if (entityId === 'recent') {
      newChatSessionName = orgInfo.name;
    } else if (entityId === board._id && entityId !== '') {
      newChatSessionName = board.name;
    } else {
      newChatSessionName = orgInfo.name;
    }

    return newChatSessionName;
  };

  getChatSessionEntityId = () => {
    let newChatSessionName = '';
    const orgInfo = store.getState().org.orgInfo;
    const board = store.getState().board.board;
    const roomInfo = store.getState().room.roomInfo;
    let entityId = '';

    if (location.pathname.includes('/board/')) {
      entityId = board._id;
    } else if (location.pathname.includes('/aiAssistant')) {
      entityId = 'aiassistant';
    } else if (location.pathname.includes('/admin')) {
      entityId = 'agent';
    }

    return entityId;
  };

  createNewChatSession = async (userId, orgId, includeContext, gptModel) => {
    const currentChatSession = store.getState().AIAssist.currentChatSession;
    const currentAgent = store.getState().AIAssist.currentChatAiPersonaData;
    console.log('##createNewChatSession');
    const entityId = this.getChatSessionEntityId();
    // let entityId = 'recent';
    let newChatSessionName = this.generateNewChatSessionName(entityId);
    let defaultAgent = null;

    //if it is new chat session and there is selected agent, then use the default agent
    if ((!currentChatSession || !currentChatSession._id) && currentAgent) {
      defaultAgent = currentAgent;
    }

    const newAiChatSession = {
      _id: UtilityService.getInstance().generateWidgetID(),
      name: newChatSessionName,
      userId: userId,
      createdAt: new Date().getTime(),
      orgId: orgId,
      entityId: entityId,
      systemMessage: '',
      gptModel: gptModel,
      isIncludeBoardContent: includeContext,
      isIncludeBoardFilesContent: false,
      promptPersonaData: currentAgent
    };

    const result = await store.dispatch(
      AiAssistApi.endpoints.addNewAIChatSession.initiate({
        newAIChatdata: newAiChatSession
      })
    );

    let chatSessionList = store.getState().AIAssist.chatSessionList;
    let newChatSessionList = [newAiChatSession, ...chatSessionList];
    await store.dispatch(handleSetChatSessionList(newChatSessionList));

    await this.loadChatSession(newAiChatSession);
    return newAiChatSession;
  };
  // addNewAIChatSession({ newAIChatdata: newAiChatSession });
  // store.handleClickOpenChatUI(e, newAiChatSession);

  loadChatSession = async newAiChatSession => {
    console.log('##loadChatSession', newAiChatSession._id);
    const userInfo = store.getState().user.userInfo;
    await AIService.getInstance().InitializeChatSessionByID(
      newAiChatSession._id
    );
    store.dispatch(handleOpenChatUI(true));
    store.dispatch(handleSetCurrentChatSession(newAiChatSession));
    store.dispatch(
      handleSetCurrentChatAiPersonaData(
        newAiChatSession.promptPersonaData
          ? newAiChatSession.promptPersonaData
          : null
      )
    );
    store.dispatch(handleSetIsPanMode(false));
    store.dispatch(handleSetOpenTemplate(false));

    BoardService.getInstance().resetBoardMenuEvents();

    store.dispatch(handleSetDrawingEraseMode(false));
    store.dispatch(handleSetBoardPanelClicked(false));
  };

  //this is for loading chat history by session id from web methods
  async loadChatHistoryBySessionId(chatSessionId) {
    const chats = await server.call(
      'ai.AIChatSession.getChatHistory',
      chatSessionId
    );
    const aiChats = chats
      .map(aiChat => ({
        ...aiChat,
        _id: aiChat.id
      }))
      .sort((a, b) => a.createdAt - b.createdAt);

    this.loadChatMessages(aiChats);
    await store.dispatch(handleSetAIChatRows(aiChats));
  }

  /***
   * initialize chat session by id
   * @param chatSessionId the session id
   * @param userId the user id
   */
  async InitializeChatSessionByID(chatSessionId) {
    this.chatSessionId = chatSessionId;
    if (!this.chatSessionId) return;
    if (this.chatSubscription) await this.chatSubscription.remove();

    const aiChatCollection = server.collection('aiChat');
    aiChatCollection.onChange(async () => {
      const aiChats = await aiChatCollection
        .fetch()
        .filter(aiChat => aiChat.chatSessionId === chatSessionId)
        .map(aiChat => ({
          ...aiChat,
          _id: aiChat.id
        }))
        .sort((a, b) => a.createdAt - b.createdAt);
      this.loadChatMessages(aiChats);
      await store.dispatch(handleSetAIChatRows(aiChats));
    });
    //subscription aichat

    this.chatSubscription = server.subscribe(
      'aiChat',
      this.chatSessionId,
      this.chatLimit
    );

    await this.chatSubscription.ready();
  }

  async loadChatMessages(aiChat) {
    const newMsg = [];
    const newAiChat = [...aiChat].reverse();

    for (const chat of newAiChat) {
      if (chat.type === 'user') {
        newMsg.push({ role: 'user', content: chat.message });
      }
      if (chat.type === 'AI') {
        newMsg.push({ role: 'assistant', content: chat.message });
      }
    }
    await store.dispatch(handleSetAIChatMessages(newMsg.reverse()));
  }

  async updateChatMessage(chatId, message) {
    const result = await server.call('ai.AIChat.UpdateMsg', chatId, message);
  }

  async insertMessage(
    userMessage,
    newCurrentChatSession,
    orgId,
    messageType = 'user',
    parentChatId = null
  ) {
    try {
      const result = await server.call(
        'ai.AIChat.InsertMsg',
        userMessage,
        newCurrentChatSession,
        orgId,
        messageType,
        parentChatId
      );
      return result;
    } catch (err) {
      console.log('err', err);
      store.dispatch(handleSetShowAiChatLoading(false));
    }
  }

  cancelRequestAIChat() {
    try {
      this.abortChatRequest = true;
    } catch (err) {
      console.log('err', err);
    }
  }

  async updateChatSessionInfo(sessionId, msg, AIChatMessages) {
    let data = null;

    if (AIChatMessages.length == 0) {
      const prompt =
        'please refine the content as a title,just reply the string, keep it short, content:' +
        msg;
      const newTitle = await this.requestGPT4Process(prompt, 0.6);

      const cleanedTitle = newTitle.replace(/"/g, ''); // Remove double quotes from newTitle

      data = {
        name: cleanedTitle,
        lastUpdatedTime: new Date().getTime()
      };
    } else {
      data = {
        lastUpdatedTime: new Date().getTime()
      };
    }

    await this.updateChatSession(data, sessionId);
    await this.updateLocalChatSession(data, sessionId);
  }

  async updateChatSessionLastUpdatedTime(sessionId) {
    const data = {
      lastUpdatedTime: new Date().getTime()
    };
    await this.updateChatSession(data, sessionId);
  }

  async handleRequestEditChat(chatItem, newPrompt = '') {
    const currentChatList = store.getState().AIAssist.aiChatRows;

    const parentChatId = chatItem.parentChatId;
    const parentChatItem = currentChatList.find(
      chat => chat._id === parentChatId
    );

    store.dispatch(handleSetIsReading(true));
    store.dispatch(handleSetShowAiChatLoading(true));

    if (newPrompt == '') {
      newPrompt = chatItem.message;
    } else {
      this.updateChatMessage(parentChatId, newPrompt);
    }
    // Manually update the chatItem in newAIChatRows
    const newAIChatRows = currentChatList.map(chat => {
      if (chat._id === chatItem._id) {
        return {
          ...chat,
          message: newPrompt
        };
      }
      return chat;
    });
    store.dispatch(handleSetAIChatRows(newAIChatRows));

    // Manually update the chatItem in newAIChatMessages
    const newAIChatMessages = store
      .getState()
      .AIAssist.aiChatMessages.map(chat => {
        if (chat.content === chatItem.message) {
          return {
            ...chat,
            content: newPrompt
          };
        }
        return chat;
      });
    store.dispatch(handleSetAIChatMessages(newAIChatMessages));

    const result = await server.call('ai.AIChat.deleteMsg', chatItem._id);

    const skipUserMessageInsert = true;
    await this.handleRequestAIChat(
      newPrompt,
      null,
      newPrompt,
      'user',
      skipUserMessageInsert,
      chatItem.parentChatId
    );
  }

  async handleRequestAIChat(
    msg,
    image,
    contentOnTheBoard = '',
    chatType,
    skipUserMessageInsert = false,
    parentChatId = null
  ) {
    console.log('##chatType', chatType);
    try {
      this.abortChatRequest = false;
      const orgId = store.getState().org.orgInfo.orgId;
      let currentChatSession = store.getState().AIAssist.currentChatSession;
      const url = `${CLOUD_FUNCTION_URL}/chat/handleRequestAIChat`;
      let AIChatMessages = store.getState().AIAssist.aiChatMessages;

      //check if existing chat session
      if (!currentChatSession || !currentChatSession._id) {
        //then create new chat session
        await this.createNewChatSession(
          store.getState().user.userInfo.userId,
          orgId,
          true,
          this.getDefaultAIModel()
        );
        //sleep for 1 second

        currentChatSession = store.getState().AIAssist.currentChatSession;

        AIChatMessages = store.getState().AIAssist.aiChatMessages;
      }

      const messages = [...AIChatMessages];
      const contextContent =
        chatType == 'agent'
          ? ''
          : await this.getContextContentForBoardAndRoomAndTeam();
      const currentChatAiPersonaData =
        store.getState().AIAssist.currentChatAiPersonaData;
      const currentAgent = store.getState().AIAssist.currentAgent;
      let systemMessage;
      const abortController = new AbortController();

      this.updateChatSessionInfo(currentChatSession._id, msg, AIChatMessages);
      this.updateChatSessionLastUpdatedTime(currentChatSession._id);

      if (chatType !== 'agent') {
        systemMessage =
          currentChatSession && currentChatSession.systemMessage
            ? currentChatSession.systemMessage
            : '';
      } else {
        systemMessage = currentAgent.command;
      }

      const chatAiPersonaSystemMessage = currentChatAiPersonaData
        ? currentChatAiPersonaData.command
        : '';

      messages.unshift({
        role: 'system',
        content: `${chatAiPersonaSystemMessage} \n ${systemMessage} \n  ${contentOnTheBoard} \n ${contextContent}`
      });

      let urlImage = '';
      let originalMsg = msg;
      if (image) {
        urlImage = await FileService.getInstance().uploadImageToUser(
          image,
          () => {}
        );
        //append urlImage as markdown format iamge to msg
        msg = msg + '\n\n   ' + `![image](${urlImage})`;
      }

      let requestData;
      if (chatType == 'agent') {
        requestData = {
          prompt: originalMsg,
          messages: messages,
          image: urlImage,
          // promptPersonaData: null,
          model: currentAgent.gptModel,
          sessionId: currentAgent.chatSessionId
        };
      } else {
        requestData = {
          prompt: originalMsg,
          messages: messages,
          image: urlImage,
          // promptPersonaData: currentChatAiPersonaData,
          model: currentChatSession.gptModel,
          sessionId: currentChatSession._id
        };
      }

      let newCurrentChatSession = {
        _id:
          chatType == 'agent'
            ? currentAgent.chatSessionId
            : currentChatSession._id,
        message: msg,
        contentOnTheBoard: contentOnTheBoard
      };

      // if (contentOnTheBoard) {
      //   newCurrentChatSession = {
      //     ...currentChatSession,
      //     contentOnTheBoard: contentOnTheBoard
      //   };
      // } else {
      //   contentOnTheBoard = '';
      // }

      if (!skipUserMessageInsert) {
        parentChatId = await this.insertMessage(
          msg,
          newCurrentChatSession,
          orgId,
          'user'
        );
      }

      //press the data to base64 string to avoid trigger xss rules of cloudflare and cors error
      // const compressedData = LZUTF8.compress(JSON.stringify(requestData), {
      //   outputEncoding: 'Base64'
      // });;
      let responses;
      console.log('##requestData', url, requestData);
      responses = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData),
        signal: abortController.signal
      });

      console.log('##responses', responses);

      const reader = responses.body.getReader();

      let AIMessage = '';

      const AIChatMessageId = await this.insertMessage(
        AIMessage,
        newCurrentChatSession,
        orgId,
        'AI',
        parentChatId
      );

      let response = '';

      const readStream = async abordController => {
        const { done, value } = await reader.read();
        store.dispatch(handleSetShowAiChatLoading(false));

        if (done || this.abortChatRequest) {
          await AIService.getInstance().updateChatMessage(
            AIChatMessageId,
            AIMessage
          );
          store.dispatch(handleSetShowAiChatLoading(false));
          store.dispatch(handleSetIsReading(false));
          // setIsShowCancelTheAvaRequestBtn(false);

          // if (chatType !== 'agent') {
          //   await AIService.getInstance().handleSaveChatSummary(store.getState().AIAssist.currentChatSession._id, originalMsg, response);

          // }
          if (this.abortChatRequest) {
            abordController.abort();
          }
          return;
        }

        const text = new TextDecoder().decode(value);

        response += text;
        const characters = text.split('');

        const printCharacter = async index => {
          // const timeout =
          //   store.getState().user.userInfo.status === 'pro' ? 0 : 0;
          if (index < characters.length) {
            // setTimeout(() => {
            AIMessage = AIMessage + characters[index];

            let aiChatArr = store.getState().AIAssist.aiChatRows;

            store.dispatch(
              handleSetAIChatRows(
                aiChatArr.map(chat =>
                  chat._id === AIChatMessageId
                    ? { ...chat, message: AIMessage }
                    : chat
                )
              )
            );

            printCharacter(index + 1);
            // }, timeout);
          } else {
            readStream(abortController);
          }
        };

        printCharacter(0);
      };

      readStream(abortController);
    } catch (error) {
      console.error(error);
      store.dispatch(handleSetShowAiChatLoading(false));
      // setIsShowCancelTheAvaRequestBtn(false);
    }
  }

  getContextContentForBoardAndRoomAndTeam = async () => {
    try {
      let contextContent = '';
      const orgId = store.getState().org.orgInfo.orgId;
      const boardId = store.getState().board.board._id;
      const roomId = store.getState().board.board.roomId;

      const { data: teamContext } = await store.dispatch(
        AiAssistApi.endpoints.getTeamAiContextContent.initiate(orgId)
      );

      const { data: roomContext } = await store.dispatch(
        AiAssistApi.endpoints.getRoomAiContextContent.initiate(roomId)
      );

      const { data: boardContext } = await store.dispatch(
        AiAssistApi.endpoints.getBoardAiContextContent.initiate(boardId)
      );

      contextContent = teamContext
        ? teamContext?.data?.teamContext
        : '' + '\n' + roomContext
          ? roomContext?.data?.roomContext
          : '' + '\n' + boardContext
            ? boardContext?.data?.boardContext
            : '' + '\n';
      return contextContent;
    } catch (error) {
      console.log('error', error);
      return;
    }

    // if (teamAiContextContent.teamContext) {
    //   contextContent += teamAiContextContent.teamContext + '\n';
    // }

    // if (roomAiContextContent.roomContext) {
    //   contextContent += roomAiContextContent.roomContext + '\n';
    // }

    // if (boardAiContextContent.boardContext) {
    //   contextContent += boardAiContextContent.boardContext + '\n';
    // }
  };

  handleVoiceChat = async (
    recordAudioFile,
    currentChatSession,
    contentOnTheBoard,
    abortController
  ) => {
    // TODO: 处理录音文件
    store.dispatch(handleSetShowAiChatLoading(true));
    let board = store.getState().board.board;
    let r2UploadPath = UtilityService.getInstance().getr2UploadPath(board);
    let audioLink = '';
    audioLink = await FileService.getInstance().uploadRecordToR2(
      r2UploadPath,
      recordAudioFile
    );

    let msgType = 'voice';
    let key = audioLink.replace('https://files.boardx.us/', '');
    let userChatId;
    const fileData = {
      fileUrl: audioLink
    };

    // userChatId = await server.call('ai.SendUserChat', 'record', currentChatSession, msgType, fileData);

    const userMsg = await server.call('ai.audioToTextOpenAi', key, userChatId);

    store.dispatch(handleSetShowAiChatLoading(false));
    return userMsg;
    // await AIService.getInstance().handleRequestAIChat(userMsg, abortController, contentOnTheBoard, 'audio');
    // setIsShowCancelTheAvaRequestBtn(false);
  };

  updateChatSession = async (data, sessionId) => {
    const response = await store.dispatch(
      AiAssistApi.endpoints.updateAIChatSession.initiate({
        aiChatSessionId: sessionId,
        updateData: data
      })
    );
    return response;
  };

  updateLocalChatSession = async (updateData, sessionId) => {
    const currentChatSession = store.getState().AIAssist.currentChatSession;
    const chatSessionList = store.getState().AIAssist.chatSessionList;

    const updatedCurrentChatSession = {
      ...currentChatSession,
      ...updateData
    };
    await store.dispatch(
      handleSetCurrentChatSession(updatedCurrentChatSession)
    );

    const newChatSessionList = chatSessionList.map(item =>
      item._id === sessionId ? { ...item, ...updateData } : item
    );
    store.dispatch(handleSetChatSessionList(newChatSessionList));
  };

  handleSaveChatSettings = async (
    gptModel,
    chatName,
    systemMessage,
    isIncludeBoardContent
  ) => {
    try {
      const currentChatSession = store.getState().AIAssist.currentChatSession;
      const chatSessionList = store.getState().AIAssist.chatSessionList;

      const updateData = {
        ...(chatName !== null ? { name: chatName } : {}),
        ...(gptModel !== null ? { gptModel: gptModel } : {}),
        ...(systemMessage !== null ? { systemMessage: systemMessage } : {}),
        ...(isIncludeBoardContent !== null
          ? { isIncludeBoardContent: isIncludeBoardContent }
          : {})
      };

      // Perform the API call to update the AI chat session
      const response = await this.updateChatSession(
        updateData,
        currentChatSession._id
      );

      // If the update is successful, then update the Redux store
      if (response.data) {
        this.updateLocalChatSession(updateData, currentChatSession._id);
      }
    } catch (error) {
      console.error('Error updating chat session:', error);
      // Handle the error appropriately here
    }
  };
}
