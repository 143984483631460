import React, { useState, useEffect } from "react";
import { handleSetCurrentChatSession, handleSetChatSessionList, handleSetCurrentChatAiPersonaData } from 'store/AIAssist';

import ListItem from "@mui/joy/ListItem";
import ListDivider from "@mui/joy/ListDivider";
import Typography from "@mui/joy/Typography";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemText from '@mui/material/ListItemText';
import {
    handleSetShowAiChatLoading
} from '../../../store/board';

//** Import Redux kit
import store, { RootState } from '../../../store';
import { useSelector, useDispatch } from 'react-redux';
import AIService from '../../../services/AIService';
import Input from '@mui/joy/Input';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import IconButton from '@mui/joy/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box, Tooltip } from "@mui/joy";
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ChatAIIcon20x20v2 from '../../../mui/icons/ChatAIIcon20x20v2';
import { useMediaQuery } from "@mui/material";
import List from "@mui/joy/List";
// Helper function to format the date for display remains unchanged
import MobileAvaChat from "./MobileAvaChat";
import avaIconImage from "mui/icons/avaIconImage";
const formatDate = (date) => {
    return new Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
    }).format(new Date(date));
};

// Helper function to determine the difference in days
const dayDifference = (date) => {
    const today = Date.now();
    const diffTime = Math.abs(date - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
};

// Updated preprocessList function with new categories
const preprocessList = (list) => {
    const sections = {
        Today: [],
        Yesterday: [],
        "2 days ago": [],
        "3 days ago": [],
        "4 days ago": [],
        "5 days ago": [],
        "6 days ago": [],
        "1 week ago": [],
        "2 weeks ago": [],
        "3 weeks ago": [],
        "4 weeks ago": [],
        "1 month ago": [],
        "2 months ago": [],
        Earlier: []
    };

    list.forEach((item) => {
        
        const itemDate = new Date(item.lastUpdatedTime || item.createdAt);
        const diffDays = dayDifference(itemDate);

        if (diffDays === 1) {
            sections.Today.push(item);
        } else if (diffDays === 2) {
            sections.Yesterday.push(item);
        } else if (diffDays < 7) {
            sections[`${diffDays} days ago`].push(item);
        } else if (diffDays < 14) {
            sections["1 week ago"].push(item);
        } else if (diffDays < 21) {
            sections["2 weeks ago"].push(item);
        } else if (diffDays < 28) {
            sections["3 weeks ago"].push(item);
        } else if (diffDays < 35) {
            sections["4 weeks ago"].push(item);
        } else if (diffDays < 60) {
            sections["1 month ago"].push(item);
        } else if (diffDays < 90) {
            sections["2 months ago"].push(item);
        } else {
            sections.Earlier.push(item);
        }
    });

    return sections;
};

const RenderList = ({ sections, setOpenMobileChat }) => {
    const listItems = [];

    // Helper function to add section headers
    const addSectionHeader = (header) => {
        listItems.push(
            <ListItem key={header}>
                <Typography level='body-xs'>{header}</Typography>
            </ListItem>,
            <ListDivider key={Math.random()} component="li" />
        );
    };

    // Dynamically add sections to the list based on the new grouping
    Object.entries(sections).forEach(([key, items]) => {
        //@ts-ignore
        if (items.length > 0) {
            addSectionHeader(key); // Add the section header based on the key
            //@ts-ignore
            items.forEach((item, index) => {
                listItems.push(<RenderListItem setOpenMobileChat={setOpenMobileChat} index={index} key={item._id} item={item} />);
            });
        }
    });

    return <List sx={{
        "--ListItem-radius": "8px",
        "--List-gap": 4,
        padding: '5px',
    }}>{listItems}</List>;
};


const RenderListItem = ({ item, index, setOpenMobileChat }) => {
    const [editMode, setEditMode] = useState(false);
    const [editedName, setEditedName] = useState(item.name);
    const isMobile = useMediaQuery("(max-width: 600px)");

    const chatSession = item;
    const currentChatSession: any = useSelector(
        (state: RootState) => state.AIAssist.currentChatSession
    );
    const orgInfo = useSelector((state: RootState) => state.org.orgInfo);
    const dispatch = useDispatch();
    const userInfo = useSelector((state: RootState) => state.user.userInfo);
    const handleClickChatSession = async (chatSession) => {

        if (isMobile) {
            setOpenMobileChat(true)
        }
        dispatch(handleSetShowAiChatLoading(false));
        AIService.getInstance().loadChatSession(chatSession);

    };

    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleNameClick = (e, chatSession) => {
        e.stopPropagation();
        setEditMode(true);
        setAnchorEl(null);
        AIService.getInstance().loadChatSession(chatSession);
    };

    const handleNameChange = (e) => {
        setEditedName(e.target.value);
    };

    const handleRenameClick = () => {
        setEditMode(true);
    };

    const handleDeleteClick = async (e, chatSession) => {
        e.stopPropagation();
        setAnchorEl(null);

        await AIService.getInstance().deleteChatSessionById(chatSession._id);

        // await AIService.getInstance().handleDeleteChatSession(chatSession._id);
    }

    const handleSave = async (e) => {
        console.log('handleSave')
        // Trigger save event here
        setEditMode(false);
        handleNameChange(e);
        // Update the chat session name with the edited name
        // chatSession.name = editedName;
        // Dispatch an action to update the chat session in the store
        await AIService.getInstance().handleSaveChatSettings(null, editedName, null, null);
        // dispatch(updateChatSession(chatSession));
    };

    return (
        <ListItem key={item._id} sx={{ height:'12px', width: isMobile ? '100vw' : '230px' }}>
            <ListItemButton
                onClick={e => handleClickChatSession(chatSession)}
                selected={chatSession._id === currentChatSession._id}
                sx={{
                    display: 'flex',
                    width: '100%',
                    '&:focus-visible': {
                        outline: 'none', // Removes the focus outline
                        '--joy-focus-thickness': '0px',
                        // Consider adding an alternative focus style here
                    },
                    '& .MuiListItemButton-root': {
                        outline: 'none',
                        '--joy-focus-thickness': '0px',
                    },
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                {editMode ? (
                    <input
                        type="text"
                        value={editedName}
                        autoFocus={true}
                        onChange={handleNameChange}
                        onBlur={handleSave}
                        style={{ width: '100%', height: '100%' }}
                        onClick={(e) => e.stopPropagation()}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSave(e);
                            }
                        }}
                    />
                ) : (
                    <Box sx={{
                        px: '5px', display: 'flex',
                        flexDirection: 'row', flexWrap: 'nowrap',
                        justifyContent: 'space-between',
                        flexGrow: 1, alignItems: 'center'
                    }}>
                        <Box sx={{
                            display: 'flex', flexDirection: 'row',
                            flexWrap: 'nowrap', justifyContent: 'flex-start',
                            flexGrow: 1, alignItems: 'center'
                        }}>
                            <ListItemDecorator>
                                {chatSession &&
                                    chatSession.promptPersonaData &&
                                    chatSession.promptPersonaData.icon &&
                                    chatSession.promptPersonaData.icon.length > 0 ? (
                                    <img
                                        src={chatSession.promptPersonaData.icon}
                                        style={{ width: '20px', height: '20px',backgroundColor:'white', borderRadius: '50%' }}
                                    />
                                ) : (
                                    <img
                                        src={'https://files.boardx.us/ChatAI.png'}
                                        style={{ width: '20px', height: '20px' , backgroundColor:'white', borderRadius: '50%' }}
                                    />


                                )}
                            </ListItemDecorator>
                            <Tooltip title={chatSession.name} placement="top">
                                <Typography
                                    level="body-xs"
                                    sx={{
                                        overflow: 'hidden', width: '150px',
                                        textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                                    }}
                                    onDoubleClick={e => handleNameClick(e, chatSession)}
                                >
                                    {chatSession.name}
                                </Typography>
                            </Tooltip>
                        </Box>


                        <IconButton onClick={e => handleMenuOpen(e)}>
                            <MoreVertOutlinedIcon style={{ strokeWidth:"var(--joy-lineHeight-sm)" }}/>
                        </IconButton>

                        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                            <MenuItem onClick={(e) => handleNameClick(e, chatSession)}>Rename</MenuItem>
                            <MenuItem onClick={(e) => handleDeleteClick(e, chatSession)}>Delete</MenuItem>
                        </Menu>
                    </Box>
                )}
            </ListItemButton>
        </ListItem>
    );
};



export default function ChatSessionListByDate({ sessionList, setOpenMobileChat }) {

    
    const sortedList = [...sessionList].sort((a, b) => {
        const aTime = a.lastUpdatedTime || a.createdAt;
        const bTime = b.lastUpdatedTime || b.createdAt;
        return bTime - aTime;
    });
    const sections = preprocessList(sortedList);


    return (<RenderList sections={sections} setOpenMobileChat={setOpenMobileChat} />);

}
