//@ts-nocheck
import Box from '@mui/joy/Box';
import 'katex/dist/katex.min.css'; // Import KaTeX stylesheet
import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';

import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';

import { CodeBlock } from './CodeBlock';

function ChatItemMarkdown({ item, index, dialogFullScreen, chatType }) {
  const isMobile = Boardx.Util.isMobile();
  const markdown = item.message;

  const getMaxWidth = () => {
    if (isMobile) {
      return 'calc(100vw-100px)';
    }
    if (chatType === 'agent') {
      return '680px';
    }
    return '1024px';
  };

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'flex-end',
      '.markdownStyle': {
        maxWidth: getMaxWidth(),
        padding: '10px',
        fontSize: '14px',
        wordBreak: 'normal',
        boxShadow: 'var(--joy-shadow-md)',
        borderRadius: '0px 4px 4px 4px',
        boxSizing: 'border-box',
        minWidth: '200px',
        '& p:first-of-type': {
          margin: 0
        },
        '& p': {
          margin: 0
        },
        '& ol': {
          margin: '15px',
          paddingLeft: '25px',
          wordWrap: 'break-word',
          maxWidth: getMaxWidth(),
          overflow: 'auto',
        }
      },
      minWidth: '300px',
    }}>
      <ReactMarkdown
        children={markdown}
        components={{
          code({ node, inline, className, children, ...props }) {
            if (children.length) {
              if (children[0] === '▍') {
                return <span className="animate-pulse cursor-default mt-1">▍</span>;
              }

              children[0] = (children[0] as string).replace("▍", "▍");
            }

            const match = /language-(\w+)/.exec(className || '');
            const isMarkdown = match && match[1] === 'markdown';

            // If isMarkdown is true, skip rendering as code or CodeBlock
            if (isMarkdown || item.type === 'user') {
              return <div style={{ whiteSpace: 'break-spaces' }}  >{children}</div>;
            }

            return !inline && item.type !== 'user' && !isMarkdown ? (
              <CodeBlock
                key={Math.random()}
                language={(match && match[1]) || ''}
                value={String(children).replace(/\n$/, '')}
                {...props}
              />
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            );
          },
          img({ src }) {
            return (
              <img
                src={src}
                style={{ maxWidth: '100%', height: 'auto' }}
              />
            );
          },
          table({ children }) {
            return (
              <table style={{ borderCollapse: 'collapse', border: '1px solid black', padding: '12px', borderColor: '#eeeeee' }}>
                {children}
              </table>
            );
          },

          th({ children }) {
            return (
              <th style={{ wordBreak: 'break-word', border: '1px solid black', padding: '12px', color: 'white', borderColor: '#eeeeee' }}>
                {children}
              </th>
            );
          },

          td({ children }) {
            return (
              <td style={{ wordBreak: 'break-word', border: '1px solid black', padding: '12px', borderColor: '#eeeeee' }}>
                {children}
              </td>
            );
          }
        }}
        remarkPlugins={[
          remarkMath,
          remarkGfm,


        ]}
        rehypePlugins={[
          [rehypeKatex, { strict: false }]
        ]}
        className={'markdownStyle'}
      />
    </Box>
  );
}

export default ChatItemMarkdown;