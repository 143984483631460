import React, { useState } from 'react';
import {  IconButton } from '@mui/joy';
import CloseIcon from '@mui/icons-material/Close';
import AIAssistantAvaChatPage from 'pages/aiAssistantPage/AIAssistantAvaChatPage';
import { Sheet, Modal, ModalDialog, ModalClose, DialogContent } from '@mui/joy';
import {IconArrowLeft} from '@tabler/icons-react';

export default function MobileAvaChat({ setOpenMobileChat }) {
    const [open, setOpen] = useState(true);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenMobileChat(false);
    };

    return (
        <Modal   open={open} onClose={handleClose}>
            <ModalDialog  sx={{"--Card-padding":'0px', paddingTop: '10px'}} layout='fullscreen'>
                <IconButton onClick={handleClose} sx={{position:'absolute', top: '25px', left: '5px', zIndex: 100}}>
                    <IconArrowLeft style={{ strokeWidth:"var(--joy-lineHeight-sm)" }}/>
                </IconButton>
                <DialogContent sx={{overflow: 'hidden'}}>
                    <AIAssistantAvaChatPage />
                </DialogContent>
            </ModalDialog>
        </Modal>
    );
}
