//** Import react
import React, { useEffect, useState } from 'react';
import ReactMarkdown, { Options } from 'react-markdown';

// import rehypeMathjax from 'rehype-mathjax';
//** Import i18n
import { useTranslation } from 'react-i18next';
import { CodeBlock } from './CodeBlock';
//** Import Redux kit
import store, { RootState } from 'store';
import { useSelector, useDispatch } from 'react-redux';
import { useUpdateAIChatMsgInfoMutation } from 'redux/AiAssistApiSlice';
import { handleSetAiToolBar } from 'store/domArea';

import IconButton from '@mui/joy/IconButton';
import Box from '@mui/joy/Box';
import Avatar from '@mui/joy/Avatar';

//** Import MUI Icons
import ChatItemAudioPlayer from './ChatItemAudioPlayer';
import ChatItemVoice from './ChatItemVoice';
import ChatItemMenu from './ChatItemMenu';
import ChatItemFile from './ChatItemFile';
import ChatItemMarkdown from './ChatItemMarkdown';
import ChatItemFooter from './ChatItemFooter';


function ChatItem({ item, index, dialogFullScreen, chatType, avatarImage }) {
  const { t } = useTranslation();
  const [openMoreMenu, setOpenMoreMenu] = React.useState(false);
  const [openVoiceMenu, setOpenVoiceMenu] = React.useState(false);
  const [updateAIChatMsgInfo] = useUpdateAIChatMsgInfoMutation();

  const bgColor= Boardx.Util.getAvatarColor(store.getState().user.userInfo.name || '');
  const fontColor= Boardx.Util.invertColor(bgColor, true);
  return (
    <Box sx={{    minWidth: '300px', }}>
      {item.type === 'user' && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'column',
            alignItems: 'flex-start',
            mt: '14px',
            minWidth: '300px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'flex-start', pl: '8px', maxWidth: '95vw',    minWidth: '300px', }}>
            {/* 头像 */}
            <Avatar
              key={store.getState().user.userInfo.name}
              alt={
                store.getState().user.userInfo.name &&
                store.getState().user.userInfo.name.toUpperCase()
              }
              sx={{
                bgcolor:  bgColor,
                color: fontColor,
                width: '24px',
                height: '24px',
                orderRadius: '50%',
         
              }}
              // alt={user.username.toUpperCase()}
              style={{
                marginRight: '10px',
              }}
              className="avatar-img"
              src={store.getState().user.userInfo.avatar}
            >
              {store.getState().user.userInfo.name?.toUpperCase().charAt(0)}
            </Avatar>

            {/* 消息 */}
            {item.msgType && item.msgType === 'voice' && (
              <ChatItemVoice index={index} fileUrl={item.fileUrl} message={item.message} openMoreMenu={openMoreMenu} setOpenMoreMenu={setOpenMoreMenu} openVoiceMenu={openVoiceMenu} setOpenVoiceMenu={setOpenVoiceMenu} />
            )}

            {item.msgType && item.msgType === 'file' && (
              <ChatItemFile item={item} index={index} />
            )}

            {!item.msgType && (
              <ChatItemMarkdown item={item} index={index} dialogFullScreen={dialogFullScreen} chatType={chatType}  />
            )}
          </Box>

          <ChatItemFooter item={item} includeFeedback={false} chatType={chatType} />

          {/* <ChatItemMenu chatType={chatType} item={item} index={index} openMoreMenu={openMoreMenu} setOpenMoreMenu={setOpenMoreMenu} /> */}
        </Box>
      )}

      {item.type !== 'user' && 
        item.message !== '' 
        && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: 'column',
              alignItems: 'flex-start',
              mt: '12px',
              minWidth: '300px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                pl: '8px',
                minWidth: '300px',
              }}
            >
              <Avatar
                style={{
                  width: '24px',
                  height: '24px',
                  marginRight: '10px',
                  backgroundColor: 'white',
                  boxShadow: 'var(--joy-shadow-md)',
                  borderRadius: '20px'
                }}
                alt="ChatAIAvatarIcon"
                id="avatar-AI-img"
              >
                {/* <ChatAIIcon24x24v3 /> */}
                <img
                  style={{ width: '24px', height: '24px', borderRadius: '50%'}}
                  src= { avatarImage? avatarImage : "https://files.boardx.us/ChatAI.png"} 
                /> 
              </Avatar>

              {item.msgType && item.msgType === 'voice' && (
                <ChatItemAudioPlayer
                  audioSrc={item.fileUrl}
                  ChatAIVersions="desktopChatAI"
                />
              )}

              {item.msgType !== 'voice' && (
                <ChatItemMarkdown item={item} index={index} dialogFullScreen={dialogFullScreen} chatType={chatType}    />
              )}
            </Box>

{}
            <ChatItemFooter item={item} includeFeedback={true} chatType={chatType}/>

            {/* <ChatItemMenu chatType={chatType} item={item} index={index} openMoreMenu={openMoreMenu} setOpenMoreMenu={setOpenMoreMenu} /> */}
          </Box>
        )
      }
    </Box>
  );
}

const ChatItemMemo = React.memo(ChatItem);

export default ChatItemMemo;
