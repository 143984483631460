import React from 'react';
import Box from '@mui/joy/Box';
import { Outlet } from 'react-router-dom';
import { Icon, useMediaQuery } from '@mui/material';
import { List, ListItem, ListItemButton, Sheet } from '@mui/joy';
import Typography from '@mui/joy/Typography';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCheckActionPermissionMutation } from 'redux/PermissionApiSlice';
import { handleSetCurrentApp } from 'store/system';
import store from 'store';
import {IconMathFunction, IconRobot,IconPhotoAi,IconBoxModel, IconUsers,  IconLockAccess } from '@tabler/icons-react';


import {
    BoardService,
    UserService
} from 'services';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const drawerWidth = 240;

export default function AdminConsoleLayout() {
    const isMobile = useMediaQuery('(max-width: 600px)');
    const currentApp = useSelector((state: RootState) => state.system.currentApp);

    useEffect(() => {

        store.dispatch(handleSetCurrentApp('console'));
    }, []);

    return (
        <Box id='adminconsole' sx={{ 
            display: 'flex', 
            width: '100%',
            height: '100%', 
            flexGrow: 1, 
            flexShrink: 1, 
            flexDirection: 'row', 
            justifyContent: 'space-between' }}>
            <Box sx={{ width: isMobile ? '100%' : '240px' , ml: '5px'}}>
                <AdminConsoleMenu />
            </Box>
            <Box sx={{ width: '100vw', display: isMobile ? 'none' : 'flex', flexGrow: 1, flexShrink: 1 }}>
                <Outlet   />
            </Box>
        </Box>
    )
}





function AdminConsoleMenu() {



    const navigate = useNavigate();
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(true);
    const [value, setValue] = React.useState('Task Prompt');
    const [loading, setLoading] = React.useState(true);


    const userInfo = store.getState().user.userInfo;



    const [handleCheckActionPermission] = useCheckActionPermissionMutation();



    const checkActionPermission = async permissionName => {
        let data = {
            permissionName: permissionName,
            roles: JSON.parse(localStorage.getItem('userRoles'))
        };
        return await handleCheckActionPermission(data);
    };


    const handleClickTogglePage = async item => {
        await checkActionPermission(`Enter ${item.name}`).then((data) => {
            //@ts-ignore
            if (data.data) {
                setValue(item.name);
                navigate(item.url);
            } else {
                alert(t('adminPage.alertTips'));
            }
        });
    };

    useEffect(() => {
        BoardService.getInstance();
        UserService.getInstance();
        const token = localStorage.getItem('token');
        if (!store.getState().user.userInfo.userId) {


            loadUser();
            async function loadUser() {
                let user = await server.login({ resume: token });
                const userId = user.id;
                await server.call('saveLoginToken', token, userId);
                if (user && user.token) {
                    // store.dispatch(handleSetInitDone(true));
                    let user = await server.call('getUserInfo');
                    UserService.getInstance().saveStore(user);

                }


                // server.call('loginWithToken', token).then(async res => {
                //   if (res && res.status) {
                //     UserService.getInstance().saveStore(res.user);
                //   }
                // });
            }
        }
    }, []);


    const checkIndexPermission = () => {
        checkActionPermission('Enter Task Prompt').then((data) => {
            //@ts-ignore
            if (data.data) {
                const element = document.getElementById('indexItem0');
                if (element) {
                    element.style.display = 'block';
                }
            }
        });
        checkActionPermission('Enter Agent Prompt').then((data) => {
            //@ts-ignore
            if (data.data) {
                const element = document.getElementById('indexItem1');
                if (element) {
                    element.style.display = 'block';
                }
            }
        });
        checkActionPermission('Enter Image Prompt').then((data) => {
            //@ts-ignore
            if (data.data) {
                const element = document.getElementById('indexItem2');
                if (element) {
                    element.style.display = 'block';
                }
            }
        });
        checkActionPermission('Enter AI Model').then((data) => {
            //@ts-ignore
            if (data.data) {
                const element = document.getElementById('indexItem3');
                if (element) {
                    element.style.display = 'block';
                }
            }
        });
        checkActionPermission('Enter Operation').then((data) => {
            //@ts-ignore
            if (data.data) {
                const element = document.getElementById('indexItem4');
                if (element) {
                    element.style.display = 'block';
                }
            }
        });
        checkActionPermission('Enter Users').then((data) => {
            //@ts-ignore
            if (data.data) {
                const element = document.getElementById('indexItem5');
                if (element) {
                    element.style.display = 'block';
                }
            }
        });
        checkActionPermission('Enter Referral').then((data) => {
            //@ts-ignore
            if (data.data) {
                const element = document.getElementById('indexItem6');
                if (element) {
                    element.style.display = 'block';
                }
            }
        });
        checkActionPermission('Enter Permission').then((data) => {
            //@ts-ignore
            if (data.data) {
                const element = document.getElementById('indexItem7');
                if (element) {
                    element.style.display = 'block';
                }
            }
        });
    };

    useEffect(() => {
        async function deal() {
            checkActionPermission('EnterAdminDashBoard').then((data) => {

                //@ts-ignore
                if (!data.data) {
                    navigate('/recent');
                } else {
                    setLoading(false);
                }
            });
            await checkIndexPermission();
        }
        deal();
    }, []);


    return (

        <Sheet
            sx={{
                width: drawerWidth,
                height: '100%',
                flexShrink: 0,
                backgroundColor: 'var(--joy-palette-background-body)',
            }}
        >

            <List sx={{
                height: '100%', "--ListItem-radius": "8px",
                "--List-gap": 4,
                padding: '5px',
                backgroundColor: 'var(--joy-palette-background-body)',
            }}>
                <ListItem
                    id={'indexItem0'}
                    key={0}
                    onClick={() => handleClickTogglePage({ name: 'Task Prompt', url: '/admin/prompt' })}
                >
                    <ListItemButton
                        selected={value === 'Task Prompt'}
                        
                    >
                        <IconMathFunction size={20} />
                        <Typography level="body-md" sx={{ color: 'text.primary' }}>Task Prompt</Typography>
                    </ListItemButton>
                </ListItem>

                <ListItem
                    id={'indexItem1'}
                    key={1}
                    onClick={() => handleClickTogglePage({ name: 'Agent Prompt', url: '/admin/agent' })}
                >
                    <ListItemButton
                        selected={value === 'Agent Prompt'}
                    >
                        <IconRobot size={20} />
                        <Typography level="body-md" sx={{ color: 'text.primary' }}>Agent Prompt</Typography>
                    </ListItemButton>
                </ListItem>

                <ListItem
                    id={'indexItem2'}
                    key={2}
                    onClick={() => handleClickTogglePage({ name: 'Image Prompt', url: '/admin/imageprompt' })}
                >
                    <ListItemButton
                        selected={value === 'Image Prompt'}
                    >
                        <IconPhotoAi size={20} />
                        <Typography level="body-md" sx={{ color: 'text.primary' }}>Image Prompt</Typography>
                    </ListItemButton>
                </ListItem>

                <ListItem
                    id={'indexItem3'}
                    key={3}
                    onClick={() => handleClickTogglePage({ name: 'AI Model', url: '/admin/model' })}
                >
                    <ListItemButton
                        selected={value === 'AI Model'}
                    >
                        <IconBoxModel size={20} />
                        <Typography level="body-md" sx={{ color: 'text.primary' }}>AI Model</Typography>
                    </ListItemButton>
                </ListItem>

                <ListItem
                    id={'indexItem4'}
                    key={4}
                    onClick={() => handleClickTogglePage({ name: 'Users', url: '/admin/users' })}
                >
                    <ListItemButton
                        selected={value === 'Users'}
                    >
                        <IconUsers size={20} />
                        <Typography level="body-md" sx={{ color: 'text.primary' }}>Users</Typography>
                    </ListItemButton>
                </ListItem>

                <ListItem
                    id={'indexItem5'}
                    key={5}
                    onClick={() => handleClickTogglePage({ name: 'Permission', url: '/admin/permission' })}
                >
                    <ListItemButton
                        selected={value === 'Permission'}
                    >
                        <IconLockAccess size={20} />
                        <Typography level="body-md" sx={{ color: 'text.primary' }}>Permission</Typography>
                    </ListItemButton>
                </ListItem>
            </List>
        </Sheet>
    );
}
