import React, { useEffect, useState } from "react";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Box from "@mui/joy/Box";
import { Typography } from "@mui/joy";
// Importing useSpring from react-spring for animations
import { useSpring, animated } from '@react-spring/web';

const AudioVisualizer = ({ setStop, stream }) => {
  const [shapeProps, setShapeProps] = useState({
    borderRadius: "50%", // Start as a circle
    scale: 1,
  });
  const [time, setTime] = useState("00:00");
  const [timerId, setTimerId] = useState(null);
  const [open, setOpen] = useState(true);

  const startTimer = () => {
    const startTime = Date.now();
    setTimerId(setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      const minutes = Math.floor(elapsedTime / 60000);
      const seconds = Math.floor((elapsedTime % 60000) / 1000);
      const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      setTime(formattedTime);
    }, 1000));
  };

  const endTimer = () => {
    clearInterval(timerId);
    setTime("00:00");
  };

  // react-spring animation hook
  const animationProps = useSpring({
    to: {
      transform: `scale(${shapeProps.scale})`,
    },
    from: {
      transform: "scale(1)",
    },
    config: {
      tension: 170,
      friction: 26,
    },
  });

  useEffect(() => {
    let animationFrameId;
    startTimer();
    const handleAudio = async () => {
      try {
        //@ts-ignore
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const source = audioContext.createMediaStreamSource(stream);
        const analyser = audioContext.createAnalyser();

        source.connect(analyser);
        analyser.fftSize = 512;
        const dataArray = new Uint8Array(analyser.frequencyBinCount);

        const animate = () => {
          analyser.getByteFrequencyData(dataArray);
          let volume =
            dataArray.reduce((acc, val) => acc + val, 0) / dataArray.length;

          // Adjust the shape based on volume
          let newScale = Math.max(1, volume / 20); // Scale based on volume
          let borderRadius = volume > 128 ? "0%" : "50%"; // Morph shape based on volume

          setShapeProps({
            borderRadius: borderRadius,
            scale: newScale,
          });

          animationFrameId = requestAnimationFrame(animate);
        };

        animate();
      } catch (err) {
        console.error("Error accessing the microphone", err);
      }
    };

    handleAudio();

    return () => {
      cancelAnimationFrame(animationFrameId);
      endTimer();
    };
  }, [stream]);

  return (
    <Modal open={open}>
      <ModalDialog
        layout='center'
        sx={{
          backgroundColor: "var(--joy-palette-background-surface)",
          boxShadow: "var(--joy-shadow-md)",
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          padding: '20px',
          borderRadius: '10px'
        }}
      >
        <Typography level='body-md'>{time}</Typography>

        <Box
          style={{
            width: "150px",
            height: "150px",
            display: "flex",
            flexDirection: "column",
            gap: '10px',
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "var(--joy-palette-background-paper)",
          }}
        >
          <Box sx={{
            width: "100px",
            height: "100px", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
          }}>

            <animated.div
              style={{
                ...animationProps,
                width: 30,
                height: 30,
                backgroundColor: "#F21D6B",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: shapeProps.borderRadius,
              }}
            ></animated.div>
          </Box>

          <Button variant="plain" color="primary" fullWidth onClick={setStop} sx={{ flexShrink: 0, flexGrow: 0 }}>
            Stop
          </Button>
        </Box>
      </ModalDialog>
    </Modal>
  );
};

export default AudioVisualizer;
