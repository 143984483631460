//** Import react
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { useEffect, useState, useRef } from 'react';

//** Import i18n
import { useTranslation } from 'react-i18next';

//** Import Redux kit
import {
  useAddUserTagsMutation,
  useGetUserTagsQuery
} from '../../../redux/UserAPISlice';

//** Import Mui
import Typography from '@mui/joy/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/joy/Divider';
import UserPhoto from '../../../mui/icons/UserPhoto';
import Chip from '@mui/material/Chip';
import UserManagementAddTagsIcon from '../../../mui/icons/UserManagementAddTagsIcon';
import UserManagementManageTagsIcon from '../../../mui/icons/UserManagementManageTagsIcon'

import store from '../../../store';
import server from '../../../startup/serverConnect';

function AddUserTags(props) {

  const { t } = useTranslation();
  const { openTagsUserPopper, setOpenTagsUserPopper } = props;
  const [openAddNewTag, setOpenAddNewTag] = useState(false);
  const [userList, setUserList] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const [currentSelectUser, setCurrentSelectUser] = useState([]);
  const [data, setData] = useState({
    filter: null,
    start: 0,
    limit: 0
  });
  const userNameRef: any = useRef('');
  const tagNameRef: any = useRef('');

  const [addUserTags] = useAddUserTagsMutation();
  const { data: userTags = [] } = useGetUserTagsQuery(undefined);

  const handleClickOpenAddNewTag = () => {
    setOpenAddNewTag(true);
    setOpenTagsUserPopper(false);
  };

  const handleSearch = e => {
    const keyword = e.target.value;
    let newData = { ...data, filter: keyword };
    setData(newData);
  };

  useEffect(() => {
    handleGetUserList(data);
  }, [data]);

  const handleGetUserList = async data => {
    server.call('getUserList', data).then(res => {
      let list = [];
      res?.map(t => {
        t.id = t._id;
        t.email = t.emails[0].address;
        t.avatar = t.head_url ? t.head_url : null;
        list.push(t);
      });
      setUserList(list);
    }).catch(err => {
      Boardx.Util.Msg.warning(err);
    });

  };

  const handleAddUser = user => {
    setCurrentSelectUser([...currentSelectUser, user]);
    setUserList(userList.filter(item => item.id !== user.id));
  };

  const handleDelete = user => {
    setCurrentSelectUser(currentSelectUser.filter(item => item.id !== user.id));
    setUserList([...userList, user]);
  };

  const handleCloseAddNewTagDialog = () => {
    setOpenAddNewTag(!openAddNewTag);
    setIsSearch(false);
    setUserList([]);
    setCurrentSelectUser([]);
    setData({
      filter: null,
      start: 0,
      limit: 0
    });
  };

  const handleClickSave = async () => {
    const tagName = tagNameRef.current.value.trim();
    if (tagName === '') {
      return Boardx.Util.Msg.info(t('adminPage.tagNameIsRequired'));
    }

    let existingTags = [];
    userTags.map(item => {
      existingTags.push(item.tagName);
    });
    if (existingTags.includes(tagName)) {
      return Boardx.Util.Msg.warning(t('adminPage.tagAlreadyExists'));
    }

    if (currentSelectUser.length === 0) {
      return Boardx.Util.Msg.warning(t('adminPage.userToAddToTheTag'));
    }

    const data = {
      users: currentSelectUser,
      createUserId: store.getState().user.userInfo.userId,
      createUserName: store.getState().user.userInfo.userName,
      createTime: new Date().getTime(),
      tagName: tagName
    };

    server.call('updateUserProfileTags',
      currentSelectUser,
      tagName).then(async res => {
        Boardx.Util.Msg.warning(t('adminPage.updateUserTagSuccess'));
        await addUserTags(data);
        Boardx.Util.Msg.warning(t('adminPage.addUserTagSuccess'));
        handleCloseAddNewTagDialog();
      }).catch(err => {
        Boardx.Util.Msg.warning(err.reason);
      });

  };

  return (
    <Box >
      <Menu
        open={openTagsUserPopper}
        anchorEl={document.getElementById('tagsUserBtn')}
        onClose={() => setOpenTagsUserPopper(!openTagsUserPopper)}
        sx={{
          '.menuPaper': {
            background: '#F4F5FA',
            boxShadow:
              '0px 5px 5px -3px rgba(58, 53, 65, 0.2), 0px 8px 10px 1px rgba(58, 53, 65, 0.14), 0px 3px 14px 2px rgba(58, 53, 65, 0.12)',
            borderRadius: '6px'
          },

          '.menuItemGutters': {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0.15px',

          }

        }}

      >
        <MenuItem
          onClick={handleClickOpenAddNewTag}
        >
          <UserManagementAddTagsIcon />
          Add new tag
        </MenuItem>
        <MenuItem  >
          <UserManagementManageTagsIcon />
          Manage tags
        </MenuItem>
      </Menu>

      <Dialog
        open={openAddNewTag}
        onClose={handleCloseAddNewTagDialog}
        sx={{
          '.paperDialog': {
            background: 'var(--joy-palette-background-surface)',
            boxShadow: 'var(--joy-shadow-md)',
            borderRadius: '6px',
            width: '460px',
            height: '540px',
            padding: '40px 24px 20px',
            position: 'relative'
          },

          '.dialogTitleRoot': {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '24px',
            lineHeight: '133.4%',
            textAlign: 'center',

            padding: 0,
            marginBottom: '8px'
          }
        }}

        classes={{ paper: 'paperDialog' }}
      >
        <DialogTitle
          classes={{ root: 'dialogTitleRoot' }}>
          Add New Tag
        </DialogTitle>
        <Box>
          <Box>
            <Typography level='title-md' >Tag Name</Typography>

            <TextField
              sx={{
                '.textFieldRoot': {
                  '& .MuiInputBase-input': {
                    padding: '9px 17px'
                  },

                },
              }}
              classes={{ root: 'textFieldRoot' }}
              fullWidth
              id="name"
              placeholder="name"
              type="text"
              variant="outlined"
              inputRef={tagNameRef}
            />
          </Box>
          <Box sx={{ mt: '16px' }}>
            <Typography level='title-md'>Users</Typography>
            {isSearch ? (
              <Box sx={{
                width: '100%',
                height: 'auto',
                border: '1px solid rgba(0, 0, 0, 0.16)',
                borderRadius: '2px',
                display: 'flex',
                flexWrap: 'wrap',
                maxHeight: '80px',
                overflowX: 'hidden',
                overflowY: 'scroll'
              }}>
                {currentSelectUser?.map(user => (
                  <Chip
                    icon={
                      <UserPhoto style={{ width: '14px', height: '14px' }} />
                    }
                    sx={{
                      '.sizeMedium': {
                        margin: '6px 0px 0px 6px',
                        height: '24px'
                      },
                      '.chipLabel': {
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '13px',
                        lineHeight: '18px',
                        letterSpacing: '0.16px',
                      },
                      '.chipIconMedium': {
                        width: '14px',
                        height: '14px'
                      }
                    }}
                    classes={{
                      sizeMedium: 'sizeMedium',
                      label: 'chipLabel',
                      iconMedium: 'chipIconMedium'
                    }}
                    key={user._id}
                    label={user.name}
                    onDelete={handleDelete.bind(this, user)}
                  />
                ))}
                <TextField
                  autoFocus
                  defaultValue={data?.filter}
                  fullWidth
                  id="fullname"
                  sx={{
                    '.textFieldRoot': {
                      '& .MuiInputBase-input': {
                        padding: '9px 17px'
                      },

                    },

                    flex: 1,
                    height: '38px',
                    margin: 0,
                    overflow: 'hidden',
                    minWidth: '200px',
                    '& .MuiInputBase-fullWidth': {
                      width: '100%'
                    },
                    '& .MuiInputBase-input': {
                      width: '100%',
                      height: '38px',
                      padding: '3px 3px 3px 12px',
                      boxSizing: 'border-box'
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 0
                    }
                  }}
                  classes={{ root: 'textFieldRoot' }}

                  inputRef={userNameRef}
                  placeholder="Search"
                  variant="outlined"
                  type="text"
                  onKeyDown={handleSearch}
                />
              </Box>
            ) : (
              <Button
                onClick={() => setIsSearch(!isSearch)}
                sx={{
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: '13px',
                  lineHeight: '22px',
                  letterSpacing: '0.46px',
                  textTransform: 'capitalize',

                  padding: '0px'
                }}
                variant="plain"
              >
                Add Users
              </Button>
            )}
          </Box>
          <Box sx={{ mt: '10px' }}>
            <Divider />
            <Box
              sx={{
                background: '#F9FAFC',
                maxHeight: '200px',
                overflowX: 'hidden',
                overflowY: 'scroll'
              }}
            >
              {userList?.slice(0, 20).map(item => (
                <MenuItem key={item.id} onClick={() => handleAddUser(item)}>
                  <UserPhoto />
                  <Typography>{item.name}</Typography>
                </MenuItem>
              ))}
            </Box>
          </Box>
          <Box sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'absolute',
            bottom: '20px',
            right: '24px'
          }}>
            <Button
              variant="outlined"
              onClick={handleCloseAddNewTagDialog}
            >
              Cancel
            </Button>
            <Button
              onClick={handleClickSave}
              variant="solid"
              sx={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '13px',
                lineHeight: '22px',
                letterSpacing: '0.46px',
                textTransform: 'capitalize',

                boxShadow: 'var(--joy-shadow-md)',
                borderRadius: '5px',
                background: '#F21D6B !important',
                marginLeft: '16px'
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
}

export default AddUserTags;
