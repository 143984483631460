//** Import react
import React, { useRef, Component, useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';

//** Import i18n
import { useTranslation } from 'react-i18next';
import store, { RootState } from '../../../store';
import { useSelector, useDispatch } from 'react-redux';

import TextField from '@mui/material/TextField';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import server from '../../../startup/serverConnect';

export default function AIAssistantImagesPreview(props) {
  const currentRowData = useSelector(
    (state: RootState) => state.AIAssist.currentAdminAiImagePromptData
  );
  const valueRefCategory: any = useRef('');
  const [previewImageUrl, setPreviewImageUrl] = useState('');
  const [loadingExecute, setLoadingExecute] = useState(false);

  function dataURItoBlob(dataURI) {
    // 将 base64 数据去除开头部分，只保留数据部分
    const byteString = atob(dataURI);
    // 构造 Uint8Array 数组，将每个字符转为 ASCII 码对应的数字
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    // 返回 Blob 对象
    return new Blob([ab], { type: 'image/png' });
  }

  const handleExecutePreview = () => {
    const commandData = {
      ...currentRowData,
      command: currentRowData.command,
      targetContent: valueRefCategory.current.value
    };
    const prompt = commandData.command.replace(
      '{input}',
      commandData.targetContent
    );
    setLoadingExecute(true);
    const user = store.getState().user.userInfo;

    server
      .call('ai.textToImage', commandData, prompt, '', user)
      .then(data => {
        const file = new File(
          [dataURItoBlob(data.image[0].base64)],
          'image.png',
          {
            type: 'image/png'
          }
        );
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (e) {
          const url: any = e.target.result;
          setPreviewImageUrl(url);
          setLoadingExecute(false);
        };
      })
      .catch(e => {
        Boardx.Util.Msg.warning(e.error);
        setLoadingExecute(false);
        return;
      });
  };

  return (
    <Box>
      {/* target content */}
      <Box sx={{ marginTop: '12px' }}>
        <Typography sx={{
          fontWeight: 600,
          fontSize: '12px',
          lineHeight: '15px',
          marginBottom: '6px'
        }} variant="plain">
          TARGET CONTENT
        </Typography>
        <TextField
          fullWidth
          autoFocus
   
  
          inputRef={valueRefCategory}
          id="targetContent"
          placeholder="Content"
          type="text"
          variant="outlined"
        />
      </Box>
      <Box
        sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '12px' }}
      >
        <Button
          loading={loadingExecute}
       
          onClick={handleExecutePreview}
          variant="outlined"
        >
          Execute
        </Button>
      </Box>
      <Box sx={{ marginTop: '12px' }}>
        <Typography sx={{
          fontWeight: 600,
          fontSize: '12px',
          lineHeight: '15px',
          marginBottom: '6px'
        }} variant="plain">
          RESULTS
        </Typography>
        <Box
          sx={{
            borderRadius: '3px',
            width: '358px',
            height: '316px',
            overflow: 'hidden',
            marginLeft: '12px',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat'
          }}
          style={{
            backgroundImage: `url(${previewImageUrl})`
          }}
        ></Box>
      </Box>
    </Box>
  );
}
