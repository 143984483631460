//** Import react
import React, { useEffect, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { MenuItem, Select, useMediaQuery } from '@mui/material';
//** Import i18n
import { useTranslation } from 'react-i18next';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/joy/IconButton';
//** Import Redux kit
import store, { RootState } from '../../store';
import { useSelector, useDispatch } from 'react-redux';
import { handleSetAiToolBar } from '../../store/domArea';
import {
  useGetAIChatSessionListQuery,
} from '../../redux/AiAssistApiSlice';

import AIService from '../../services/AIService';

//** Import Material UI
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';

//**Import Services
import { BoardService, UtilityService, SysService } from '../../services';

//**Import Components
import ChatDialogContent from '../../components/boardChatAI/ChatAI/ChatDialogContent';
import ChatAISettings from '../../components/boardChatAI/ChatAI/ChatAISettings';
import AIAssistanChatRecordList from './AIAssistanChatRecordList';
import AIModelSelectComponent from '../../components/boardChatAI/AIModelSelectComponent';

import ChatAgentComponent from 'components/boardChatAI/agent/ChatAgentComponent';

import SendMsgForm from 'components/boardChatAI/ChatAI/SendMsgForm';
import ChatShare from 'components/boardChatAI/ChatAI/ChatShare';
import { IconSettings, IconPrinter } from '@tabler/icons-react';
import { ChatHeaderMenu } from 'components/boardChatAI/ChatAI/ChatHeaderMenu';

function AIAssistantAvaChatPage(props) {
  const { chatType } = props;
  const dispatch = useDispatch();

  const location = useLocation();
  const { t } = useTranslation();
  const [openChatAISettings, setOpenChatAISettings] = useState(false);
  const [checkCreditsIsEnoughResult, setCheckCreditsIsEnoughResult] =
    useState(null);

  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const isMobile = useMediaQuery('(max-width:600px)');
  const board = useSelector((state: RootState) => state.board.board);
  const roomInfo = useSelector((state: RootState) => state.room.roomInfo);
  const orgInfo = useSelector((state: RootState) => state.org.orgInfo);

  const currentChatSession: any = useSelector(
    (state: RootState) => state.AIAssist.currentChatSession
  );


  const { data: chatSessionList = [], isLoading: isLoadingChatSessionList } =
    useGetAIChatSessionListQuery({
      userId: userInfo.userId,
      orgId: orgInfo.orgId
    });


  useEffect(() => {
    return () => {
      store.dispatch(handleSetAiToolBar(false));
    };
  }, []);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      flexShrink: 1,
      width: '100%',
      maxWidth: '1024',
      alignItems: 'center',
      height: isMobile ? '100vh' : 'calc(100vh - 60px)',
    }}>
      <Box sx={{

        zIndex: 1,
        display: 'flex',
        height: '60px',
        alignItems: 'center',
        boxShadow: 'var(--joy-shadow-sm)',
        width: '100%',
        justifyContent: 'space-between',
        flexShrink: 0,

      }}>
        <Box>

        </Box>
        <ChatAgentComponent />
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, mr: '20px' }}>
          <ChatHeaderMenu />

        </Box>

      </Box>


      <ChatDialogContent
        dialogFullScreen={true}
        chatType={'dashboard'}
        checkCreditsIsEnoughResult={checkCreditsIsEnoughResult}
      />



      <SendMsgForm dialogFullScreen={true} chatType={chatType} />

    </Box>
  );
}

export default AIAssistantAvaChatPage;
