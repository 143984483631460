//** Import react
import React, { useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
//** Import i18n
import { useTranslation } from 'react-i18next';


//** Import Redux kit
import store, { RootState } from '../../store';
import { useSelector, useDispatch } from 'react-redux';
import {
  useGetOrgListQuery,
  useInsertNewOrgMutation
} from '../../redux/OrgAPISlice';

//** Import Mui
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';

import Container from '@mui/material/Container';
import Typography from '@mui/joy/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';

//** Import Services
import { OrgService, BoardService, UtilityService } from '../../services';

export default function OrganizationCreateOrgModal({ handleClose, openCreateOrg, setOpenCreateOrg, }) {
  // use
  const theme = useTheme();
  const { t } = useTranslation();
  const fullScreen = useMediaQuery(theme.breakpoints.up('xl'));
  const navigate = useNavigate();
  const nameRef: any = useRef();

  const dispatch = useDispatch();
  //org
  const user = useSelector((state: RootState) => state.user.userInfo);
  const orgInfo = useSelector((state: RootState) => state.org.orgInfo);
  const {data: orgList=[], isLoading, isError} = useGetOrgListQuery({userId:user.userId});
  const [insertNewOrg,{error}] = useInsertNewOrgMutation();

  const [flag, setFlag] = React.useState(false);
  const [createOrgButtonLoading, setCreateOrgButtonLoading] = React.useState(false);

  useEffect(() => {
    setFlag(!openCreateOrg);
  });

  //创建org
  const handleCreateOrganization = async (e) => {
    let orgName = nameRef.current ? nameRef.current.value.trim() : '';
    if (orgName.length === 0) {
      Boardx.Util.Msg.info(t('pages.organizationNameEmpty'));
      return;
    }
    setFlag(true);
    setCreateOrgButtonLoading(true);
    const orgId = UtilityService.getInstance().generateWidgetID();
    let user = store.getState().user.userInfo;
    await insertNewOrg({ orgName: orgName, orgId: orgId, type:'dashBoardCreateOrg',user });
    navigate('/recent');
    // Boardx.Util.Msg.warning(error.reason);
     setOpenCreateOrg(false);
     setFlag(false);
     setCreateOrgButtonLoading(false);
  };

  return (
    <Box sx={{display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '@media (max-width: 912px)': {
      dialogRightContent: {
        padding: '34px 16px',
        justifyContent: 'flex-start'
      },
      paper: {
        width: '100%',
        height: '100%',
        borderRadius: 0
      },
      containerStyle: {
        justifyContent: 'center'
      }
    },
    '@media (max-height: 644px)': {
      scrollPaper: {
        height: 'auto',
        marginTop: '48px'
      }
    }
  }}
    id="main">
      <Dialog
        aria-labelledby="responsive-dialog-title"
        sx={{
          '& .MuiDialog-scrollPaper': {
            height: '100%'
          },
          '& .MuiDialog-paper': {
            width: 'auto',
            height: 'auto',
            maxHeight: 'none',
            maxWidth: 'none',
            overflow: 'hidden',
            borderRadius: '8px',
            boxShadow: 'var(--joy-shadow-md)',
          },
        
        
        }}


        fullScreen={fullScreen}
        fullWidth
        id="dialog"
        onClose={handleClose}
        open={openCreateOrg}
      >
        <Container
          sx={{ display: 'flex', padding: 0 }}
          
          component="main"
          maxWidth="lg"
        >
          <CssBaseline />
          <Box
            sx={{
              width: 490,
              padding: '45px 64px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}
          >
            <a onClick={e => e.preventDefault()}>
              <img
                alt=""
              
                style={{ width: 'auto', height: '40px' }}
                src="/images/logo_1.svg"
              />
            </a>
            <Typography
            
   
              sx={{
                 
                marginBottom: '8px',
                fontSize: '2rem',
                lineHeight: 1.125,
                fontStyle: 'normal',
                marginTop: '32px'
              }}
              component="h1"
               level="h1"
            >
              {t('pages.listPage.createOrg')}
            </Typography>
            <Typography

              sx={{ color: 'rgba(35, 41, 48, 0.65)', fontSize: '0.875rem' }}
              component="p"
            >
              {t('pages.listPage.orgRole')}
            </Typography>
            <TextField
              sx={{ width: '100%', margin: '24px 0' }}
              defaultValue=""
              id="inputOrgName"
              inputProps={{ style: { width: '100%' } }}
              inputRef={nameRef}
              placeholder={t('pages.listPage.orgName')}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleCreateOrganization(event);
                }
              }}
            />
            <Button
              loading={createOrgButtonLoading}
              color="primary"
              onClick={handleCreateOrganization}
              style={{ height: 56, width: '100%' }}
              type="button"
              variant="solid"
              disabled={flag}
            >
              {t('pages.create')}
            </Button>
          </Box>
        </Container>
      </Dialog>
    </Box>
  );
}
