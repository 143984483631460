//** Import react
import React, { useRef, Component, useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';

//** Import Redux kit
import { useAdminAddAiCommandMutation } from '../../../redux/AiAssistApiSlice';
import { useSelector, useDispatch } from 'react-redux';
import { handleSetCurrentAdminAiPromptData } from '../../../store/AIAssist';

import Button from '@mui/joy/Button';
import TextField from '@mui/material/TextField';
import Switch from '@mui/joy/Switch';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Autocomplete from '@mui/material/Autocomplete';

//** Import i18n
import { useTranslation } from 'react-i18next';

//** Import services
import { UtilityService } from '../../../services';

import store from '../../../store';

export default function AIAssistantContentAddNewPrompt({
  languageData,
  languageSelect,
  openAddNewPromptDialog,
  handleCloseAddNewPromptDialog,
  setOpenAiPromptDialog
}) {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  /**
   * Create a reference ('ref') for storing an input element's value.
   * The initial value is set to an empty string.
   */
  const valueRefName: any = useRef('');

  /**
   * Create a state variable 'sectionSelect' and its setter function using the 'React.useState' hook.
   * This variable is used to manage selected sections or items and is initialized as an empty array.
   */
  const [sectionSelect, setSectionSelect] = React.useState([]);

  /**
   * Create a state variable 'switchValue' and its setter function using the 'React.useState' hook.
   * This variable is used to manage the state of a switch or toggle component and is initialized as 'false'.
   * 'false' typically represents the 'off' state.
   */
  const [switchValue, setSwitchValue] = React.useState(false);

  /**
   * Create a state variable 'teamsPrompt' and its setter function using the 'useState' hook.
   * This variable is used to manage the visibility of a prompt related to teams.
   * It is initialized as 'false', indicating that the prompt should initially be hidden.
   */
  const [teamsPrompt, setTeamsPrompt] = useState(false);

  const [adminAddAiCommand, { isError, isSuccess, isLoading }] =
    useAdminAddAiCommandMutation();

  const sectionSelectData = [
    'General',
    'Ideate',
    'Extract',
    'Blog',
    'Email',
    'Sales & Marketing',
    'Product',
    'Persona',
    'Storytelling',
    'Social Media',
    'Video',
    'SEO',
    'Translation',
    'Unclassified'
  ];

  /**
   * Handles the addition of a new command.
   * - Checks if the input value is empty and displays a message if it is.
   * - Constructs the data object for the new command.
   * - Calls the 'adminAddAiCommand' function to add the new command.
   * - Displays a success message.
   * - Resets the 'sectionSelect' state to an empty array.
   * - Closes the 'Add New Prompt' dialog.
   */
  const handleAddCommand = async () => {
    // Check if the input value is empty.
    if (valueRefName.current.value === '') {
      // Display an information message if the input is empty.
      Boardx.Util.Msg.info(t('adminPage.pleaseFillAllFields'));
      return;
    }

    // Create a data object for the new command.
    const data = {
      _id: UtilityService.getInstance().generateWidgetID(),
      name: valueRefName.current.value,
      section: sectionSelect,
      isFeatured: switchValue,
      isTeamsPrompt: teamsPrompt,
      typeOfUse: 'Prompt',
      language: languageData[languageSelect],
      usedTimes: 0,
      createdAt: new Date(),
      createUser: store.getState().user.userInfo.userName,
      createUserId: store.getState().user.userInfo.userId,
      lastUpdateUser: store.getState().user.userInfo.userName,
      lastUpdateUserId: store.getState().user.userInfo.userId,
      lastUpdateAt: new Date()
    };

    // Call the 'adminAddAiCommand' function to add the new command.
    await adminAddAiCommand({ commandData: data })
      .unwrap()
      .then(result => {
        dispatch(handleSetCurrentAdminAiPromptData(data));
        Boardx.Util.Msg.info(t('adminPage.addSuccessfully'));
        setOpenAiPromptDialog(true);
      })
      .catch(err => {
        console.log('updateAIChatSession err', err);
      });

    setSectionSelect([]);

    handleCloseAddNewPromptDialog();
  };

  /**
   * Handles the change event of a switch or toggle.
   * Toggles the value of the 'switchValue' state variable when the switch changes.
   *
   * @param {object} event - The event object.
   */
  const handleSwitchChange = event => {
    // Toggle the value of the 'switchValue' state variable.
    setSwitchValue(!switchValue);
  };

  /**
   * Handles the change event for a Teams prompt.
   * Toggles the value of the 'teamsPrompt' state variable when the change event occurs.
   *
   * @param {object} event - The event object.
   */
  const handleChangeTeamsPrompt = event => {
    // Toggle the value of the 'teamsPrompt' state variable.
    setTeamsPrompt(!teamsPrompt);
  };

  return (
    <Dialog
      open={openAddNewPromptDialog}
      onClose={handleCloseAddNewPromptDialog}
      sx={{
        '.inputBox': {
          marginTop: '12px'
        },

        '.inputTitle': {
          fontWeight: 600,
          fontSize: '12px',
          lineHeight: '15px',
          marginBottom: '6px'
        },

        '.textFieldRoot': {
          width: '500px',
      
        },

        '.saveAndPreview': {
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '24px',
          letterSpacing: '0.4px',
          textTransform: 'uppercase',
          boxShadow: 'var(--joy-shadow-md)',
          borderRadius: '5px',
          padding: '7px 22px !important',
          background: '#F21D6B !important'
        },

        '.autocompleteInputRoot': {
          minHeight: '40px',
          padding: '0px !important'
        },

        '.autocompleteInput': {
          paddingLeft: '15px !important'
        },

     

      }}
    >
      <DialogContent>
        {/* CATEGORY */}
        <Box className={'inputBox'}>
          <Typography className={'inputTitle'} variant="plain">
            {t('promptManagement.category')}
          </Typography>
          <Autocomplete
            id="commandSectionSelect"
            multiple
            options={sectionSelectData.map(option => option)}
            getOptionLabel={option => option}
            filterSelectedOptions
            onChange={(event, value) => setSectionSelect(value)}
            value={sectionSelect}
            classes={{
              inputRoot: 'autocompleteInputRoot',
              input: 'autocompleteInput',
              focused: 'autocompleteFocused'
            }}
            sx={{
              '& .MuiAutocomplete-endAdornment': {
                top: '0px',
                right: '0px !important'
              }
            }}
            renderInput={params => (
              <TextField
                onBlur={event => {
                  if (event.target.value.trim() !== '')
                    return setSectionSelect([
                      ...sectionSelect,
                      event.target.value
                    ]);
                }}
                {...params}
                placeholder={t('promptManagement.category')}
              />
            )}
          />
        </Box>

        {/* Name */}
        <Box className={'inputBox'}>
          <Typography className={'inputTitle'} variant="plain">
            {t('promptManagement.name')}
          </Typography>
          <TextField
            id="name"
            inputRef={valueRefName}
            placeholder={t('promptManagement.name')}
            type="text"
            variant="outlined"
            classes={{ root: 'textFieldRoot' }}
          />
        </Box>

        <Box
          className={'inputBox'}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <Typography
            className={'inputTitle'}
            sx={{ mb: '0 !important' }}
            variant="plain"
          >
            {t('promptManagement.isFeatured')}:{' '}
          </Typography>
          <Switch
            onChange={handleSwitchChange}
            checked={switchValue}
            size="sm"
          />
        </Box>

        <Box
          className={'inputBox'}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <Typography
            className={'inputTitle'}
            sx={{ mb: '0 !important' }}
            variant="plain"
          >
            {t('promptManagement.isTeamsPrompt')}:{' '}
          </Typography>
          <Switch
            onChange={handleChangeTeamsPrompt}
            checked={teamsPrompt}
            size="sm"
          />
        </Box>
      </DialogContent>

      {/* save button */}
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          pl: '24px',
          pr: '24px',
          pb: '20px'
        }}
      >
        <Button
          className={'saveAndPreview'}
          variant="solid"
          onClick={handleAddCommand}
        >
          {t('promptManagement.saveAndPreview')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
