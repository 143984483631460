//** Import react
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
 
//** Import i18n

import { useTranslation } from 'react-i18next';

//** Import Redux kit
import store, { RootState } from '../../store';
import { useSelector, useDispatch } from 'react-redux';
import { useGetOrgListQuery } from '../../redux/OrgAPISlice';
import { useCheckActionPermissionOfTeamMutation } from '../../redux/PermissionApiSlice';
import {
  handleSetCaptureThumbnail,
  handleSetCaptureThumbnailBoardName
} from '../../store/board';
import { useGetPendingDeletedBoardQuery } from '../../redux/BoardListAPISlice';

//** Import Mui
import Drawer from '@mui/material/Drawer';
 
import Box from '@mui/joy/Box';
//** Import Services
import { BoardService, OrgService } from '../../services';

//** Imort components
import RoomList from './RoomList';
import { memo } from 'react';
import { Stack } from '@mui/joy';

const drawerWidth = 240;

function RoomLeftDrawer({ parentComponent }) {
  //use
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { t } = useTranslation();

  //org setting
  const isOrgAdmin = useSelector((state) => state.org.orgInfo.role) !== 'member' ? true : false;
  const orgInfo = useSelector((state) => state.org.orgInfo);
  const [handleCheckActionPermissionOfTeam] = useCheckActionPermissionOfTeamMutation();
  //user
  const userInfo = useSelector((state) => state.user.userInfo);

  //orgList
  const [switchOrgList, setSwitchOrgList] = useState(false);
 
  const { data: orgList = [], isLoading, isError } = useGetOrgListQuery({ userId: userInfo.userId });
  const orgName = useSelector((state) => state.org.orgInfo.name);

  //react dom
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [roleForManage, setRoleForManage] = useState(false);

  const { data: deletedBoardList = [] } = useGetPendingDeletedBoardQuery({
    orgId: orgInfo.orgId
  });

  const checkActionPermission = async (permissionName) => {
    let data = {
      permissionName: permissionName,
      role: orgInfo.role
    }
    return await handleCheckActionPermissionOfTeam(data);
  }

  // //load Org List, pull orgId from localstorage as the current orgId, if not exist, pull the first orgId from orgList
  // useEffect(() => {

  //   let orgId=localStorage.getItem('orgId');
  //   if(!orgId) orgId=orgList[0]?.orgId;
  //   if(orgList && orgList.length > 0 && userInfo && userInfo.userId && orgId){
  //     console.log('orglist',orgList);
  //     OrgService.getInstance().loadOrganization(orgId);
  //   }

  // },[orgList,userInfo]);

 

  useEffect(() => {
    const deal = async () => {
      let checkRoleOfManageTeam = await checkActionPermission('Edit Team');
      setRoleForManage(checkRoleOfManageTeam && checkRoleOfManageTeam.data);
    };
    deal();
  }, [orgInfo]);

  useEffect(() => {
    if (!orgInfo?.orgId) return;

    OrgService.getInstance().loadRoomListByOrgId(orgInfo.orgId);
  }, [orgInfo]);

  useEffect(() => {
    const deal = async () => {
      let imageData = store.getState().board.captureThumbnail;
      if (imageData) {
        await BoardService.getInstance().updateWhiteboardThumbnailOutSide();
        store.dispatch(handleSetCaptureThumbnail(null));
        store.dispatch(handleSetCaptureThumbnailBoardName(null));
      }
    }
    deal();

  }, [])




  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  window.setMobileOpen = setMobileOpen;

const hOffset=60;
  const DrawerContent = (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        borderStyle: 'none',
        marginTop: '10px',
      }}
    >
   
      <div style={{
        overflowY: 'auto',
        overflowX: 'hidden',
        height: `calc(100vh - ${hOffset}px)`,
 
      }}>
        <RoomList parentComponent={parentComponent} />
        {/* <Feedback /> */}
      </div>
    </Box>
  );

  return (
    <Box id="leftDrawer"
      sx={{
  
        display: {
          sm: 'block',
          xs: 'none',
          width: drawerWidth,
          borderStyle: 'none',
          height: '100%',
          boxShadow: 'var(--joy-shadow-sm)',
        },
        flexShrink: 0,
      }}>
      <Drawer
        id="mobile"
        onClose={handleDrawerToggle}
        open={mobileOpen}
        sx={{
 
          display: { sm: 'none', xs: 'block' },
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            borderStyle: 'none'
          },
          boxShadow: 'var(--joy-shadow-md)',
        }}
        variant="temporary"
      >
        {DrawerContent}
      </Drawer>
      <Stack
        id="laptop"
        open
        sx={{
 
          display: {
            sm: 'block',
            xs: 'none',
          },
          boxShadow: 'var(--joy-shadow-md)',
        }}
 
      >
        {DrawerContent}
      </Stack>
    </Box>
  );
}

export default memo(RoomLeftDrawer);