//** Import react
import React, { useEffect } from 'react';

import { styled } from '@mui/material/styles';

//** Import i18n
import { useTranslation } from 'react-i18next';

import Button from '@mui/joy/Button';
import Box from '@mui/joy/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import { Textarea } from '@mui/joy';
import Typography from '@mui/joy/Typography';

const EditCustomizedContentOutputFormat = props => {
  const {
    currentSelectFormat,
    openEditCustomizedContentOutputFormatDialog,
    setOpenEditCustomizedContentOutputFormatDialog,
    customizedContentOutputFormat,
    setCustomizedContentOutputFormat
  } = props;

  const { t } = useTranslation();
  


  const titleRef:any = React.useRef('');

  const [descriptionContent, setDescriptionContent] = React.useState('');

  useEffect(() => {
    setDescriptionContent(currentSelectFormat?.description);
  }, [currentSelectFormat]);

  const handleClosesDialog = () => {
    setOpenEditCustomizedContentOutputFormatDialog(false);
  };

  /**
   * Handles the click event for editing and saving a customized content output format.
   * Updates the selected format with new title and description, then closes the dialog.
   */
  const handleClickEditSave = () => {
    // Create a new format object with updated title and description.
    const newCurrentSelectFormat = {
      id: currentSelectFormat.id,
      title: titleRef.current.value,
      description: descriptionContent
    };

    // Map through the existing formats and update the selected format.
    const newCustomizedContentOutputFormat = customizedContentOutputFormat.map(
      item => {
        if (item.id === currentSelectFormat.id) {
          return newCurrentSelectFormat;
        }
        return item;
      }
    );

    // Update the state with the new format list and close the edit dialog.
    setCustomizedContentOutputFormat(newCustomizedContentOutputFormat);
    setOpenEditCustomizedContentOutputFormatDialog(false);
  };

  return (
    <Dialog
      open={openEditCustomizedContentOutputFormatDialog}
      onClose={handleClosesDialog}
      id="customizedOutputFormatDialog"

      sx={{
        '.dialogBox': {
        maxWidth: 'unset',
        borderRadius: '6px'
      },
    
      '.contentBox2': {
        width: '500px',
        height: 'auto',
        padding: '18px 24px 10px',
        boxSizing: 'border-box'
      },
    
      '.textAreaStyle': {
        width: '100%',
        resize: 'none',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: '0.15px',
        padding: '5px',
        overflow: 'unset !important'
      },
    
      '.inputTitle': {
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '15px',
        marginBottom: '6px'
      }}}
      classes={{ paper: 'dialogBox' }}
    >
      <Box className={'contentBox2'}>
        {/* Title */}
        <Box sx={{ mb: '20px' }}>
          <TextField
            fullWidth
            id="title"
            inputProps={{ style: { height: '18px', marginBottom: '8px' } }}
            inputRef={titleRef}
            defaultValue={currentSelectFormat?.title}
            label={t('promptManagement.fieldName')}
            variant="outlined"
          />
        </Box>

        {/* Description */}
        <Box>
          <Typography className={'inputTitle'} variant="plain">
            {t('promptManagement.itemsToBeGenerated')}
          </Typography>
          <Textarea
            value={descriptionContent}
            id="description"
            placeholder={t('promptManagement.itemsToBeGenerated')}
            className={'textAreaStyle'}
            onChange={e => {
              setDescriptionContent(e.target.value);
            }}
          />
        </Box>

        <DialogActions>
          <Button variant="plain" onClick={handleClosesDialog}>
            {t('promptManagement.cancel')}
          </Button>
          <Button variant="solid" onClick={handleClickEditSave}>
            {t('promptManagement.save')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default EditCustomizedContentOutputFormat;
