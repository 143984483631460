import { extendTheme } from "@mui/joy/styles";
import joyButton from "./Button";

import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';


declare module "@mui/joy/styles" {
  // No custom tokens found, you can skip the theme augmentation.
}

const theme = extendTheme({
  components: {
    
    JoySvgIcon:{
      styleOverrides:{
        root:{
          strokeWidth: '1.5px',
        }
      }
    },
    
    JoyIconButton: {
      styleOverrides: {
        root: {
          '& svg': {
            strokeWidth: '1.5px',
          },
        },
      },
    },
  },

 
  "colorSchemes": {
    "light": {
      "palette": {
        "divider": "rgba(99, 107, 116, 0.3000)",
        "focusVisible": "var(--joy-palette-neutral-500, #636B74)",
        "background": {
          "backdrop": "rgba(18, 20, 22, 0.2500)",
          "body": "var(--joy-palette-common-white, #FFFFFF)",
          "popup": "var(--joy-palette-common-white, #FFFFFF)",
          "surface": "var(--joy-palette-neutral-050, #FBFCFE)"
        },
     
        "danger": {
          "50": "#FEF6F6",
          "100": "#FCE4E4",
          "200": "#F7C5C5",
          "300": "#F09898",
          "400": "#E47474",
          "500": "#C41C1C",
          "600": "#A51818",
          "700": "#7D1212",
          "800": "#430A0A",
          "900": "#240505",
          "darkChannel": "rgba(125, 18, 18, 0.6000)",
          "lightChannel": "rgba(247, 197, 197, 0.6000)",
          "mainChannel": "rgba(196, 28, 28, 0.6000)",
          "softDisabledBg": "var(--joy-palette-neutral-050, #FBFCFE)",
          "solidColor": "var(--joy-palette-common-white, #FFFFFF)"
        },
        "neutral": {
          "50": "#FBFCFE",
          "100": "#F0F4F8",
          "200": "#DDE7EE",
          "300": "#CDD7E1",
          "400": "#9FA6AD",
          "500": "#636B74",
          "600": "#555E68",
          "700": "#32383E",
          "800": "#171A1C",
          "900": "#0B0D0E",
          "darkChannel": "rgba(50, 56, 62, 0.6000)",
          "lightChannel": "rgba(221, 231, 238, 0.6000)",
          "mainChannel": "rgba(99, 107, 116, 0.6000)",
          "softDisabledBg": "var(--joy-palette-neutral-050, #FBFCFE)",
          "solidColor": "var(--joy-palette-common-white, #FFFFFF)",
          

        },
        "primary": {
          "50": "#FDEDF3",
          "100": "#FBE4ED",
          "200": "#F7C5D7",
          "300": "#F098B9",
          "400": "#E4447E",
          "500": "#F21D6B",
          "600": "#A5184B",
          "700": "#7C1339",
          "800": "#430A1F",
          "900": "#051423",
          "darkChannel": "rgba(123, 18, 57, 0.6000)",
          "lightChannel": "rgba(247, 199, 216, 0.6000)",
          "mainChannel": "rgba(203, 11, 81, 0.6000)",
          "outlinedActiveBg": "var(--joy-palette-primary-200, #F7C5D7)",
          "outlinedBorder": "var(--joy-palette-primary-300, #0098B9)",
          "outlinedColor": "var(--joy-palette-primary-500, #001D6B)",
          "outlinedHoverBg": "var(--joy-palette-primary-100, #FBE4ED)",
          "plainActiveBg": "var(--joy-palette-primary-200, #F7C5D7)",
          "plainColor": "var(--joy-palette-primary-500, #F21D6B)",
          "plainHoverBg": "var(--joy-palette-primary-100, #FBE4ED)",
          "softActiveBg": "var(--joy-palette-primary-300, #F098B9)",
          "softActiveColor": "var(--joy-palette-primary-800, #430A1F)",
          "softBg": "var(--joy-palette-primary-100, #FBE4ED)",
          "softColor": "var(--joy-palette-primary-700, #7C1339)",
          "softDisabledBg": "var(--joy-palette-neutral-050, #FBFCFE)",
          "softHoverBg": "var(--joy-palette-primary-200, #F7C5D7)",
          "solidActiveBg": "var(--joy-palette-primary-700, #7C1339)",
          "solidBg": "var(--joy-palette-primary-500, #F21D6B)",
          "solidColor": "var(--joy-palette-common-white, #FFFFFF)",
          "solidHoverBg": "var(--joy-palette-primary-600, #A5184B)",
       
        },
        "success": {
          "50": "#F6FEF6",
          "100": "#E3FBE3",
          "200": "#C7F7C7",
          "300": "#A1E8A1",
          "400": "#51BC51",
          "500": "#1F7A1F",
          "600": "#136C13",
          "700": "#0A470A",
          "800": "#042F04",
          "900": "#021D02",
          "darkChannel": "rgba(10, 71, 10, 0.6000)",
          "lightChannel": "rgba(199, 247, 199, 0.6000)",
          "mainChannel": "rgba(31, 122, 31, 0.6000)",
          "softDisabledBg": "var(--joy-palette-neutral-050, #FBFCFE)",
          "solidColor": "var(--joy-palette-common-white, #FFFFFF)"
        },
        "warning": {
          "50": "#FEFAF6",
          "100": "#FDF0E1",
          "200": "#FCE1C2",
          "300": "#F3C896",
          "400": "#EA9A3E",
          "500": "#9A5B13",
          "600": "#72430D",
          "700": "#492B08",
          "800": "#2E1B05",
          "900": "#1D1002",
          "darkChannel": "rgba(73, 43, 8, 0.6000)",
          "lightChannel": "rgba(252, 225, 194, 0.6000)",
          "mainChannel": "rgba(154, 91, 19, 0.6000)",
          "softDisabledBg": "var(--joy-palette-neutral-050, #FBFCFE)",
          "solidColor": "var(--joy-palette-common-white, #FFFFFF)"
        },
        "common": {
          "white": "#FFFFFF",
          "black": "#000000"
        }
      }
    },
    "dark": {
      "palette": {
        "divider": "rgba(99, 107, 116, 0.1600)",
        "focusVisible": "var(--joy-palette-neutral-500, #F21D6B)",
        "background": {
          "backdrop": "rgba(245, 247, 250, 0.2500)",
          "body": "var(--joy-palette-common-black, #000000)",
          "popup": "var(--joy-palette-common-black, #000000)"
        },
        "danger": {
          "50": "#FEF6F6",
          "100": "#FCE4E4",
          "200": "#F7C5C5",
          "300": "#F09898",
          "400": "#E47474",
          "500": "#C41C1C",
          "600": "#A51818",
          "700": "#7D1212",
          "800": "#430A0A",
          "900": "#240505",
          "darkChannel": "rgba(125, 18, 18, 0.6000)",
          "lightChannel": "rgba(247, 197, 197, 0.6000)",
          "mainChannel": "rgba(196, 28, 28, 0.6000)",
          "solidColor": "var(--joy-palette-common-white, #FFFFFF)"
        },
        "neutral": {
          "50": "#FBFCFE",
          "100": "#F0F4F8",
          "200": "#DDE7EE",
          "300": "#CDD7E1",
          "400": "#9FA6AD",
          "500": "#636B74",
          "600": "#555E68",
          "700": "#32383E",
          "800": "#171A1C",
          "900": "#0B0D0E",
          "darkChannel": "rgba(50, 56, 62, 0.6000)",
          "lightChannel": "rgba(221, 231, 238, 0.6000)",
          "mainChannel": "rgba(99, 107, 116, 0.6000)",
          "solidColor": "var(--joy-palette-common-white, #FFFFFF)"
        },
        "primary": {
          "50": "#FDEDF3",
          "100": "#FBE4ED",
          "200": "#F7C5D7",
          "300": "#F098B9",
          "400": "#E4447E",
          "500": "#F21D6B",
          "600": "#A5184B",
          "700": "#7C1339",
          "800": "#430A1F",
          "900": "#051423",
          "darkChannel": "rgba(123, 18, 57, 0.6000)",
          "lightChannel": "rgba(247, 199, 216, 0.6000)",
          "mainChannel": "rgba(203, 11, 81, 0.6000)",
          "outlinedActiveBg": "var(--joy-palette-primary-700, #7C1339)",
          "outlinedBorder": "var(--joy-palette-primary-700, #7C1339)",
          "outlinedColor": "var(--joy-palette-primary-200, #F7C5D7)",
          "outlinedHoverBg": "var(--joy-palette-primary-800, #430A1F)",
          "plainActiveBg": "var(--joy-palette-primary-700, #7C1339)",
          "plainColor": "var(--joy-palette-primary-300, #F098B9)",
          "plainHoverBg": "var(--joy-palette-primary-800, #430A1F)",
          "softActiveBg": "var(--joy-palette-primary-600, #A5184B)",
          "softActiveColor": "var(--joy-palette-primary-100, #FBE4ED)",
          "softBg": "var(--joy-palette-primary-800, #430A1F)",
          "softColor": "var(--joy-palette-primary-200, #F7C5D7)",
          "softHoverBg": "var(--joy-palette-primary-700, #7C1339)",
          "solidActiveBg": "var(--joy-palette-primary-700, #7C1339)",
          "solidBg": "var(--joy-palette-primary-500, #F21D6B)",
          "solidColor": "var(--joy-palette-common-white, #FFFFFF)",
          "solidHoverBg": "var(--joy-palette-primary-600, #A5184B)"
        },
        "success": {
          "50": "#F6FEF6",
          "100": "#E3FBE3",
          "200": "#C7F7C7",
          "300": "#A1E8A1",
          "400": "#51BC51",
          "500": "#1F7A1F",
          "600": "#136C13",
          "700": "#0A470A",
          "800": "#042F04",
          "900": "#021D02",
          "darkChannel": "rgba(10, 71, 10, 0.6000)",
          "lightChannel": "rgba(199, 247, 199, 0.6000)",
          "mainChannel": "rgba(31, 122, 31, 0.6000)",
          "solidColor": "var(--joy-palette-common-white, #FFFFFF)"
        },
        "warning": {
          "50": "#FEFAF6",
          "100": "#FDF0E1",
          "200": "#FCE1C2",
          "300": "#F3C896",
          "400": "#EA9A3E",
          "500": "#9A5B13",
          "600": "#72430D",
          "700": "#492B08",
          "800": "#2E1B05",
          "900": "#1D1002",
          "darkChannel": "rgba(73, 43, 8, 0.6000)",
          "lightChannel": "rgba(252, 225, 194, 0.6000)",
          "mainChannel": "rgba(154, 91, 19, 0.6000)",
          "solidColor": "var(--joy-palette-common-white, #FFFFFF)"
        },
        "common": {
          "white": "#FFFFFF",
          "black": "#000000"
        }
      }
    }
  }
 
});

export default theme;
 