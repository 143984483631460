import * as React from "react";
import { CssVarsProvider } from "@mui/joy/styles";
import { FocusTrap } from "@mui/base/FocusTrap";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import Stack from "@mui/joy/Stack";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import FolderRoundedIcon from "@mui/icons-material/FolderRounded";
import ReviewsOutlinedIcon from "@mui/icons-material/ReviewsOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useMediaQuery } from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BugReportOutlinedIcon from "@mui/icons-material/BugReportOutlined";
import Feedback from 'pages/feedback';
import Avatar from "@mui/joy/Avatar";
import Input from "@mui/joy/Input";


import EngineeringOutlinedIcon from "@mui/icons-material/EngineeringOutlined";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/joy/Tooltip";
import { IconChalkboard } from '@tabler/icons-react';
import { IconMessages } from '@tabler/icons-react';
import { IconHomeCog } from '@tabler/icons-react';

export default function AppBar() {

  const isMobile = useMediaQuery("(max-width: 600px)");
  const currentApp = useSelector((state: RootState) => state.system.currentApp);
  const navigate = useNavigate();
  const userInfo: any = useSelector((state: RootState) => state.user.userInfo);
  const userAdminRole = userInfo.roles.includes('BoardX Admin');

  const { t } = useTranslation();
  return (
    <Stack
      id="tab-bar"
      spacing={1}
      sx={{
        // display: { xs: "flex", sm: "none" },
        flexDirection: isMobile ? "row" : "column",
        justifyContent: isMobile ? "center" : "space-between",
        width: isMobile ? "auto" : '60px',
        pt: '10px',
        pb: '16px',
        backgroundColor: "background.body",
        borderColor: "divider",
        boxShadow: 'var(--joy-shadow-md)',
        opacity: 0.8,
      }}
    >
      <Box
        sx={{
          gap: 2,
          display: "flex",
          flexDirection: isMobile ? "row" : "column",
          justifyContent: isMobile ? "space-around" : "space-around",
        }}
      >

        <Button
          variant="plain"
          
          color={currentApp == "ava" ? "primary" : "neutral"}
          //size="sm"
          onClick={() => { navigate('/aiassistant') }}
          startDecorator={<IconMessages />}
          sx={{ fontSize: "10px", flexDirection: "column", "--Button-gap": 0, opacity: 0.8 }}
        >
          {t('pages.topmenu.ava')}
        </Button>
        <Button
          variant="plain"
          color={currentApp == "board" ? "primary" : "neutral"}
          //size="sm"
          onClick={() => { navigate('/recent') }}
          startDecorator={<IconChalkboard />}
          sx={{ fontSize: "10px", flexDirection: "column", "--Button-gap": 0, opacity: 0.8 }}
        >
          {t('pages.topmenu.boards')}
        </Button>

        {!isMobile && <Button
          variant="plain"

          color={currentApp == "agent" ? "primary" : "neutral"}
          onClick={() => { navigate('/appsStore') }}
          //size="sm"
          startDecorator={<EngineeringOutlinedIcon />}
          sx={{ fontSize: "10px", width:"60px", flexDirection: "column", "--Button-gap": 0, opacity: 0.8 }}
        >
          {t('pages.topmenu.agent')}
        </Button>}



      </Box>
      <Box
        sx={{
          gap: 2,
          display: isMobile ?  "none":  'flex', //{ sm: "none", md: 'flex' },
          flexDirection: isMobile ? "row" : "column",
          justifyContent: isMobile ? "space-around" : "space-around",
          alignItems: "center",


        }}
      >

        {userAdminRole && !isMobile && (<Button
          variant="plain"
          color={currentApp == "console" ? "primary" : "neutral"}
          //size="sm"

          startDecorator={<IconHomeCog />}
          sx={{ fontSize: "10px", flexDirection: "column", "--Button-gap": 0, opacity: 0.8 }}

          onClick={() => navigate('/admin')}

        >


          {t('components.userMenu.adminConsole')}


        </Button>)}

        {!isMobile && <Tooltip
          title={t('components.board.betaContent')}
          arrow placement="bottom"
        >
          <Typography sx={{ fontSize: '10px', textAlign: 'center', width: '40px', opacity: 0.8 }} variant='solid' level="body-xs" >BETA</Typography>
        </Tooltip>}
        <Feedback />

        {/* <Button
          variant="plain"
          color="neutral"
          size="sm"
          startDecorator={<HelpOutlineOutlinedIcon />}
          sx={{
            flexDirection: "column",
            "--Button-gap": 0,
            display: { xs: "none", sm: "flex" },
          }}
        >
          Help
        </Button> */}
      </Box>
    </Stack>
  );
}
