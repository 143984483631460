//** Import react
import * as React from 'react';

//** Import i18n
import { useTranslation } from 'react-i18next';

//** Import Redux kit
import {
  useGetAiModelAllDataQuery,
  useAdminUpdateAiModelMutation
} from '../../../redux/AiAssistApiSlice';

//** Import Mui
import { DataGrid } from '@mui/x-data-grid';
import Typography from '@mui/joy/Typography';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Chip from '@mui/joy/Chip';

import TextField from '@mui/material/TextField';

import AIModelAddNewModel from './AIModelAddNewModel';
import AIModelParameter from './AIModelParameter';
import AIModelTrainedDataGrid from './AIModelTrainedDataGrid';
import server from '../../../startup/serverConnect';

const addNewModelButton = {
  padding: '7px 22px',
  margin: '0px 16px 0px 16px',
  background: '#F21D6B',
  fontSize: '14px',
  lineHeight: '24px'
};

export default function AIModelManagementPage() {

  const modelNameRef: any = React.useRef('');
  const { t } = useTranslation();
  const [searchKeyWord, setSearchKeyWord] = React.useState('');
  const [currentRowData, setCurrentRowData] = React.useState(null);
  const [tabValue, setTabValue] = React.useState('1');
  const [loadingCancelButton, setLoadingCancelButton] = React.useState(false);
  const [openAddNewModelDialog, setOpenAddNewModelDialog] =
    React.useState(false);

  const { data: modelData = [] } = useGetAiModelAllDataQuery(undefined);
  const [adminUpdateAiModel] = useAdminUpdateAiModelMutation();

  const handleStatusCellDOM = params => {
    const status = params.row.status;
    return (
      <Chip> {status}</Chip>
    );
  };

  const handleSubmittedTimeCellDOM = params => {
    const time = params.row.submittedTime ? params.row.submittedTime : '';
    return (
      <Typography level="body-sm"  >
        {time}
      </Typography>
    );
  };

  const handleTrainedTimeCellDOM = params => {
    const time = params.row.trainedTime ? params.row.trainedTime : '';
    return (
      <Typography level="body-sm" >
        {time}
      </Typography>
    );
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 200
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 130,
      renderCell: params => handleStatusCellDOM(params)
    },
    {
      field: 'submittedTime',
      headerName: 'Submitted Time',
      width: 180,
      renderCell: params => handleSubmittedTimeCellDOM(params)
    },
    {
      field: 'trainedTime',
      headerName: 'Trained Time',
      width: 180,
      renderCell: params => handleTrainedTimeCellDOM(params)
    }
  ];

  const handleChangeTabs = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCloseAddNewModelDialog = () => {
    setOpenAddNewModelDialog(false);
  };

  const handleCancelCurrentModelTrainingStatus = () => {
    const modelFineTuneId = currentRowData.modelTrainingInfo.fineTuneId;
    setLoadingCancelButton(true);
    server.call('ai.cancelCurrentModelTraining',
      modelFineTuneId).then(async res => {
        setLoadingCancelButton(false);
        await adminUpdateAiModel({
          newModelData: {
            ...currentRowData,
            status: 'Draft'
          }
        });
        Boardx.Util.Msg.success(
          t('chatAi.cancelModelTrainingSuccessfully')
        );
      }).catch(err => {
        Boardx.Util.Msg.warning(err.reason);

      });

  };

  const handleSearchModel = e => {
    const keyword = modelNameRef.current.value;
    setSearchKeyWord(keyword);
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      height: 'calc(100vh-60px)',
      width: '100%'
    }}>
      <Box sx={{
        width: '40%',
        borderRadius: '6px 0px 0px 6px',
        background: 'var(--joy-palette-background-surface)',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <TextField
          fullWidth
          id="fullname"
          inputProps={{
            style: {
              height: '18px',
              width: '95%',

            }
          }}

          classes={{ root: 'textFieldRoot' }}
          inputRef={modelNameRef}
          label="Filter"
          variant="outlined"
          onKeyDown={handleSearchModel}
        />
        <DataGrid
          style={{ flex: 1 }}
          columns={columns}
          rows={modelData.filter(item =>
            item.name.toLowerCase().includes(searchKeyWord.toLowerCase())
          )}
          rowHeight={64}
          disableColumnMenu
          rowsPerPageOptions={[25, 50, 100]}
          pageSize={25}
          onRowClick={(params, event) => {
            setCurrentRowData(params.row);
          }}
          getRowId={row => row._id}
        />
        <Box sx={{
          margin: '30px 0px 24px',
          display: 'flex',
          justifyContent: 'flex-end'
        }}>
          <Button
            loading={loadingCancelButton}
            disabled={
              currentRowData && currentRowData.status === 'Submitted'
                ? false
                : true
            }
            variant="solid"
            sx={addNewModelButton}
            onClick={handleCancelCurrentModelTrainingStatus}
          >
            CANCEL
          </Button>
          <Button

            sx={{ mr: '16px', ml: '16px', ...addNewModelButton }}
            variant="solid"
            onClick={() => setOpenAddNewModelDialog(true)}
          >
            ADD NEW MODEL <ArrowForwardIcon sx={{ ml: '8px' }} />
          </Button>
        </Box>
        <AIModelAddNewModel
          openAddNewModelDialog={openAddNewModelDialog}
          handleCloseAddNewModelDialog={handleCloseAddNewModelDialog}
        />
      </Box>

      <Box sx={{
        flex: 1,
        borderRadius: '0px 6px 6px 0px',
        display: 'flex',
        flexDirection: 'column',
        background: 'var(--joy-palette-background-surface)',
        padding: '0px 18px 0px 18px',

      }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleChangeTabs}
              aria-label="lab API tabs example"

              sx={{
                '.tabRoot': {
                  flex: 1,
                  borderRadius: '0px 6px 6px 0px',
                  display: 'flex',
                  flexDirection: 'column',
                  background: 'var(--joy-palette-background-surface)',
                  padding: '0px 18px 0px 18px',
                  position: 'relative'
                },
                '.tabPanelRoot': {
                  flex: 1,
                  padding: 0,
                  overflowX: 'hidden',
                  overflowY: 'scroll'
                }
              }}
            >
              <Tab
                classes={{ root: 'tabRoot' }}
                label="Parameter"
                value="1"
              />
              <Tab
                classes={{ root: 'tabRoot' }}
                label="Dataset"
                value="2"
              />
            </TabList>
          </Box>
          <TabPanel classes={{ root: 'tabPanelRoot' }} value="1">
            <AIModelParameter
              setCurrentRowData={setCurrentRowData}
              currentRowData={currentRowData}
            />
          </TabPanel>
          <TabPanel classes={{ root: 'tabPanelRoot' }} value="2">
            <AIModelTrainedDataGrid
              setCurrentRowData={setCurrentRowData}
              currentRowData={currentRowData}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
}
