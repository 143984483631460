//** Import react
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';

//** Import i18n
import { useTranslation } from 'react-i18next';

//** Import Redux kit
import store, { RootState } from '../../../store';
import { handleOpenChatUI } from '../../../store/sideBar';
import { useSelector, useDispatch } from 'react-redux';
import { handleSetOpenTemplate } from '../../../store/resource';
import {
  handleSetIsPanMode,
  handleSetBoardPanelClicked,
  handleSetDrawingEraseMode,
  handleSetShowAiChatLoading
} from '../../../store/board';
import { changeMode } from '../../../store/mode';
import { handleSetAiToolBar } from '../../../store/domArea';

import {
  handleSetCurrentChatSession,
  handleSetChatSessionList,
  handleSetCurrentChatAiPersonaData,
  handleSetShowChatAiMaskingLayer
} from '../../../store/AIAssist';

import AIService from '../../../services/AIService';

//** Import Material UI
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/joy/Button';
import Sheet from '@mui/joy/Sheet';
import IconButton from '@mui/joy/IconButton';
import Box from '@mui/joy/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/joy/Typography';
import Popover from '@mui/material/Popover';
import ListItemDecorator from '@mui/joy/ListItemDecorator';

//** Import MUI Icons
import ChatAIIcon20x20 from '../../../mui/icons/ChatAIIcon20x20';
import ChatAIIcon20x20v2 from '../../../mui/icons/ChatAIIcon20x20v2';

//**Import Services
import { BoardService, UtilityService } from '../../../services';
import ChatSessionListByDate from './ChatSessionListByDate';
import useMediaQuery from '@mui/material/useMediaQuery';
import MobileAvaChat from './MobileAvaChat';
function ChatSessionList(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openPopover, setOpenPopover] = useState(false);
  const currentChatSession: any = useSelector(
    (state: RootState) => state.AIAssist.currentChatSession
  );

  const [openMobileChat, setOpenMobileChat] = useState(false);

  const board = useSelector((state: RootState) => state.board.board);
  const roomInfo = useSelector((state: RootState) => state.room.roomInfo);
  const orgInfo = useSelector((state: RootState) => state.org.orgInfo);
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const chatSessionList = useSelector(
    (state: RootState) => state.AIAssist.chatSessionList || []
  );
  const isMobile = useMediaQuery("(max-width: 600px)");

  const handleClickOpenPopover = () => {
    setOpenPopover(true);
    // dispatch(handleSetShowChatAiMaskingLayer(true));
  };

  const handleClosePopover = () => {
    setOpenPopover(false);
    dispatch(handleSetShowChatAiMaskingLayer(false));
    dispatch(changeMode('default'));
    dispatch(handleSetAiToolBar(false));
  };

  const handleClickChatSession = async (e, chatSession) => {
    handleClosePopover();
    dispatch(handleSetShowAiChatLoading(false));
    AIService.getInstance().InitializeChatSessionByID(
      chatSession._id
    );
  };

  const handleClickNewChat = async e => {
    dispatch(handleSetShowAiChatLoading(false));
    const userId = userInfo.userId;
    const orgId = orgInfo.orgId;
    const includeContext = true;
    const gptModel = userInfo.status == 'free' ? 'gpt-4' : 'gpt-4';

    const sessiondata = await AIService.getInstance().createNewChatSession(userId, orgId, includeContext, gptModel)
    if (isMobile) {
      setOpenMobileChat(true);

    }

    // let newChatSessionList = [...chatSessionList];
    // newChatSessionList.unshift(newAiChatSession);
    // dispatch(handleSetChatSessionList(newChatSessionList));

    // handleClosePopover();
    // handleClickOpenChatUI(e, newAiChatSession);
  };



  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1, flexShrink: 1,
      boxShadow: 'var(--joy-shadow-sm'
    }}>
      <Box
        sx={{

          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '60px',
          flexShrink: 0,
          boxShadow: 'var(--joy-shadow-sm)',
        }}
      >

        <Button
          sx={{
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0.15px',
            marginLeft: '10px',
            padding: 0,
            fullwidth: 'true',

          }}
          onClick={handleClickNewChat}
          variant="plain"
        >
          {t('chatAi.startANewChat')}
        </Button>
      </Box>

      <Box
        sx={{
          overflowY: 'scroll',
          overflowX: 'hidden',
          width: isMobile ? '100vw' : '240px',
          flexGrowth: 1,
          flexShrink: 1,
          display: 'flex',
          height: isMobile ? 'calc(100vh - 170px)' : 'calc(100vh - 120px)',
          // background:'var(--joy-palette-background-level1)' 
          boxShadow: 'var(--joy-shadow-sm)',

        }}
      >

        <ChatSessionListByDate sessionList={chatSessionList} setOpenMobileChat={setOpenMobileChat} />
        {openMobileChat && <MobileAvaChat setOpenMobileChat={setOpenMobileChat} />}

      </Box>



    </Box>
  );
}

export default ChatSessionList;
