import Box from "@mui/joy/Box";
import CssBaseline from "@mui/joy/CssBaseline";
import { CssVarsProvider } from "@mui/joy/styles";
import { useMediaQuery } from "@mui/material";
import AppBarHeader from "components/common/AppBarHeader";
import * as React from "react";
import { Outlet } from "react-router-dom";
import AppBar from "./AppBar";

export default function AppDesktop() {

    const isMobile = useMediaQuery("(max-width: 600px)");

    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline />
            <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', }}>
                <AppBarHeader />

                <Box
                    sx={{
                        overflow: "hidden",
                        display: "flex",
                        flexGrow: 1,
                        flexShrink: 1,
                        width: "100vw",
                        flexDirection: isMobile ? "column-reverse" : "row",
                        height: "calc(100vh - 60px)",
                    }}
                >
                    <AppBar />



                    <Box sx={{ overflow: "hidden", height: 'calc(100vh-70px)', width: "100vw" }}>

                        <Outlet />
                    </Box>

                </Box>
            </Box>
        </CssVarsProvider>
    );
}
