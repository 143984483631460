export enum ModifiedField {
  Width = 'width',
  Height = 'height',
  Left = 'left',
  Top = 'top',
  ShapeScalex = 'shapeScaleX',
  ScaleX = 'scaleX',
  ScaleY = 'scaleY',
  FlipX = 'flipX',
  FLipY = 'flipY',
  MaxHeight = 'maxHeight',
  FixedScaleChange = 'fixedScaleChange',
  Lines = 'lines',
  PanelObj = 'panelObj',
  Relationship = 'relationship',
  ZIndex = 'zIndex',
  x1 = 'x1',
  x2 = 'x2',
  y1 = 'y1',
  y2 = 'y2',
  ConnectorStart = 'connectorStart',
  ConnectorEnd = 'connectorEnd',
  Fill = 'fill',
  Text = 'text',
}
