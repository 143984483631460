
export enum BoardState {
    LOADING = 'loading',
    ERROR = 'error',
    READY = 'ready',
    ONBOARDING = 'onBoarding',
    COMMENTING = 'commenting',
    FOLLOWME = 'followMe',
    ARROWCREATING = 'arrowCreating',
    PRESENTATION = 'presentation',
    CREATESLIDE = 'createSlide',
    DRAWING = 'drawing',
    DRAWINGERASE = 'drawingErase',
    DRAWTOCREATE = 'drawToCreate',
  }

  