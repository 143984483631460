//** Import react
import React, { useRef, Component, useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';

//** Import i18n
import { useTranslation } from 'react-i18next';

//** Import Redux kit
import {
  useAdminDeleteAiModelMutation,
  useAdminUpdateAiModelMutation,
  useAdminDeleteAiModelAllTrainedDataMutation
} from '../../../redux/AiAssistApiSlice';

import Button from '@mui/joy/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/joy/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Option from '@mui/joy/Option'

import store from '../../../store';
 
export default function AIModelParameter({
  currentRowData,
  setCurrentRowData
}) {

  const valueRefName:any = useRef(''); //creating a refernce for TextField Component
  const { t } = useTranslation();
  const [name, setName] = React.useState('');
  const [modelSelect, setModelSelect] = React.useState('0');
  const [fineTunedModel, setFineTunedModel] = React.useState('');
  const [nEpochs, setNEpochs] = React.useState(4); // null 4
  const [batchSize, setBatchSize] = React.useState(null); //1~256
  const [learningRateMultiplier, setLearningRateMultiplier] =
    React.useState(null); // 0.02~0.2
  const [promptLossWeight, setPromptLossWeight] = React.useState(0.01); // 0.01~1.0
  const [computeClassificationMetrics, setComputeClassificationMetrics] =
    React.useState(false); // fasle true
  const [classificationNClasses, setClassificationNClasses] =
    React.useState(null);
  const [classificationPositiveClass, setClassificationPositiveClass] =
    React.useState(null);
  const [classificationBetas, setClassificationBetas] = React.useState(null);
  const [suffix, setSuffix] = React.useState(null);
  const [statusSelect, setStatusSelect] = React.useState('0');
  const statusSelectData = ['Draft', 'Trained', 'Submitted'];
    const modelSelectData = ['curie', 'babbage', 'ada', 'davinci'];

    const [adminDeleteAiModel] = useAdminDeleteAiModelMutation();
    const [adminUpdateAiModel] = useAdminUpdateAiModelMutation();
    const [adminDeleteAiModelAllTrainedData] =
      useAdminDeleteAiModelAllTrainedDataMutation();

    useEffect(() => {
      if (!currentRowData) return;
      setName(currentRowData.name);
      setModelSelect(
        String(
          modelSelectData.indexOf(currentRowData.model) > -1
            ? modelSelectData.indexOf(currentRowData.model)
            : 0
        )
      );
      setFineTunedModel(
        currentRowData.modelTrainingInfo &&
          currentRowData.modelTrainingInfo.fine_tuned_model
          ? currentRowData.modelTrainingInfo.fine_tuned_model
          : ''
      );
      setNEpochs(currentRowData.n_epochs);
      setBatchSize(currentRowData.batch_size);
      setLearningRateMultiplier(currentRowData.learning_rate_multiplier);
      setPromptLossWeight(currentRowData.prompt_loss_weight);
      setComputeClassificationMetrics(
        currentRowData.compute_classification_metrics
      );
      setClassificationNClasses(currentRowData.classification_n_classes);
      setClassificationPositiveClass(
        currentRowData.classification_positive_class
      );
      setClassificationBetas(currentRowData.classification_betas);
      setSuffix(currentRowData.suffix);
    }, [currentRowData]);

    const handleSaveEditCommand = async () => {
      const data = {
        ...currentRowData,
        name: valueRefName.current.value,
        model: modelSelectData[Number(modelSelect)],
        lastUpdateUser: store.getState().user.userInfo.userName,
        lastUpdateUserId: store.getState().user.userInfo.userId,
        lastUpdateAt: new Date()
      };
  console.log('data', data)
      await adminUpdateAiModel({
        newModelData: data
      });
      Boardx.Util.Msg.info(t('adminPage.updateSuccessfully'));
    };

    const handleDeleteCommand = async () => {
      let text =
        'confirm to delete the AI modeld data: ' + currentRowData.name + '?';
      if (confirm(text) == false) {
        return;
      }

      if (currentRowData) {
        await adminDeleteAiModel({ modelId: currentRowData._id,});
        // 删除当前 AI model 时，同时删除所有的 trained data
        await adminDeleteAiModelAllTrainedData({ modelId: currentRowData._id });
        // Boardx.Util.Msg.info(err.reason);
        Boardx.Util.Msg.info(t('adminPage.deleteSuccessfully'));
        setCurrentRowData(null);
      } else {
        Boardx.Util.Msg.info(t('adminPage.selectTableContent'));
      }
    };

    return (
      <Box>
        <Box sx={{ flex: 1, p: '0 0 100px' }}>
          {/* Name */}
          <Box sx={{ '& .MuiOutlinedInput-notchedOutline': { display: 'none' } }}>
            <Typography sx={{ fontWeight: 400, fontSize: '12px' }} variant="plain">
              NAME
            </Typography>
            <TextField
              autoFocus
             // sx={{ padding: 0, fontWeight: 400, fontSize: '12px', lineHeight: '13px', color: 'rgba(35, 41, 48, 0.65)', marginLeft: '10px' }}
              fullWidth
              value={name}
              id="name"
              inputRef={valueRefName}
              placeholder="name"
              type="text"
              variant="outlined"
              onChange={() => setName(valueRefName.current.value)}
              onBlur={() =>
                setCurrentRowData({
                  ...currentRowData,
                  name: valueRefName.current.value
                })
              }
              disabled={
                currentRowData && currentRowData.status !== 'Draft' ? true : false
              }
            />
          </Box>

          {/* Model */}
          <Box sx={{ '& .MuiOutlinedInput-notchedOutline': { display: 'none' } }}>
            <Typography sx={{ fontWeight: 400, fontSize: '12px' }} variant="plain">
              Model
            </Typography>
            <Select
  slotProps={{
                  listbox: {
                    sx: (theme) => ({
                      zIndex: theme.vars.zIndex.modal
                    })
                  }
                }}
    /*          sx={{
                '& .MuiSelect-select': {
                  padding: 0,
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '13px',
                  color: 'rgba(35, 41, 48, 0.65)',
                  marginLeft: '10px',
                },
             '& .MuiSelect-icon': {
                  color: 'rgba(35, 41, 48, 0.65)',
                },
                '& .MuiMenuItem-root': {
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '13px',
                },
              }}
              */
              id="modelSelect"
              onChange={(event, newValue) => {
                setModelSelect(newValue);
                setCurrentRowData({
                  ...currentRowData,
                  model: modelSelectData[Number(newValue)] // Use the asserted target here
                });
              }}
              value={modelSelect}
              disabled={
                currentRowData && currentRowData.status !== 'Draft' ? true : false
              }
             
            >
              {modelSelectData && modelSelectData.length > 0
                ? modelSelectData.map((type, index) => {
                    return (
                      <Option
                        key={index}
                        sx={{ fontSize: '14px' }}
                        value={index}
                      >
                        {type}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Box>

          {/* fine_tuned_model */}
          <Box sx={{ '& .MuiOutlinedInput-notchedOutline': { display: 'none' } }}>
            <Typography sx={{ fontWeight: 400, fontSize: '12px' }} variant="plain">
              Fine Tuned Model
            </Typography>
            <TextField
              //sx={{ padding: 0, fontWeight: 400, fontSize: '12px', lineHeight: '13px', color: 'rgba(35, 41, 48, 0.65)' }}
              value={fineTunedModel}
              disabled={true}
              id="fine_tuned_model"
              type="text"
              variant="outlined"
              fullWidth
            />
          </Box>

          {/* N Epochs、Batch Size、learning rate multiplier */}
          <Box sx={{ display: 'flex', '& .MuiOutlinedInput-notchedOutline': { display: 'none' } }}>
            {/* N Epochs */}
            <Box sx={{ mr: '12px' }}>
              <Typography sx={{ fontWeight: 400, fontSize: '12px' }} variant="plain">
                N Epochs
              </Typography>
              <TextField
                //sx={{ padding: 0, fontWeight: 400, fontSize: '12px', lineHeight: '13px', color: 'rgba(35, 41, 48, 0.65)' }}
                value={nEpochs}
                disabled={true}
                id="n_epochs"
                type="text"
                variant="outlined"
              />
            </Box>

            {/* batch size */}
            <Box sx={{ mr: '12px' }}>
              <Typography sx={{ fontWeight: 400, fontSize: '12px' }} variant="plain">
                Batch Size
              </Typography>
              <TextField
               // sx={{ padding: 0, fontWeight: 400, fontSize: '12px', lineHeight: '13px', color: 'rgba(35, 41, 48, 0.65)' }}
                disabled={true}
                id="batch_size"
                type="text"
                variant="outlined"
                placeholder=""
              />
            </Box>

            {/* learning rate multiplier */}
            <Box sx={{ mr: '12px' }}>
              <Typography sx={{ fontWeight: 400, fontSize: '12px' }} variant="plain">
                Learning Rate Multiplier
              </Typography>
              <TextField
                //sx={{ padding: 0, fontWeight: 400, fontSize: '12px', lineHeight: '13px', color: 'rgba(35, 41, 48, 0.65)' }}
                disabled={true}
                id="learning_rate_multiplier"
                type="text"
                variant="outlined"
                placeholder=""
              />
            </Box>
          </Box>

          {/* prompt loss weight、compute classification metrics、classification n */}
          <Box sx={{ display: 'flex', '& .MuiOutlinedInput-notchedOutline': { display: 'none' } }}>
            {/* prompt loss weight */}
            <Box sx={{ mr: '12px' }}>
              <Typography sx={{ fontWeight: 400, fontSize: '12px' }} variant="plain">
                Prompt Loss Weight
              </Typography>
              <TextField
                //sx={{ padding: 0, fontWeight: 400, fontSize: '12px', lineHeight: '13px', color: 'rgba(35, 41, 48, 0.65)' }}
                value={promptLossWeight}
                disabled={true}
                id="prompt_loss_weight"
                type="text"
                variant="outlined"
              />
            </Box>

            {/* compute classification metrics */}
            <Box sx={{ mr: '12px' }}>
              <Typography sx={{ fontWeight: 400, fontSize: '12px' }} variant="plain">
                Compute Classification Metrics
              </Typography>
              <TextField
                //sx={{ padding: 0, fontWeight: 400, fontSize: '12px', lineHeight: '13px', color: 'rgba(35, 41, 48, 0.65)' }}
                disabled={true}
                id="compute_classification_metrics"
                type="text"
                variant="outlined"
                placeholder=""
              />
            </Box>

            {/* classification n */}
            <Box sx={{ mr: '12px' }}>
              <Typography sx={{ fontWeight: 400, fontSize: '12px' }} variant="plain">
                Classification N Classes
              </Typography>
              <TextField
                //sx={{ padding: 0, fontWeight: 400, fontSize: '12px', lineHeight: '13px', color: 'rgba(35, 41, 48, 0.65)' }}
                disabled={true}
                id="classification_n_classes"
                type="text"
                variant="outlined"
                placeholder=""
              />
            </Box>
          </Box>

          {/*classification positive class、classification betas、classification n */}
          <Box sx={{ display: 'flex', '& .MuiOutlinedInput-notchedOutline': { display: 'none' } }}>
            {/* prompt loss weight */}
            <Box sx={{ mr: '12px' }}>
              <Typography sx={{ fontWeight: 400, fontSize: '12px' }} variant="plain">
                Classification Positive Class
              </Typography>
              <TextField
                //sx={{ padding: 0, fontWeight: 400, fontSize: '12px', lineHeight: '13px', color: 'rgba(35, 41, 48, 0.65)' }}
                disabled={true}
                id="classification_positive_class"
                type="text"
                variant="outlined"
                placeholder=""
              />
            </Box>

            {/* classification betas */}
            <Box sx={{ mr: '12px' }}>
              <Typography sx={{ fontWeight: 400, fontSize: '12px' }} variant="plain">
                Classification Betas
              </Typography>
              <TextField
                //sx={{ padding: 0, fontWeight: 400, fontSize: '12px', lineHeight: '13px', color: 'rgba(35, 41, 48, 0.65)' }}
                disabled={true}
                id="classification_betas"
                type="text"
                variant="outlined"
                placeholder=""
              />
            </Box>

            {/* suffix */}
            <Box sx={{ mr: '12px' }}>
              <Typography sx={{ fontWeight: 400, fontSize: '12px' }} variant="plain">
                Suffix
              </Typography>
              <TextField
                //sx={{ padding: 0, fontWeight: 400, fontSize: '12px', lineHeight: '13px', color: 'rgba(35, 41, 48, 0.65)' }}
                disabled={true}
                id="suffix"
                type="text"
                variant="outlined"
                placeholder=""
              />
            </Box>
          </Box>
        </Box>

        <Box sx={{ '& .MuiButton-root': { fontWeight: 400, fontSize: '12px' } }}>
          <Button
            onClick={handleDeleteCommand}
            sx={{ marginRight: '12px' }}
            variant="outlined"
          >
            Delete
          </Button>
          <Button
   
            variant="solid"
            onClick={handleSaveEditCommand}
            disabled={
              currentRowData && currentRowData.status !== 'Draft' ? true : false
            }
          >
            Save
          </Button>
        </Box>
      </Box>
    );
  }
